import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Modal from "components/shared/modal/Modal";
import { useForm, Controller } from "react-hook-form";
import LabelledTextInput from "components/shared/LabelledTextInput";
import { Button, MiniButton } from "components/shared/standardElements";
import CoursePicker from "components/shared/createEditBookingModal/CoursePicker";

import { COLORS, FONTSIZE } from "helpers/designConstants";
import ConfirmModal from "components/shared/ConfirmModal";

const CreateEditContactModal = ({
  title,
  show,
  onClose,
  selectedContact,
  courses,
  onSave,
  onRemoveContact,
  ...rest
}) => {
  const { t } = useTranslation();
  //HOOKS
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    resetField
  } = useForm();

  //STATE
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  //Set the default value on the CourseName picker
  useEffect(() => {
    resetField("courseName", {
      defaultValue:
        selectedContact != null
          ? {
              label: selectedContact?.courseName,
              value: selectedContact?.courseName
            }
          : null
    });
  }, [resetField, selectedContact]);

  const onSubmitForm = (data) => {
    onSave(
      data.contactName,
      data.contactEmail,
      data.courseName.value,
      selectedContact?.id
    );
    onCloseModal();
  };

  const onDeleteContact = () => {
    onRemoveContact(selectedContact.id);
    onCloseModal();
  };

  const onCloseModal = () => {
    onClose();
    resetField("contactName");
    resetField("contactEmail");
    resetField("courseName");
  };

  return (
    <Modal
      title={selectedContact == null ? t("new_contact") : t("edit_contact")}
      show={show}
      onClose={onCloseModal}
      disableCloseOnOutsideClick
      maxHeight={"90vh"}
      {...rest}
    >
      <Container>
        <StyledForm onSubmit={handleSubmit(onSubmitForm)}>
          <StyledLabelledTextInput
            shouldAutoFocus
            labelText={t("name_title")}
            type='text'
            name='name'
            id='name'
            placeholder={t("name_title")}
            defaultValue={selectedContact?.name}
            {...register("contactName", { required: true })}
            error={errors?.bookingName}
          />
          {errors?.contactName?.type === "required" && (
            <StyledInputErrorMessage>
              You must provide a name
            </StyledInputErrorMessage>
          )}
          <StyledLabelledTextInput
            labelText={t("email_address")}
            type='email'
            name='email'
            id='email'
            placeholder={t("email_address")}
            defaultValue={selectedContact?.email}
            {...register("contactEmail", { required: true })}
            error={errors?.bookingName}
          />
          {errors?.contactEmail?.type === "required" && (
            <StyledInputErrorMessage>
              You must provide an email
            </StyledInputErrorMessage>
          )}
          <Controller
            control={control}
            name='courseName'
            render={({ field }) => (
              <StyledCoursePicker
                courseOptions={courses}
                {...field}
                selectionRequiredError={errors?.courseName?.type === "required"}
              />
            )}
            rules={{ required: true }}
          />
          <Button>
            {selectedContact == null ? t("create_button") : t("update_button")}
          </Button>
        </StyledForm>
        {selectedContact != null && (
          <MiniButton destructive onClick={onDeleteContact}>
            DELETE
          </MiniButton>
        )}
      </Container>
      <ConfirmModal />
    </Modal>
  );
};

const Container = styled.div`
  min-width: 350px;
  padding: 5px;
  /* max-height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow: scroll; */
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
`;

const StyledLabelledTextInput = styled(LabelledTextInput)`
  padding-bottom: 10px;
  input {
    border-color: ${({ error }) => error && COLORS.alertRed};
  }
`;

const StyledInputErrorMessage = styled.p`
  margin-top: 0px;
  padding: 5px;
  color: ${COLORS.alertRed};
  font-size: ${FONTSIZE.xsmall};
  align-self: flex-start;
`;

const StyledCoursePicker = styled(CoursePicker)`
  width: 100%;
  padding-bottom: 60px;
`;

export default CreateEditContactModal;
