import React, { Fragment, useRef, useState } from "react";
import styled from "styled-components/macro";
import { COLORS, FONTWEIGHT, CONSTANTS } from "helpers/designConstants";
import useOnClickOutside from "helpers/useOnClickOutside";
import { useTranslation } from "react-i18next";

const LanguageDropdownMenu = ({ className, alignLeftOrRight = "right" }) => {
  const { t, i18n } = useTranslation();
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsActive(false));

  const [isActive, setIsActive] = useState(false);

  const onClickMenu = () => setIsActive(!isActive);

  const onChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    setIsActive(false);
  };

  return (
    <Fragment>
      <DropDownContainer ref={dropdownRef}>
        <MenuTrigger
          onClick={onClickMenu}
          className={className}
          isActive={isActive}
        >
          <span>{i18n.language === "en" ? "EN" : "FR"}</span>
        </MenuTrigger>
        <Nav isActive={isActive} align={alignLeftOrRight}>
          <NameHeader>{t("language")}</NameHeader>
          <ul>
            <LanguageItem onClick={() => onChangeLanguage("en")}>
              English
            </LanguageItem>
            <LanguageItem onClick={() => onChangeLanguage("fr-CA")}>
              Français
            </LanguageItem>
          </ul>
        </Nav>
      </DropDownContainer>
    </Fragment>
  );
};

export default LanguageDropdownMenu;

const DropDownContainer = styled.div`
  position: relative;
`;

const MenuTrigger = styled.button`
  background: ${({ isActive }) => (isActive ? COLORS.text : "none")};
  color: ${({ isActive }) => (isActive ? COLORS.primaryInverted : COLORS.text)};
  cursor: pointer;
  border: none;
  font-weight: ${FONTWEIGHT.heavy};
  border-radius: ${CONSTANTS.borderRadius};
  /* border: 1px solid ${COLORS.text}; */
  padding: 6px;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding-right: 0px;
  }

  @media (hover: hover) {
    &:hover {
      background: ${COLORS.text};
      color: ${COLORS.primaryInverted};
    }
  }
`;

const NameHeader = styled.div`
  padding: 4px 10px;
  text-transform: uppercase;
  background-color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.primaryInverted};
`;

const Nav = styled.nav`
  text-align: center;
  background: #ffffff;
  border-radius: ${CONSTANTS.borderRadius};
  position: absolute;
  z-index: 1000;
  top: 40px;
  right: ${({ align }) => align === "right" && 0};
  left: ${({ align }) => align === "left" && 0};
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  overflow: hidden;

  opacity: ${(props) => (props.isActive ? 1 : 0)};
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  transform: ${(props) =>
    props.isActive ? "translateY(0)" : "translateY(-20px)"};
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const LanguageItem = styled.li`
  border-bottom: 1px solid #dddddd;
  padding: 15px 20px;
  cursor: pointer;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;

  &:hover {
    background-color: ${COLORS.tertiary};
  }
  a {
    text-decoration: none;
    padding: 15px 20px;
    display: block;
  }
`;
