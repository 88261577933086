import { useDatabaseReader } from "database/useDatabaseReader";
import {
  doc,
  getFirestore,
  updateDoc,
  arrayUnion,
  deleteField
} from "firebase/firestore";
import { firebaseRandomID } from "helpers/helperFunctions";

const useContactsWriter = () => {
  const { contactsDocID } = useDatabaseReader();

  const createContact = async (data) => {
    const contactID = firebaseRandomID();
    await updateDoc(doc(getFirestore(), "bookingContacts", contactsDocID), {
      [`contacts.${contactID}`]: data,
      courseNames: arrayUnion(data.courseName)
    });
    return contactID;
  };

  const updateContact = async (data, contactID) => {
    await updateDoc(doc(getFirestore(), "bookingContacts", contactsDocID), {
      [`contacts.${contactID}`]: data,
      courseNames: arrayUnion(data.courseName)
    });
    return true;
  };

  const addCourseName = async (courseName) => {
    await updateDoc(doc(getFirestore(), "bookingContacts", contactsDocID), {
      courseNames: arrayUnion(courseName)
    });
    return true;
  };

  const deleteContact = async (contactID) => {
    await updateDoc(doc(getFirestore(), "bookingContacts", contactsDocID), {
      [`contacts.${contactID}`]: deleteField()
    });
  };

  // const createTestData = async () => {
  //   const data = [
  //     {
  //       name: "Faustine Halladay",
  //       email: "fhalladay0@cam.ac.uk",
  //       courseName: "Leatherstocking Golf Course"
  //     },
  //     {
  //       name: "Quinn Lundberg",
  //       email: "qlundberg1@liveinternet.ru",
  //       courseName: "Leatherstocking Golf Course"
  //     },
  //     {
  //       name: "Beverley Gland",
  //       email: "bgland2@jiathis.com",
  //       courseName: "Leatherstocking Golf Course"
  //     },
  //     {
  //       name: "Teressa Etherton",
  //       email: "tetherton3@un.org",
  //       courseName: "Wyboo Golf Club"
  //     },
  //     {
  //       name: "Talia Balfre",
  //       email: "tbalfre4@hostgator.com",
  //       courseName: "Wyboo Golf Club"
  //     },
  //     {
  //       name: "Kellina Carter",
  //       email: "kcarter5@independent.co.uk",
  //       courseName: "Valderrama Golf Club"
  //     },
  //     {
  //       name: "Else Thorouggood",
  //       email: "ethorouggood6@jiathis.com",
  //       courseName: "Valderrama Golf Club"
  //     },
  //     {
  //       name: "Kirbie Trigwell",
  //       email: "ktrigwell7@chron.com",
  //       courseName: "Boat of Garten Golf Club"
  //     },
  //     {
  //       name: "Cordula Lidgate",
  //       email: "clidgate8@google.cn",
  //       courseName: "Boat of Garten Golf Club"
  //     },
  //     {
  //       name: "Beret Momery",
  //       email: "bmomery9@youtube.com",
  //       courseName: "Boat of Garten Golf Club"
  //     },
  //     {
  //       name: "Darryl Treharne",
  //       email: "dtreharnea@stanford.edu",
  //       courseName: "Boat of Garten Golf Club"
  //     },
  //     {
  //       name: "Garrick Zorzi",
  //       email: "gzorzib@walmart.com",
  //       courseName: "Bushfoot Golf Club"
  //     },
  //     {
  //       name: "Grantley Ianiello",
  //       email: "gianielloc@fastcompany.com",
  //       courseName: "Contraband Bayou Golf Club"
  //     },
  //     {
  //       name: "Norman Ruddom",
  //       email: "nruddomd@printfriendly.com",
  //       courseName: "Contraband Bayou Golf Club"
  //     },
  //     {
  //       name: "Miller Beavors",
  //       email: "mbeavorse@digg.com",
  //       courseName: "Contraband Bayou Golf Club"
  //     },
  //     {
  //       name: "Celia Tieraney",
  //       email: "ctieraneyf@naver.com",
  //       courseName: "King Kamehameha Golf Club"
  //     },
  //     {
  //       name: "Chris Beckson",
  //       email: "cbecksong@go.com",
  //       courseName: "Kukuiolono Golf Course"
  //     },
  //     {
  //       name: "Irwin Orford",
  //       email: "iorfordh@bloglovin.com",
  //       courseName: "Pasatiempo Golf Club"
  //     },
  //     {
  //       name: "Sukey Haslen",
  //       email: "shasleni@smh.com.au",
  //       courseName: "Pasatiempo Golf Club"
  //     },
  //     {
  //       name: "Camile Strettle",
  //       email: "cstrettlej@state.gov",
  //       courseName: "Pasatiempo Golf Club"
  //     },
  //     {
  //       name: "Dorothee Sooley",
  //       email: "dsooleyk@msu.edu",
  //       courseName: "Pasatiempo Golf Club"
  //     },
  //     {
  //       name: "Rikki Denford",
  //       email: "rdenfordl@bbc.co.uk",
  //       courseName: "We-Ko-Pa Golf Club"
  //     },
  //     {
  //       name: "Harley Anersen",
  //       email: "hanersenm@shinystat.com",
  //       courseName: "Tokatee Golf Club"
  //     },
  //     {
  //       name: "Candy O'Reagan",
  //       email: "coreagann@princeton.edu",
  //       courseName: "Tokatee Golf Club"
  //     },
  //     {
  //       name: "Isa O'Ruane",
  //       email: "ioruaneo@bizjournals.com",
  //       courseName: "True Blue"
  //     },
  //     {
  //       name: "Margalit Vila",
  //       email: "mvilap@senate.gov",
  //       courseName: "True Blue"
  //     },
  //     {
  //       name: "Gerri Moye",
  //       email: "gmoyeq@theglobeandmail.com",
  //       courseName: "Possum Trot Golf Course"
  //     },
  //     {
  //       name: "Desmund Gallen",
  //       email: "dgallenr@businessweek.com",
  //       courseName: "Possum Trot Golf Course"
  //     },
  //     {
  //       name: "Bryant Hrihorovich",
  //       email: "bhrihorovichs@netvibes.com",
  //       courseName: "Possum Trot Golf Course"
  //     },
  //     {
  //       name: "Sergei Leindecker",
  //       email: "sleindeckert@altervista.org",
  //       courseName: "Possum Trot Golf Course"
  //     },
  //     {
  //       name: "Kelsey Feaveryear",
  //       email: "kfeaveryearu@pbs.org",
  //       courseName: "Robber's Row at Port Royal Golf Club"
  //     },
  //     {
  //       name: "Samantha Arstall",
  //       email: "sarstallv@sfgate.com",
  //       courseName: "Bootleg Gap Golf"
  //     },
  //     {
  //       name: "Orelee Doyland",
  //       email: "odoylandw@geocities.com",
  //       courseName: "Kissing Camels Golf Course"
  //     },
  //     {
  //       name: "Ursuline Milbourne",
  //       email: "umilbournex@google.com.hk",
  //       courseName: "Kissing Camels Golf Course"
  //     },
  //     {
  //       name: "Jenna Garioch",
  //       email: "jgariochy@ezinearticles.com",
  //       courseName: "Kissing Camels Golf Course"
  //     },
  //     {
  //       name: "Stevie Quig",
  //       email: "squigz@foxnews.com",
  //       courseName: "Lillooet Sheep Pasture Golf Course"
  //     },
  //     {
  //       name: "Car Cuchey",
  //       email: "ccuchey10@desdev.cn",
  //       courseName: "Deep Cliff Golf Course"
  //     },
  //     {
  //       name: "Danyelle Gedney",
  //       email: "dgedney11@jalbum.net",
  //       courseName: "Deep Cliff Golf Course"
  //     },
  //     {
  //       name: "Ede Righy",
  //       email: "erighy12@mozilla.com",
  //       courseName: "Hombre Golf Club"
  //     },
  //     {
  //       name: "Marisa Bodycombe",
  //       email: "mbodycombe13@auda.org.au",
  //       courseName: "Hombre Golf Club"
  //     },
  //     {
  //       name: "Donall Neles",
  //       email: "dneles14@istockphoto.com",
  //       courseName: "Court House & Jail Rock Golf Course"
  //     },
  //     {
  //       name: "Charmaine Knoller",
  //       email: "cknoller15@wikia.com",
  //       courseName: "Taboo Golf Club"
  //     },
  //     {
  //       name: "Gnni Cassel",
  //       email: "gcassel16@desdev.cn",
  //       courseName: "Taboo Golf Club"
  //     },
  //     {
  //       name: "Emanuele Richardot",
  //       email: "erichardot17@vkontakte.ru",
  //       courseName: "Grandfather Golf & Country Club"
  //     },
  //     {
  //       name: "Lea Moorfield",
  //       email: "lmoorfield18@tamu.edu",
  //       courseName: "Man O' War Golf Club"
  //     },
  //     {
  //       name: "Ernesto Gobert",
  //       email: "egobert19@harvard.edu",
  //       courseName: "Dancing Rabbit Golf Club"
  //     },
  //     {
  //       name: "Lydia Jarmyn",
  //       email: "ljarmyn1a@imgur.com",
  //       courseName: "Dancing Rabbit Golf Club"
  //     }
  //   ];

  //   const convertedData = { contacts: {}, courseNames: [] };

  //   data.forEach((contact) => {
  //     const contactID = firebaseRandomID();
  //     convertedData.contacts[contactID] = contact;
  //     convertedData.courseNames.push(contact.courseName);
  //   });

  //   convertedData.courseNames = [...new Set(convertedData.courseNames)];
  //   await updateDoc(doc(getFirestore(), "bookingContacts", contactsDocID), {
  //     contacts: convertedData.contacts,
  //     courseNames: convertedData.courseNames
  //   });
  // };

  return {
    createContact,
    updateContact,
    addCourseName,
    deleteContact
  };
};

export default useContactsWriter;
