import React from "react";
import styled from "styled-components/macro";
import Modal from "components/shared/modal/Modal";
import CartHeader from "./CartHeader";
import OrderEntry from "components/shared/orderEntry/OrderEntry";

import useWindowSize from "helpers/useWindowSize";
import { t } from "i18next";

const CartModal = ({
  show,
  onClose,
  booking,
  selectedArticles,
  formControl,
  getFormValues,
  setFormValue,
  overallCategorySummary,
  didSelecteRemoveArticle
}) => {
  const { width } = useWindowSize();

  const onCloseModal = () => {
    onClose();
  };

  return (
    <StyledModal
      show={show}
      onClose={() => onCloseModal()}
      closeButtonHidden
      disableCloseOnOutsideClick
      height='88vh'
      width={width > 1350 ? "1300px" : "96vw"}
      contentPadding='0 20px 20px 20px'
      title={t("cart_title")}
    >
      <CartContainer>
        <CartHeader showSaveButton={true} onSave={onCloseModal} />
        <OrderEntry
          booking={booking}
          selectedArticles={selectedArticles}
          formControl={formControl}
          getFormValues={getFormValues}
          setFormValue={setFormValue}
          overallCategorySummary={overallCategorySummary}
          didSelecteRemoveArticle={didSelecteRemoveArticle}
        />
      </CartContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  overflow: unset;
`;

const CartContainer = styled.div`
  /* padding: 10px 0 0; */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  /* row-gap: 10px; */
  height: 100%;
  overflow: hidden;
`;

export default CartModal;
