import { useMemo } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  updateDoc
} from "firebase/firestore";
import { useAuth } from "../useAuth";
import { firebaseRandomID } from "helpers/helperFunctions";
import { debounce } from "lodash";

const useCatalogueWriter = () => {
  const { user } = useAuth();

  const createNewCatalogue = async (data) => {
    const catalogue = {
      name: data.catalogueName,
      articleIDs: [],
      adidasRepIDs: [user.uid],
      creationDate: new Date(),
      viewerUID: firebaseRandomID(),
      priceToShowPreview: data.priceToShowPreview,
      sortOrder: data.sortOrder,
      seasonKey: data.seasonOptions.seasonKey,
      seasonName: data.seasonOptions.seasonName,
      seasonName_fr: data.seasonOptions.seasonName_fr,
      seasonSiloKey: data.seasonOptions.siloKey,
      seasonSiloName: data.seasonOptions.siloName,
      seasonSiloName_fr: data.seasonOptions.siloName_fr
    };

    const docRef = await addDoc(
      collection(getFirestore(), "catalogues"),
      catalogue
    );

    catalogue.id = docRef.id;
    return catalogue;
  };

  const updateCatalogueDetails = async (data, catalogueID) => {
    const catalogue = {
      name: data.catalogueName,
      priceToShowPreview: data.priceToShowPreview,
      sortOrder: data.sortOrder,
      seasonKey: data.seasonOptions.seasonKey,
      seasonName: data.seasonOptions.seasonName,
      seasonName_fr: data.seasonOptions.seasonName_fr,
      seasonSiloKey: data.seasonOptions.siloKey,
      seasonSiloName: data.seasonOptions.siloName,
      seasonSiloName_fr: data.seasonOptions.siloName_fr
    };

    return await updateDoc(
      doc(getFirestore(), "catalogues", catalogueID),
      catalogue
    );
  };

  const updateCatalogueArticleIDs = useMemo(
    () =>
      debounce(
        async (articleIDs, catalogueID) => {
          await updateDoc(doc(getFirestore(), "catalogues", catalogueID), {
            articleIDs: articleIDs
          });
        },
        2000,
        { maxWait: 6000 }
      ),
    []
  );

  const deleteCatalogue = async (catalogueID) => {
    return await deleteDoc(doc(getFirestore(), "catalogues", catalogueID));
  };

  return {
    createNewCatalogue,
    updateCatalogueDetails,
    updateCatalogueArticleIDs,
    deleteCatalogue
  };
};

export default useCatalogueWriter;
