import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components/macro";

const Checkbox = forwardRef(
  (
    {
      className,
      selected = false,
      type = "checkbox",
      value,
      onChange,
      name,
      color = "black",
      label,
      disabled,
      ...rest
    },
    ref
  ) => {
    const [checked, setChecked] = useState(selected);

    useEffect(() => {
      setChecked(selected);
    }, [selected]);

    const internalOnChange = (e) => {
      onChange(e);
      setChecked(!checked);
    };

    return (
      <LabelWrapper className={className}>
        <CheckboxContainer>
          <HiddenCheckbox
            checked={checked}
            value={value}
            type={type}
            name={name}
            onChange={internalOnChange}
            disabled={disabled}
            ref={ref}
          />
          <StyledCheckbox checked={checked} color={color} disabled={disabled}>
            <Icon viewBox='0 0 24 24'>
              <polyline points='20 6 9 17 4 12' />
            </Icon>
          </StyledCheckbox>
        </CheckboxContainer>
        <span>{label}</span>
      </LabelWrapper>
    );
  }
);

const LabelWrapper = styled.label`
  span {
    padding-left: 5px;
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${(props) => (props.checked ? props.color : "clear")};
  border: ${(props) => `1px solid ${props.color}`};
  border-radius: 3px;
  transition: all 150ms;
  opacity: ${(props) => props.disabled && "50%"};

  ${HiddenCheckbox}:focus + & {
    box-shadow: ${(props) => `0 0 3px 1px ${props.color}`};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export default Checkbox;
