import { useState } from "react";

const useInventoryModal = () => {
  //STATE
  const [isInventoryModalShowing, setShowInventoryModal] = useState(false);
  const [inventoryArticle, setInventoryArticle] = useState();

  const toggleInventoryModal = () => {
    setShowInventoryModal(!isInventoryModalShowing);
  };

  const onViewInventory = (article) => {
    setInventoryArticle(article);
    setShowInventoryModal(true);
  };

  return {
    isInventoryModalShowing,
    toggleInventoryModal,
    inventoryArticle,
    onViewInventory
  };
};

export default useInventoryModal;
