import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { FONTSIZE, FONTWEIGHT } from "helpers/designConstants";

const LoginContentWrapper = ({ title, children }) => {
  //HOOKS
  const { t } = useTranslation();
  return (
    <ContentWrapper>
      <AdidasHeader>
        <Image src='/icon-adidas-logo.svg'></Image>
        {t("booking")}
        {title ? <Title>{title}</Title> : ""}
      </AdidasHeader>
      {children}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 95%;
`;

const AdidasHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  font-size: ${FONTSIZE.xlarge};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
`;

const Image = styled.img`
  width: 200px;
  margin-bottom: 10px;
`;

const Title = styled.div`
  padding-top: 10px;
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  text-align: center;
`;

export default LoginContentWrapper;
