import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import DashboardCard from "../DashboardCard";
import { MiniButton } from "components/shared/standardElements";
import Checkbox from "components/shared/Checkbox";
import ConfirmShareModal from "./ConfirmShareModal";
import CreateNewContactModal from "./CreateNewContactModal";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";
import ContactPicker from "components/shared/ContactPicker";
import { toast } from "react-toastify";
import Loading from "components/shared/Loading";

import useBookingWriter from "database/bookings/useBookingWriter";
import useContactsWriter from "database/useContactsWriter";
import { copyToClipboard } from "helpers/copyToClipboard";

const SharingCard = ({
  className,
  contacts,
  bookingLinkedContactIDs,
  allLinkedContactIDs,
  bookingID,
  courseName,
  shareTracking,
  orderingPassword
}) => {
  const { t, i18n } = useTranslation();
  const {
    addLinkedContactID,
    removeLinkedContactID,
    shareBookingWithContacts
  } = useBookingWriter();

  const { createContact } = useContactsWriter();

  const [isLoading, setIsLoading] = useState();
  const [selectedContactIDs, setSelectedContactIDs] = useState([]);
  const [showConfirmShareModal, setShowConfirmShareModal] = useState(false);
  const [newContactName, setNewContactName] = useState();

  const [sortedLinkedContacts, setSortedLinkedContacts] = useState();

  useEffect(() => {
    const sortedContacts = [];

    allLinkedContactIDs.forEach(
      (contactID) =>
        contacts[contactID] != null &&
        sortedContacts.push({ ...contacts[contactID], id: contactID })
    );

    sortedContacts.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : 0
    );

    setSortedLinkedContacts(sortedContacts);
  }, [allLinkedContactIDs, contacts]);

  const toggleContactSelection = (contactID) => {
    selectedContactIDs.includes(contactID)
      ? setSelectedContactIDs(
          selectedContactIDs.filter((id) => id !== contactID)
        )
      : setSelectedContactIDs([...selectedContactIDs, contactID]);
  };

  const onSelectedContactID = async (contactID) => {
    await addLinkedContactID(contactID);
  };

  const onRemoveContactID = async (contactID) => {
    await removeLinkedContactID(contactID);
  };

  const onCreateContact = async (contactData) => {
    setNewContactName();
    const contactID = await createContact(contactData);
    await addLinkedContactID(contactID);
  };

  const onShareBooking = (contactID) => {
    if (contactID != null) {
      setSelectedContactIDs(contactID);
    }
    setShowConfirmShareModal(true);
  };

  const onConfirmSendBooking = async (shouldSendPasscode) => {
    setShowConfirmShareModal(false);
    setIsLoading(true);
    const contacts = sortedLinkedContacts.filter((contact) =>
      selectedContactIDs.includes(contact.id)
    );
    const shareResult = await shareBookingWithContacts(
      contacts,
      shouldSendPasscode
    );
    if (shareResult.success) {
      setSelectedContactIDs([]);
    } else {
      toast.error(t("share_booking_error"));
    }
    setIsLoading(false);
  };

  const copyBookingLink = () => {
    let domain = "";
    if (window.location.hostname === "localhost") {
      domain = "http://localhost:3000";
    } else {
      domain = `https://${window.location.hostname}`;
    }
    // navigator.clipboard.writeText(
    //   `${domain}/ext/preview?bid=${encodeURIComponent(bookingID)}`
    // );
    copyToClipboard(
      `${domain}/ext/preview?bid=${encodeURIComponent(bookingID)}`
    );
    toast.success(t("copied_booking_link_notification"));
  };

  const copyPurchasingCode = () => {
    navigator.clipboard.writeText(orderingPassword);
    toast.success(t("copied_purchasing_code_notification"));
  };

  return (
    <StyledDashboardCard className={className} title={t("sharing")}>
      <Loading isLoading={isLoading} />
      <Wrapper>
        <ManualSharingArea>
          <InfoBlock>
            <Label>{t("shareable_link")}</Label>
            <InfoText>
              https://...
              <StyledCopyButton onClick={copyBookingLink}>
                {t("copy_button")}
              </StyledCopyButton>
            </InfoText>
          </InfoBlock>
          <InfoBlock>
            <Label>{t("ordering_passcode")}</Label>
            <InfoText>
              {orderingPassword}
              <StyledCopyButton onClick={copyPurchasingCode}>
                {t("copy_button")}
              </StyledCopyButton>
            </InfoText>
          </InfoBlock>
        </ManualSharingArea>
        <ContactSelector>
          <ContactPicker
            contactOptions={contacts}
            linkedContactIDs={allLinkedContactIDs}
            onSelectedContactID={onSelectedContactID}
            onCreatedContact={setNewContactName}
          />
        </ContactSelector>
        <ContactsWrapper>
          {allLinkedContactIDs?.length > 1 && (
            <LinkedContactsHeader>
              <StyledCheckbox
                color={COLORS.text}
                selected={
                  selectedContactIDs?.length === allLinkedContactIDs?.length
                }
                label={
                  selectedContactIDs?.length === allLinkedContactIDs?.length
                    ? t("select_none_button")
                    : t("select_all_button")
                }
                onChange={() =>
                  selectedContactIDs.length === allLinkedContactIDs.length
                    ? setSelectedContactIDs([])
                    : setSelectedContactIDs(allLinkedContactIDs)
                }
              />
              <MiniButton
                disabled={selectedContactIDs?.length === 0}
                onClick={() => onShareBooking()}
              >
                {t("send_to_selected")}
              </MiniButton>
            </LinkedContactsHeader>
          )}
          <LinkedContactsWrapper>
            {sortedLinkedContacts?.map((contact) => (
              <LinkedContactsItem key={contact.id}>
                {allLinkedContactIDs?.length > 1 && (
                  <ContactItemLeftSide>
                    <Checkbox
                      selected={selectedContactIDs.includes(contact.id)}
                      onChange={() => toggleContactSelection(contact.id)}
                      color={COLORS.text}
                    />
                  </ContactItemLeftSide>
                )}
                <ContactItemCenter>
                  <ContactName>{contact.name}</ContactName>
                  <ContactEmail>{contact.email}</ContactEmail>
                </ContactItemCenter>
                <ContactItemRightSide>
                  <LastSent>
                    {shareTracking?.[contact.id]
                      ? `${t("sent")}: ${shareTracking[
                          contact.id
                        ].lastSent.toLocaleString(i18n.language, {
                          dateStyle: "medium",
                          timeStyle: "short"
                        })}`
                      : t("unsent")}
                  </LastSent>
                  <ActionButtonCollection>
                    {shareTracking[contact.id] == null &&
                      bookingLinkedContactIDs.includes(contact.id) && (
                        <MiniButton
                          onClick={() => onRemoveContactID(contact.id)}
                        >
                          REMOVE
                        </MiniButton>
                      )}
                    {allLinkedContactIDs?.length === 1 && (
                      <StyledMiniButton
                        margin={"0 0 0 8px"}
                        onClick={() => onShareBooking([contact.id])}
                      >
                        {shareTracking[contact.id] == null ? "SEND" : "RESEND"}
                      </StyledMiniButton>
                    )}
                  </ActionButtonCollection>
                </ContactItemRightSide>
              </LinkedContactsItem>
            ))}
          </LinkedContactsWrapper>
        </ContactsWrapper>
      </Wrapper>
      <ConfirmShareModal
        show={showConfirmShareModal}
        // show={true}
        onClose={() => setShowConfirmShareModal(false)}
        contacts={selectedContactIDs.map((contactID) => ({
          ...contacts[contactID],
          id: contactID
        }))}
        onSend={onConfirmSendBooking}
      />
      <CreateNewContactModal
        show={newContactName != null}
        onClose={() => setNewContactName()}
        existingEmails={
          contacts != null &&
          Object.values(contacts).map((contact) => contact.email)
        }
        name={newContactName}
        courseName={courseName}
        onCreateContact={onCreateContact}
      />
    </StyledDashboardCard>
  );
};

const StyledDashboardCard = styled(DashboardCard)`
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px 0;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

const ManualSharingArea = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.tertiary};
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const Label = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  text-transform: uppercase;
`;
const InfoText = styled.div`
  width: 100%;
  padding: 5px 0 0;
  display: flex;
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  align-items: center;
  justify-content: flex-end;
`;

const StyledCopyButton = styled(MiniButton)`
  margin-left: 5px;
`;

const ContactSelector = styled.div`
  width: 100%;
  margin-top: 30px;
  border-top: 1px solid ${COLORS.secondary};
`;

const StyledCheckbox = styled(Checkbox)`
  text-transform: uppercase;
`;

const ContactsWrapper = styled.div`
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const LinkedContactsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  background-color: ${COLORS.tertiary};
  border-bottom: 2px solid ${COLORS.secondary};
  border-top-left-radius: ${CONSTANTS.borderRadius};
  border-top-right-radius: ${CONSTANTS.borderRadius};
`;

const LinkedContactsWrapper = styled.div`
  height: 100%;
  /* padding: 0 5px; */
  overflow-x: visible;
  overflow-y: auto;
`;

const LinkedContactsItem = styled.div`
  padding: 15px 0;
  display: flex;
  /* justify-content: space-between; */
  :not(:first-child) {
    border-top: 1px solid ${COLORS.secondary};
  }
`;

const ContactItemLeftSide = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
`;

const ContactItemCenter = styled.div`
  flex-grow: 2;
  padding-left: 10px;
`;

const ContactName = styled.div`
  text-transform: uppercase;
  font-weight: ${FONTWEIGHT.heavy};
  padding-bottom: 5px;
`;

const ContactEmail = styled.div``;

const ContactItemRightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 10px;
`;

const LastSent = styled.div`
  padding-bottom: 4px;
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};
`;

const ActionButtonCollection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledMiniButton = styled(MiniButton)`
  margin: ${({ margin }) => margin};
`;

export default SharingCard;
