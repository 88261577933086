import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet
} from "react-router-dom";
import styled from "styled-components/macro";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { COLORS } from "helpers/designConstants";

//Context Imports
import AuthContextProvider from "database/useAuth";
import ArticleReaderContextProvider from "database/articles/useArticleReader";
import DatabaseReaderContextProvider from "database/useDatabaseReader";

//Pages
import AdidasRepRoutes from "routes/AdidasRepRoutes";
import AdminLogin from "components/pages/adminLogin/AdminLogin";
import MainDashboard from "components/pages/mainDashboard/MainDashboard";
import BookingPage from "components/pages/booking/BookingPage";
import BookingPreview from "components/pages/bookingPublic/BookingPreview";
import CourseDatabaseContextProvider from "database/useCourseDatabase";
import OrderingDashboard from "components/pages/ordering/OrderingDashboard";
import PurchaserRoutes from "routes/PurchaserRoutes";
import CatalogueDatabaseContextProvider from "database/useCatalogueDatabase";
import CatalogueViewer from "components/pages/catalogueViewer/CatalogueViewer";
import PublicPage from "components/pages/publicPage/PublicPage";

const App = () => {
  if (window.location.host.split(".")[0] === "public") {
    return (
      <AuthContextProvider>
        <ArticleReaderContextProvider>
          <BrowserRouter>
            <Container>
              <Routes>
                <Route path='/' element={<PublicPage />} />
              </Routes>
            </Container>
          </BrowserRouter>
        </ArticleReaderContextProvider>
      </AuthContextProvider>
    );
  } else {
    return (
      <AuthContextProvider>
        <ArticleReaderContextProvider>
          <BrowserRouter>
            <Container>
              <Routes>
                <Route
                  path='/'
                  element={
                    <DatabaseReaderContextProvider>
                      <Outlet />
                    </DatabaseReaderContextProvider>
                  }
                >
                  <Route index element={<AdminLogin />} />
                  <Route path='/reps' element={<AdidasRepRoutes />}>
                    <Route
                      path='/reps/mainDashboard'
                      element={<MainDashboard />}
                    />
                    <Route path='/reps/booking' element={<BookingPage />} />
                    <Route
                      path='/reps/'
                      element={<Navigate to='/' replace />}
                    />
                    <Route
                      path='/reps/*'
                      element={<Navigate to='/' replace />}
                    />
                  </Route>
                </Route>
                <Route
                  path='/'
                  element={
                    <CourseDatabaseContextProvider>
                      <Outlet />
                    </CourseDatabaseContextProvider>
                  }
                >
                  <Route path='/ext'>
                    <Route path='/ext/preview' element={<BookingPreview />} />
                    <Route path='/ext/' element={<Navigate to='/' replace />} />
                    <Route
                      path='/ext/*'
                      element={<Navigate to='/' replace />}
                    />
                  </Route>
                  <Route path='/ext/private' element={<PurchaserRoutes />}>
                    <Route
                      path='/ext/private/orderDashboard'
                      element={<OrderingDashboard />}
                    />
                  </Route>
                  <Route path='*' element={<Navigate to='/' replace />} />
                </Route>
                <Route
                  path='/'
                  element={
                    <CatalogueDatabaseContextProvider>
                      <Outlet />
                    </CatalogueDatabaseContextProvider>
                  }
                >
                  <Route path='/catalogue'>
                    <Route
                      path='/catalogue/viewer'
                      element={<CatalogueViewer />}
                    />
                    <Route
                      path='/catalogue/'
                      element={<Navigate to='/' replace />}
                    />
                    <Route
                      path='/catalogue/*'
                      element={<Navigate to='/' replace />}
                    />
                  </Route>
                  <Route path='*' element={<Navigate to='/' replace />} />
                </Route>
              </Routes>
              <ToastContainer
                position='top-right'
                theme='light'
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                // draggable
                pauseOnHover
              />
            </Container>
          </BrowserRouter>
        </ArticleReaderContextProvider>
      </AuthContextProvider>
    );
  }
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  background: ${COLORS.pageBackground};
  /* background: pink; */
  display: flex;
  justify-content: center;
`;

export default App;
