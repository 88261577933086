import React from "react";
import { useTranslation } from "react-i18next";
import DashboardPageLayout from "components/shared/dashboardPageLayout/DashboardPageLayout";
import { useAuth } from "database/useAuth";
import BookingsTab from "./bookingsTab/BookingsTab";
import ContactsTab from "./contactsTab/ContactsTab";
import CatalogueCreatorTab from "./catalogueCreatorTab/CatalogueCreatorTab";

const MainDashboard = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <DashboardPageLayout title={t("dashboard")} subTitle={user?.displayName}>
      <BookingsTab title={t("bookings_tab")} />
      <ContactsTab title={t("contacts_tab")} />
      <CatalogueCreatorTab title={t("catalogue_creator_tab_title")} />
    </DashboardPageLayout>
  );
};

export default MainDashboard;
