import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import StatusCard from "./StatusCard";
import SharingCard from "./sharingCard/SharingCard";
import DetailsCard from "./DetailsCard";

const DashboardTab = ({ booking, orders, orderingPassword, contacts }) => {
  const [contactsToShow, setContactsToShow] = useState([]);

  useEffect(() => {
    if (booking == null || contacts == null) return;
    const courseContacts = Object.entries(contacts).filter(
      ([contactID, entry]) => entry.courseName === booking.courseName
    );
    setContactsToShow([
      ...new Set([
        ...booking.linkedContactIDs,
        ...courseContacts.map((contact) => contact[0])
      ])
    ]);
  }, [booking, contacts]);

  return (
    <Container>
      <StatusCard booking={booking} orders={orders} />
      <StyledSharingCard
        contacts={contacts}
        bookingLinkedContactIDs={booking?.linkedContactIDs}
        allLinkedContactIDs={contactsToShow}
        shareTracking={booking?.shareTracking}
        bookingID={booking?.id}
        courseName={booking?.courseName}
        orderingPassword={orderingPassword}
      />
      <DetailsCard booking={booking} orders={orders} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px;
  /* overflow: hidden; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-template-rows: repeat(min-content);
  grid-gap: 5px;
`;

const StyledSharingCard = styled(SharingCard)`
  grid-column: 2;
  grid-row: 1 / span 2;
`;

export default DashboardTab;
