import { createContext, useContext, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { getDoc, doc, getFirestore } from "firebase/firestore";

// import useSearchClientReader from "database/search/useSearchClientReader";
import { useAuth } from "database/useAuth";
import { articleSearchClient } from "services/typesense";
import { flattenDeep } from "lodash";

//CREATE CONTEXT
const ArticleReaderContext = createContext();

const ArticleReaderContextProvider = ({ children }) => {
  //HOOKS
  const { user, authContextLoaded } = useAuth();

  //STATE
  const [activeSeasons, setActiveSeasons] = useState([]);
  const [schemas, setSchemas] = useState({});
  const [overallCategories, setOverallCategories] = useState({});
  const [collectionKeysBySeason, setCollectionKeysBySeason] = useState({});
  const [generalCategoryKeysBySeason, setGeneralCategoryKeysBySeason] =
    useState({});
  const [copiedSizeRun, setCopiedSizeRun] = useState({});

  const db = getFirestore();

  useEffect(() => {
    if (user == null || !authContextLoaded || activeSeasons.length > 0) return;
    getDoc(doc(db, "general", "seasonInfo")).then((snapshot) => {
      if (snapshot.exists()) {
        let schemas = {};
        Promise.all(
          snapshot.data().bookingActiveSeasons.map(async (season) => {
            const schemaSnapshot = await getDoc(
              doc(db, "general", "seasonInfo", "schemas", season.key)
            );
            if (schemaSnapshot.exists()) {
              schemas[season.key] = schemaSnapshot.data();
            } else {
              Sentry.captureMessage(
                `general/seasonInfo/schemas/${season.key} doc does not exist`
              );
            }
            return schemaSnapshot;
          })
        ).then(() => {
          setActiveSeasons(snapshot.data().bookingActiveSeasons);
          setSchemas(schemas);
        });
      } else {
        Sentry.captureMessage("general/seasonInfo doc does not exist");
      }
    });
  }, [activeSeasons, authContextLoaded, db, user]);

  useEffect(() => {
    if (user == null || !authContextLoaded) return;
    getDoc(doc(db, "general", "overallCategorySchema")).then((snapshot) => {
      if (snapshot.exists()) {
        setOverallCategories(snapshot.data());
      } else {
        Sentry.captureMessage(
          "general/overallCategorySchema doc does not exist"
        );
      }
    });
  }, [authContextLoaded, db, user]);

  //Get Articles for Season if not already set
  const getCollectionOrCategoryKeysForSeason = async (seasonKey, type) => {
    if (type === "collection" && collectionKeysBySeason[seasonKey] != null) {
      return collectionKeysBySeason[seasonKey];
    }

    if (
      type === "generalCategory" &&
      generalCategoryKeysBySeason[seasonKey] != null
    ) {
      return generalCategoryKeysBySeason[seasonKey];
    }

    const result = await retrieveSlimArticlesBySeason(seasonKey);

    const collectionKeyList = flattenDeep(
      Object.values(result).map(
        (article) => article[`${seasonKey}_collectionKeys`]
      )
    );

    const generalCategoryKeyList = flattenDeep(
      Object.values(result).map(
        (article) => article[`${seasonKey}_generalCategoryKey`]
      )
    );

    setCollectionKeysBySeason((prev) => ({
      ...prev,
      [`${seasonKey}`]: collectionKeyList
    }));

    setGeneralCategoryKeysBySeason((prev) => ({
      ...prev,
      [`${seasonKey}`]: generalCategoryKeyList
    }));

    if (type === "collection") {
      return collectionKeyList;
    } else if (type === "generalCategory") {
      return generalCategoryKeyList;
    } else {
      return [];
    }
  };

  const getCollectionKeysForSeason = async (seasonKey) => {
    return await getCollectionOrCategoryKeysForSeason(seasonKey, "collection");
  };

  const getGeneralCategoryKeysForSeason = async (seasonKey) => {
    return await getCollectionOrCategoryKeysForSeason(
      seasonKey,
      "generalCategory"
    );
  };

  return (
    <ArticleReaderContext.Provider
      value={{
        activeSeasons,
        schemas,
        overallCategories,
        getCollectionKeysForSeason,
        getGeneralCategoryKeysForSeason,
        copiedSizeRun,
        setCopiedSizeRun
      }}
    >
      {children}
    </ArticleReaderContext.Provider>
  );
};

export default ArticleReaderContextProvider;

export const useArticleReader = () => useContext(ArticleReaderContext);

//Helper Function

const retrieveSlimArticlesBySeason = async (season) => {
  try {
    let searchParameters = {
      q: "*",
      filter_by: `seasons:=${season}`,
      // filter_by: `seasons:=${season} && ${season}_collectionKeys:[${collectionKey}]`,
      per_page: 200,
      include_fields: `id, ${season}_collectionKeys, ${season}_generalCategoryKey, dropped`
    };

    let retrievedArticles = {};
    let result = await articleSearchClient
      .collections(
        `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`
      )
      .documents()
      .search(searchParameters);

    result.hits.map((hit) =>
      !hit.document.dropped
        ? (retrievedArticles[hit.document.id] = hit.document)
        : null
    );

    if (result.hits.length < result.found) {
      const pages = Math.ceil(result.found / result.hits.length);
      for (let page = 2; page <= pages; page++) {
        searchParameters.page = page;
        let result = await articleSearchClient
          .collections(
            `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`
          )
          .documents()
          .search(searchParameters);
        result.hits.map((hit) =>
          !hit.document.dropped
            ? (retrievedArticles[hit.document.id] = hit.document)
            : null
        );
      }
    }
    console.log(retrievedArticles);
    return retrievedArticles;
  } catch (error) {
    console.log(error);
  }
};
