import React, { Fragment, useState } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useArticleReader } from "database/articles/useArticleReader";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";
import ImageComponent from "components/shared/ImageComponent";
import NumberFormat from "react-number-format";
import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS
} from "helpers/designConstants";
import { Button, MiniButton } from "../standardElements";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import RemoveIcon from "@mui/icons-material/Remove";
import { netPrice } from "helpers/helperFunctions";

const CartArticleCard = ({
  seasonKey,
  articleDetails,
  formControl,
  getFormValues,
  setFormValue,
  priceToShow,
  onZoomArticle,
  didSelectRemove,
  showRemoveButton = true
}) => {
  const { t, i18n } = useTranslation();

  const { copiedSizeRun, setCopiedSizeRun } = useArticleReader();
  const [unpastedSizes, setUnpastedSizes] = useState([]);

  const copySizeRun = () => {
    const currentValues = getFormValues();
    let sizeRunToCopy = {};
    currentValues[articleDetails.id].orderQtys.forEach((cap, index) => {
      sizeRunToCopy[articleDetails.userFriendlySizes[index]] = cap;
    });
    setCopiedSizeRun(sizeRunToCopy);
    toast.success(
      t("copied_size_run_notification", {
        articleNumber: articleDetails.articleNumber
      })
    );
  };

  const pasteSizeRun = () => {
    let unPasted = [];
    articleDetails.userFriendlySizes.forEach((size, index) => {
      if (size in copiedSizeRun) {
        setFormValue(
          `${articleDetails.id}.orderQtys.${index}`,
          copiedSizeRun[size] > 0 ? copiedSizeRun[size] : undefined
        );
      } else {
        unPasted.push(size);
      }
      setUnpastedSizes(unPasted);
    });
  };

  const clearSizeRun = () => {
    const sizes = Object.keys(getFormValues(`${articleDetails.id}.orderQtys`));
    sizes.forEach((size, index) => {
      setFormValue(`${articleDetails.id}.orderQtys.${index}`, undefined);
    });
    setUnpastedSizes([]);
  };

  return (
    <Card>
      <ImageWrapper>
        <Image
          imageURLs={
            articleDetails.imageURLs?.filter(
              (url) => url.includes("_MAIN_") && url.includes("_thumb")
            )[0]
          }
        />
      </ImageWrapper>
      <InfoWrapper>
        <ArticleInfo>
          <ArticleName>{articleDetails.articleName}</ArticleName>
          <ArticleNumber>{articleDetails.articleNumber}</ArticleNumber>
          <ArticleColor>
            {i18n.language === "en"
              ? articleDetails.color
              : articleDetails.color_fr}
          </ArticleColor>
          {articleDetails != null && (
            <ArticlePrice>
              {priceToShow === "MSRP" ? (
                `$ ${articleDetails.retailPrice} ${t("MSRP")}`
              ) : priceToShow === "WHSL" ? (
                `$ ${netPrice(
                  articleDetails.retailPrice,
                  articleDetails.priceCode
                )} ${t("WHSL")}`
              ) : priceToShow === "MSRP+WHSL" ? (
                <>
                  <div>{`$ ${articleDetails.retailPrice} ${t("MSRP")} /`}</div>
                  <div>{`$ ${netPrice(
                    articleDetails.retailPrice,
                    articleDetails.priceCode
                  )} ${t("WHSL")}`}</div>
                </>
              ) : priceToShow === "NONE" ? (
                ""
              ) : (
                `$ ${articleDetails?.retailPrice} ${t("MSRP")}`
              )}
            </ArticlePrice>
          )}
          <IntroDate>
            <span>{t("intro_date_label")}: </span>
            {i18n.language === "en"
              ? articleDetails?.[`${seasonKey}_deliveryDate`]
              : articleDetails?.[`${seasonKey}_deliveryDate_fr`]}
          </IntroDate>
        </ArticleInfo>
        <ActionButtonGroup>
          <ActionButton onClick={() => onZoomArticle(articleDetails)}>
            <ZoomInIcon />
          </ActionButton>
          {showRemoveButton && (
            <AddRemoveButton isSelected>
              <RemoveIcon onClick={() => didSelectRemove(articleDetails)} />
            </AddRemoveButton>
          )}
        </ActionButtonGroup>
      </InfoWrapper>
      <SizeInputWrapper>
        <SizeInputContainer>
          <Fragment>
            <LabelBox>
              <LimitsInputTitle>{""}</LimitsInputTitle>
              {articleDetails.inventory?.incoming != null ? (
                articleDetails.inventory.incoming.map((dateData, index) => (
                  <InventoryDateTitle key={dateData.date} row={index}>
                    {new Date(dateData.date).toLocaleString(i18n.language, {
                      dateStyle: "medium"
                    })}
                  </InventoryDateTitle>
                ))
              ) : (
                <InventoryDateTitle>NO DATA</InventoryDateTitle>
              )}
              <InventoryTotalTitle>{t("total")}</InventoryTotalTitle>
            </LabelBox>
            {articleDetails.userFriendlySizes.map((friendlySize, index) => {
              const databaseSize = articleDetails.sizes[index];
              return (
                <SizeContainer key={friendlySize}>
                  <SizeTitle>{friendlySize}</SizeTitle>

                  <Controller
                    render={({ field }) => {
                      return (
                        <SizeInput
                          $unPastedHighlight={
                            unpastedSizes.includes(friendlySize) ? true : false
                          }
                          placeholder=''
                          onValueChange={(v) => {
                            if (unpastedSizes.includes(friendlySize))
                              setUnpastedSizes(
                                unpastedSizes.filter(
                                  (unPastedSize) =>
                                    unPastedSize !== friendlySize
                                )
                              );
                            field.onChange(
                              v.floatValue != null ? v.floatValue : null
                            );
                          }}
                          value={field.value || ""}
                          displayType='input'
                          type='text'
                          thousandSeparator={false}
                          allowNegative={false}
                          decimalScale={0}
                        />
                      );
                    }}
                    name={`${articleDetails.id}.orderQtys.${index}`}
                    // defaultValue={null}
                    control={formControl}
                  />
                  {articleDetails.inventory?.incoming != null ? (
                    articleDetails.inventory.incoming.map((dateData, index) => (
                      <InventoryQty key={dateData.date} row={index}>
                        {dateData.sizes[databaseSize] != null
                          ? dateData.sizes[databaseSize]
                          : "-"}
                      </InventoryQty>
                    ))
                  ) : (
                    <InventoryQty row={index}>-</InventoryQty>
                  )}
                  <InventoryQtyTotal>
                    {articleDetails?.inventory.overallTotals == null
                      ? "-"
                      : articleDetails?.inventory.overallTotals?.sizes[
                          databaseSize
                        ] != null
                      ? articleDetails?.inventory.overallTotals?.sizes[
                          databaseSize
                        ]
                      : "-"}
                  </InventoryQtyTotal>
                </SizeContainer>
              );
            })}
          </Fragment>
        </SizeInputContainer>
        <CopyPasteArea>
          <CopyPasteButton type='button' onClick={copySizeRun}>
            {t("copy_size_run_button")}
          </CopyPasteButton>
          <CopyPasteButton
            type='button'
            disabled={Object.keys(copiedSizeRun).length === 0}
            onClick={pasteSizeRun}
          >
            {t("paste_size_run_button")}
          </CopyPasteButton>
          <CopyPasteButton type='button' onClick={clearSizeRun}>
            {t("clear_button")}
          </CopyPasteButton>
        </CopyPasteArea>
      </SizeInputWrapper>
    </Card>
  );
};

const Card = styled.div`
  width: 100%;
  margin-bottom: 5px;
  background-color: white;
  display: flex;
  position: relative;
`;

const ImageWrapper = styled.div`
  padding: 5px;
  width: 150px;
  flex-shrink: 0;
`;

const Image = styled(ImageComponent)`
  max-width: 150px;
  max-height: 150px;
`;

const InfoWrapper = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  flex-shrink: 0;
  border-right: 1px solid ${COLORS.secondary};
`;

const ArticleInfo = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-between;
`;

const ArticleName = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleNumber = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleColor = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticlePrice = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 5px;
`;

const IntroDate = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  text-transform: uppercase;

  & > span {
    font-weight: ${FONTWEIGHT.heavy};
  }
`;

const ActionButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ActionButton = styled.div`
  margin: 5px;
  width: 30px;
  height: 30px;
  border: 2px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  color: ${COLORS.secondary};
  /* align-self: flex-end; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-color: ${({ limitWarnings }) => limitWarnings && COLORS.alertOrange};
  color: ${({ limitWarnings }) => limitWarnings && COLORS.alertOrange};

  :hover {
    background: ${({ limitWarnings }) =>
      limitWarnings ? COLORS.alertOrange : COLORS.secondary};
    color: white;
  }
`;

const AddRemoveButton = styled.div`
  margin: 5px;
  width: 30px;
  height: 30px;
  border: 2px solid
    ${(props) => (props.isSelected ? COLORS.alertRed : COLORS.select)};
  border-radius: ${CONSTANTS.borderRadius};
  color: ${(props) => (props.isSelected ? COLORS.alertRed : COLORS.select)};
  /* align-self: flex-end; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: ${(props) =>
      props.isSelected ? COLORS.alertRed : COLORS.select};
    color: white;
  }
`;

const SizeInputWrapper = styled.div`
  overflow-x: hidden;
`;

const SizeInputContainer = styled.div`
  padding: 10px;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
`;

const LabelBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
`;

const LimitsInputTitle = styled.div`
  padding-bottom: 17px;
  padding-right: 5px;
  text-transform: uppercase;
`;

const InventoryDateTitle = styled.div`
  width: 100%;
  padding: 3px;
  padding-right: 5px;
  text-align: right;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  background-color: ${({ row }) => row % 2 === 0 && COLORS.tertiary};
  white-space: nowrap;
`;

const InventoryTotalTitle = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.text};
  padding: 3px;
  padding-right: 5px;
  text-align: right;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.heavy};
  background-color: ${COLORS.secondary};
  text-transform: uppercase;
`;

const SizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 52px;
  /* margin: 5px; */
  flex-shrink: 0;
`;

const SizeTitle = styled.div`
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid ${COLORS.secondary};
  width: 100%;
  text-align: center;
`;

const SizeInput = styled(NumberFormat)`
  width: 45px;
  text-align: center;
  border: 2px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  padding: 5px;
  margin: 5px 0;
  background-color: ${COLORS.tertiary};
  font-size: ${FONTSIZE.small};
  color: ${(props) => {
    if (props.$orangeHighlight) {
      return COLORS.alertOrange;
    } else if (props.$redHighlight) {
      return COLORS.alertRed;
    } else {
      return undefined;
    }
  }};
  border-color: ${(props) => {
    if (props.$orangeHighlight) {
      return COLORS.alertOrange;
    } else if (props.$redHighlight) {
      return COLORS.alertRed;
    } else {
      return undefined;
    }
  }};
  -webkit-appearance: none;

  &:focus {
    outline: none !important;
  }

  border-color: ${(props) => {
    if (props.$unPastedHighlight) {
      return COLORS.alertOrange;
    }
  }};
`;

const InventoryQty = styled.div`
  width: 100%;
  padding: 3px;
  text-align: center;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  background-color: ${({ row }) => row % 2 === 0 && COLORS.tertiary};
`;

const InventoryQtyTotal = styled.div`
  width: 100%;
  /* margin-top: 3px; */
  border-top: 1px solid ${COLORS.text};
  padding: 3px;
  text-align: center;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.heavy};
  background-color: ${COLORS.secondary};
`;

const Selections = styled.div`
  margin: 8px 0 18px;
  color: ${COLORS.text};
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
`;

const CopyPasteArea = styled.div`
  width: 100%;
  display: flex;
  padding: 0 10px;
`;

const CopyPasteButton = styled(MiniButton)`
  margin-right: 5px;
`;

export default CartArticleCard;
