import Excel from "exceljs/dist/es5/exceljs.browser";

export const generateOrderForm = async (orderFormURL, articleData, order) => {
  const orderForm = await getOrderForm(orderFormURL);
  let completedOrderForm;
  const fileBuffer = await readFileAsync(orderForm);
  try {
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(fileBuffer);
    const worksheet = workbook.worksheets[0];

    let articleMap = {};
    let sections = [];

    //Starting Section Index at -1 so that the first section makes it 0 for the array
    let sectionIndex = -1;
    let articleDataStartRow;

    worksheet.eachRow((row, rowNumber) => {
      if (row.getCell(5).value === "STYLE") {
        articleDataStartRow = rowNumber + 1;
        sectionIndex++;
        let sizes = {};
        row.eachCell((cell, colNumber) => {
          if (colNumber >= 31) {
            sizes[cell.value] = colNumber;
          }
        });
        sections.push(sizes);
      } else if (
        articleDataStartRow != null &&
        rowNumber >= articleDataStartRow
      ) {
        const articleNumber = row.getCell(5).value;
        articleMap[articleNumber] = {
          sectionIndex,
          rowNumber
        };
      }
    });

    Object.entries(order.qtys).forEach(([articleID, sizes]) => {
      const articleNumber = articleData[articleID]?.articleNumber;
      if (articleNumber != null && articleMap[articleNumber] != null) {
        const { rowNumber, sectionIndex } = articleMap[articleNumber];
        Object.entries(sizes).forEach(([size, qty]) => {
          // const cleanedSize = size.replaceAll(" ", "");
          //Doing some mapping here to match up stored userFriendlySize to orderFormSize (stored as sizes in the article)
          const sizeIndex = articleData[articleID].userFriendlySizes.findIndex(
            (friendlySize) => friendlySize === size
          );
          const cleanedSize = articleData[articleID].sizes[
            sizeIndex
          ]?.replaceAll(" ", "");
          const colNumber = sections[sectionIndex][cleanedSize];
          if (colNumber != null) {
            const articleRow = worksheet.getRow(rowNumber);
            //This next line forces the cell to be a number format
            articleRow.getCell(colNumber).value =
              +articleRow.getCell(colNumber).value;
            articleRow.getCell(colNumber).value += qty;
          }
        });
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    completedOrderForm = buffer;
    return buffer;
  } catch (error) {
    console.log(error);
  }
  return completedOrderForm;
};

//HELPER FUNCTIONS
const getOrderForm = async (url) => {
  let response = await fetch(url);
  return await response.blob();
};

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
};
