import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

import SearchBar from "components/shared/SearchBar";
import SortDropdown from "./SortDropdown";
import SaveIcon from "@mui/icons-material/Save";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import OrderPickerModal from "./OrderPickerModal";
import OrderPickerDropdown from "./OrderPickerDropdown";

const ProductPickerHeader = ({
  searchBarRef,
  searchTermChanged,
  currentSort,
  onSortChange,
  onCartSelected,
  onSaveButtonPressed,
  enableCartButton = true,
  enableSaveButton = false,
  showOrderPicker = true,
  selectedArticles,
  orders,
  selectedOrderID,
  onSelectedOrderID
}) => {
  const { t } = useTranslation();
  //STATE
  const [showOrderPickerModal, setShowOrderPickerModal] = useState(false);
  const [shouldOpenCart, setShouldOpenCart] = useState(false);

  const cartButtonPressed = () => {
    if (selectedOrderID != null) {
      setShouldOpenCart(false);
      onCartSelected();
    } else {
      setShouldOpenCart(true);
      setShowOrderPickerModal(true);
    }
  };

  const onOrderIDSelection = (orderID) => {
    onSelectedOrderID(orderID, orderID != null ? shouldOpenCart : false);
    setShouldOpenCart(false);
  };

  return (
    <Header>
      <LeftHeader>
        <SearchBar ref={searchBarRef} searchTermChanged={searchTermChanged} />
        <Spacer />
        <SortDropdown
          alignLeftOrRight='left'
          onSelection={onSortChange}
          currentSort={currentSort}
        />
        <Spacer />
        {/* <SelectAllDropdown alignLeftOrRight='left' /> */}
      </LeftHeader>
      {showOrderPicker && (
        <CenterHeader>
          {selectedOrderID != null && (
            <EditingOrderLabel>{t("editing_order")}</EditingOrderLabel>
          )}
          <OrderName>
            {selectedOrderID == null
              ? t("no_order_selected")
              : orders[selectedOrderID].name}
          </OrderName>
          <StyledEditButton onClick={() => setShowOrderPickerModal(true)} />
        </CenterHeader>
      )}
      <RightHeader>
        {enableSaveButton ? (
          <>
            <Spacer />
            <SaveButton onClick={onSaveButtonPressed}>
              <SaveIcon fontSize='large' />
            </SaveButton>
          </>
        ) : undefined}
        {enableCartButton ? (
          <>
            <Spacer />
            <CartButton onClick={cartButtonPressed}>
              <ShoppingCartIcon fontSize='large' />
              <div>{selectedArticles?.length}</div>
            </CartButton>
          </>
        ) : undefined}
      </RightHeader>
      <OrderPickerModal
        title={t("create_order")}
        show={showOrderPickerModal}
        onClose={() => setShowOrderPickerModal(false)}
        orders={
          orders == null
            ? null
            : Object.values(orders).filter(
                (order) => order.isRepManaged && order.completionDate == null
              )
        }
        selectedOrderID={selectedOrderID}
        onSelectedOrderID={onOrderIDSelection}
      />
    </Header>
  );
};

const Header = styled.div`
  grid-column: 1 / span 2;
  display: flex;
  justify-content: space-between;
`;
const LeftHeader = styled.div`
  display: flex;
  align-items: stretch;
`;

const Spacer = styled.div`
  width: 10px;
`;

const CenterHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.text};
  text-transform: uppercase;
`;

const EditingOrderLabel = styled.div`
  color: ${COLORS.alertOrange};
`;

const OrderName = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.large};
  padding: 0 10px;
`;

const StyledEditButton = styled(EditIcon)`
  cursor: pointer;
`;

const RightHeader = styled.div`
  display: flex;
`;

const SaveButton = styled.div`
  position: relative;
  background: ${COLORS.primaryInverted};
  padding: 0 5px;
  color: ${COLORS.text};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};

  @media (hover: hover) {
    &:hover {
      background: ${COLORS.secondary};
      /* color: ${COLORS.primaryInverted}; */
    }
  }
`;

const CartButton = styled.div`
  position: relative;
  background: ${COLORS.primaryInverted};
  padding: 0 5px;
  color: ${COLORS.text};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};

  @media (hover: hover) {
    &:hover {
      background: ${COLORS.secondary};
      /* color: ${COLORS.primaryInverted}; */
    }
  }

  div {
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 12px;
    background: ${COLORS.green};
    color: white;
    padding: 3px;
    min-width: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default ProductPickerHeader;
