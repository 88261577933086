import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/shared/modal/Modal";
import styled from "styled-components/macro";
import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS
} from "helpers/designConstants";
import LabelledTextInput from "components/shared/LabelledTextInput";
import { Button } from "components/shared/standardElements";
import Checkbox from "components/shared/Checkbox";
import { useArticleReader } from "database/articles/useArticleReader";
import SeasonPicker from "components/shared/createEditBookingModal/SeasonPicker";

import { useForm, Controller } from "react-hook-form";
import { useEffect } from "react";

const CreateEditCatalogueModal = ({
  title,
  show,
  onClose,
  onCreateUpdateCatalogue,
  catalogue,
  ...rest
}) => {
  //HOOKS
  const { t } = useTranslation();
  const { activeSeasons, schemas } = useArticleReader();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm();

  useEffect(() => {
    setValue("catalogueName", catalogue?.name);
    setValue(
      "seasonOptions",
      catalogue
        ? {
            seasonKey: catalogue.seasonKey,
            seasonName: catalogue.seasonName,
            seasonName_fr: catalogue.seasonName_fr,
            siloKey: catalogue.seasonSiloKey,
            siloName: catalogue.seasonSiloName,
            siloName_fr: catalogue.seasonSiloName_fr
          }
        : null
    );
    setValue(
      "priceToShowPreview",
      catalogue ? catalogue.priceToShowPreview : "MSRP"
    );
    setValue("sortOrder", catalogue ? catalogue.sortOrder : "collection");
  }, [catalogue, setValue]);

  const watchPricePreviewSelection = watch("priceToShowPreview");
  const watchSortOrderSelection = watch("sortOrder");

  const onSubmitForm = (data) => {
    onCreateUpdateCatalogue(data);
  };

  const onSelectClose = () => {
    onClose();
  };

  return (
    <Modal
      title={title}
      show={show}
      onClose={onSelectClose}
      disableCloseOnOutsideClick
      maxHeight={"90vh"}
      {...rest}
    >
      <Container>
        <StyledForm onSubmit={handleSubmit(onSubmitForm)}>
          <StyledLabelledTextInput
            shouldAutoFocus
            labelText={t("catalogue_name")}
            type='text'
            name='catalogueName'
            id='catalogueName'
            placeholder={t("catalogue_name")}
            {...register("catalogueName", { required: true })}
            error={errors?.bookingName}
          />
          {errors?.catalogueName?.type === "required" && (
            <StyledInputErrorMessage>
              You must provide a name
            </StyledInputErrorMessage>
          )}
          <Controller
            control={control}
            name='seasonOptions'
            render={({ field }) => (
              <StyledSeasonPicker
                isDisabled={catalogue?.articleIDs.length > 0}
                activeSeasons={activeSeasons}
                schemas={schemas}
                {...field}
                selectionRequiredError={
                  errors?.seasonOptions?.type === "required"
                }
              />
            )}
            rules={{ required: true }}
          />
          <PricingWrapper>
            <PricingTitleWrapper>
              <PricingTitle>{t("show_price")}</PricingTitle>
            </PricingTitleWrapper>
            <PricingInnerContainer>
              <StyledCheckbox
                color={COLORS.text}
                label={t("MSRP")}
                selected={watchPricePreviewSelection === "MSRP"}
                type='radio'
                id='radio-preview-msrp'
                value='MSRP'
                {...register("priceToShowPreview")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={t("WHSL")}
                selected={watchPricePreviewSelection === "WHSL"}
                id='radio-preview-whsl'
                type='radio'
                value='WHSL'
                {...register("priceToShowPreview")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={`${t("MSRP")} + ${t("WHSL")}`}
                selected={watchPricePreviewSelection === "MSRP+WHSL"}
                id='radio-preview-msrp+whsl'
                type='radio'
                value='MSRP+WHSL'
                {...register("priceToShowPreview")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={t("none")}
                selected={watchPricePreviewSelection === "NONE"}
                id='radio-preview-none'
                type='radio'
                value='NONE'
                {...register("priceToShowPreview")}
              />
            </PricingInnerContainer>
          </PricingWrapper>
          <SortOrderWrapper>
            <SortOrderTitleWrapper>
              <SortOrderTitle>{t("sort")}</SortOrderTitle>
            </SortOrderTitleWrapper>
            <SortOrderInnerContainer>
              <StyledCheckbox
                color={COLORS.text}
                label={t("collection")}
                selected={watchSortOrderSelection === "collection"}
                id='radio-collection'
                type='radio'
                value='collection'
                {...register("sortOrder")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={t("category")}
                selected={watchSortOrderSelection === "category"}
                id='radio-category'
                type='radio'
                value='category'
                {...register("sortOrder")}
              />
            </SortOrderInnerContainer>
          </SortOrderWrapper>
          <Button>
            {catalogue == null ? t("create_button") : t("update_button")}
          </Button>
        </StyledForm>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  height: 100%;
  min-width: 350px;
  padding: 5px;
  /* max-height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLabelledTextInput = styled(LabelledTextInput)`
  input {
    border-color: ${({ error }) => error && COLORS.alertRed};
  }
`;

const StyledInputErrorMessage = styled.p`
  margin-top: 0px;
  padding: 5px;
  color: ${COLORS.alertRed};
  font-size: ${FONTSIZE.xsmall};
  align-self: flex-start;
`;

const StyledSeasonPicker = styled(SeasonPicker)`
  width: 100%;
  margin: 20px 0;
`;

const PricingWrapper = styled.div`
  margin: 25px 0 10px;
  width: 100%;
  position: relative;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: stretch;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
`;

const PricingTitleWrapper = styled.div`
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const PricingTitle = styled.div`
  background: ${COLORS.primaryInverted};
  padding: 0 5px;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
`;

const PricingInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 40px;
`;

const InnerContainerTitle = styled.div`
  padding: 0 5px 10px;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  text-decoration: underline;
`;

const Divider = styled.div`
  width: 1px;
  margin: 30px 0;
  background-color: ${COLORS.secondary};
`;

const StyledCheckbox = styled(Checkbox)`
  text-transform: uppercase;
  color: ${COLORS.text};
  padding: 8px 0;
`;

const SortOrderWrapper = styled.div`
  margin: 10px 0 50px;
  width: 100%;
  position: relative;
  display: flex;
  /* flex-direction: column; */

  align-items: stretch;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
`;

const SortOrderTitleWrapper = styled.div`
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const SortOrderTitle = styled.div`
  background: ${COLORS.primaryInverted};
  padding: 0 5px;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
`;

const SortOrderInnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 40px;
`;

export default CreateEditCatalogueModal;
