import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import MenuItem from "./MenuItem";

const NestedNav = ({
  shouldAllowSelection = true,
  userSelectedItem,
  onAddArticlesInGroup,
  onRemoveArticlesInCollection,
  currentlySelectedArticleGroupKeys = [],
  seasonArticleGroupKeys = [],
  onUpdateCurrentNavigationLocation,
  navListMap,
  initialSelection,
  backgroundColor,
  className
}) => {
  //LOCAL STATE
  const [currentSelection, setCurrentSelection] = useState([]);

  useEffect(() => {
    initialSelection && setCurrentSelection(initialSelection);
    onUpdateCurrentNavigationLocation(initialSelection);
  }, [initialSelection, onUpdateCurrentNavigationLocation]);

  const userMadeSelection = async (item, selection) => {
    selection.reverse();
    setCurrentSelection(selection);
    userSelectedItem(item);
    onUpdateCurrentNavigationLocation(selection);
  };

  return (
    <Content className={className}>
      <ScrollArea backgroundColor={backgroundColor}>
        <MenuListWrapper>
          {navListMap?.map((item, idx) => (
            <MenuItem
              shouldAllowSelection={shouldAllowSelection}
              item={item}
              key={item?.name}
              selectionWasMade={userMadeSelection}
              depth={0}
              location={[idx]}
              componentIndex={idx}
              currentSelection={currentSelection}
              onAddArticlesInGroup={onAddArticlesInGroup}
              onRemoveArticlesInCollection={onRemoveArticlesInCollection}
              currentlySelectedArticleGroupKeys={
                currentlySelectedArticleGroupKeys
              }
              seasonArticleGroupKeys={seasonArticleGroupKeys}
              allArticlesInCollectionSelected={
                seasonArticleGroupKeys.filter((groupKey) =>
                  groupKey.includes(item.key)
                ).length > 0 &&
                seasonArticleGroupKeys.filter((groupKey) =>
                  groupKey.includes(item.key)
                ).length ===
                  currentlySelectedArticleGroupKeys.filter((groupKey) =>
                    groupKey.includes(item.key)
                  ).length
              }
            />
          ))}
        </MenuListWrapper>
      </ScrollArea>
    </Content>
  );
};

const Content = styled.div`
  height: 100%;
  overflow: hidden;
  max-width: 300px;
`;

const ScrollArea = styled.div`
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-right: 10px;
  background: ${({ backgroundColor }) => backgroundColor};
`;

const MenuListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  height: 100%;
  list-style-type: none;
`;

export default NestedNav;
