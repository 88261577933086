import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import ScrollAreaCard from "components/shared/ScrollAreaCard";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import { MiniButton } from "components/shared/standardElements";
import { useAuth } from "database/useAuth";

const OrderCard = ({
  deviceID,
  order,
  booking,
  onSelectEditName,
  onSelectEditOrder,
  onSelectViewSummary,
  onSelectSubmitOrder,
  onSelectTransferToBuyer,
  onSelectDuplicate,
  onSelectDownloadOrder,
  currentlyEditingInArticleTab,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();

  return (
    <StyledScrollAreaCard {...props} inProgress={order.completionDate == null}>
      <Content>
        <Name>
          {order.name || `${booking.courseName} - ${booking.name} Order`}
        </Name>
        {/* <Season>{`${booking.seasonName}: ${booking.seasonSiloName}`}</Season> */}
        <CreationDate>{`${t("created_on")}: ${new Date(
          order.creationDate
        ).toLocaleString(i18n.language, {
          month: "short",
          day: "numeric",
          year: "numeric"
        })}`}</CreationDate>
        <Status inProgress={order.completionDate == null}>
          {order.completionDate != null
            ? `${t("completed_on")}: ${new Date(
                order.completionDate
              ).toLocaleString(i18n.language, {
                month: "short",
                day: "numeric",
                year: "numeric"
              })}`
            : t("order_in_progress")}
        </Status>
        {currentlyEditingInArticleTab && (
          <Status inProgress>{t("selected_in_articles_tab")}</Status>
        )}
        <ButtonGroup>
          <ButtonGroupRow>
            {(user.type === "adidasRep" || order.completionDate == null) && (
              <MiniButton
                onClick={() => onSelectEditOrder(order)}
                disabled={order.editorID != null && order.editorID !== deviceID}
              >
                {t("edit_order_button")}
              </MiniButton>
            )}
            <MiniButton onClick={() => onSelectViewSummary(order)}>
              {t("summary")}
            </MiniButton>
            {(user.type === "adidasRep" || order.completionDate == null) && (
              <MiniButton onClick={() => onSelectEditName(order)}>
                {t("edit_name")}
              </MiniButton>
            )}
            {order.isRepManaged &&
              order.completionDate == null &&
              user.type === "adidasRep" && (
                <MiniButton onClick={() => onSelectTransferToBuyer(order)}>
                  {t("transfer_to_buyer")}
                </MiniButton>
              )}
            <MiniButton onClick={() => onSelectDuplicate(order)}>
              {t("duplicate")}
            </MiniButton>
            <MiniButton onClick={() => onSelectDownloadOrder(order)}>
              {t("download_order")}
            </MiniButton>
            {!order.isRepManaged &&
              order.completionDate == null &&
              user.type === "bookingPurchaser" && (
                <MiniButton
                  highlightedOutline
                  onClick={() => onSelectSubmitOrder(order)}
                >
                  {t("submit_order")}
                </MiniButton>
              )}
          </ButtonGroupRow>
        </ButtonGroup>
      </Content>
    </StyledScrollAreaCard>
  );
};

const StyledScrollAreaCard = styled(ScrollAreaCard)`
  border: 1px solid
    ${({ inProgress }) => (inProgress ? COLORS.alertOrange : "inherit")};
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${COLORS.text};
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: auto;
`;

const Name = styled.div`
  /* flex-grow: 1; */
  font-size: ${FONTSIZE.medium};
  text-transform: uppercase;
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid ${COLORS.secondary};
`;

const Status = styled.div`
  flex-grow: 1;
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};
  color: ${({ inProgress }) => (inProgress ? COLORS.alertOrange : "inherit")};
  /* margin-bottom: 10px; */
`;

const CreationDate = styled.div`
  padding: 5px 0 10px;
  text-transform: uppercase;
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.heavy};
`;

const ButtonGroupRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${MiniButton} {
    margin: 10px;
  }
`;

const ButtonGroup = styled.div`
  /* flex-grow: 3; */
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid ${COLORS.secondary};
  /* padding: 10px 0 0; */
  display: flex;
  flex-direction: column;

  ${ButtonGroupRow} {
    margin: 5px;
  }
`;

export default OrderCard;
