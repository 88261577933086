import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import Modal from "components/shared/modal/Modal";
import ArticleImageGallery from "components/shared/ArticleImageGallery";

const ArticleZoomModal = ({ show, onClose, imageList }) => {
  //HOOKS
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onClose={onClose}
      height='88vh'
      width='96vw'
      title={t("image_gallery_title")}
    >
      <ZoomContent>
        <ArticleImageGallery imageList={imageList} />
      </ZoomContent>
    </Modal>
  );
};

const ZoomContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default ArticleZoomModal;
