import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/shared/modal/Modal";
import styled from "styled-components/macro";
import LabelledTextInput from "components/shared/LabelledTextInput";
import { Button } from "components/shared/standardElements";
import { useForm } from "react-hook-form";
import { COLORS, FONTSIZE } from "helpers/designConstants";

const CreateEditOrderNameModal = ({
  title,
  show,
  onClose,
  orderName,
  onSaveNewName,
  type
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSave = (data) => {
    onSaveNewName(data.name);
  };

  return (
    <Modal
      title={title}
      show={show}
      onClose={onClose}
      disableCloseOnOutsideClick
    >
      <StyledForm onSubmit={handleSubmit(onSave)}>
        <LabelledTextInput
          labelText={t("name_title")}
          type='text'
          id='name'
          name='name'
          defaultValue={orderName}
          placeholder={t("order_name")}
          // onChange={(e) => setNewName(e.target.value)}
          {...register("name", { required: t("name_required_error") })}
        />
        {errors.name && (
          <StyledInputErrorMessage>
            {errors.name.message}
          </StyledInputErrorMessage>
        )}
        <StyledButton>
          {type === "create" ? t("create_button") : t("save_button")}
        </StyledButton>
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInputErrorMessage = styled.div`
  padding: 3px 0 0 5px;
  color: ${COLORS.alertRed};
  font-size: ${FONTSIZE.xsmall};
  align-self: flex-start;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

export default CreateEditOrderNameModal;
