import React, { Fragment, useRef, useState } from "react";
import styled from "styled-components/macro";
import { COLORS, FONTWEIGHT, CONSTANTS } from "helpers/designConstants";
import useOnClickOutside from "helpers/useOnClickOutside";
import { useTranslation } from "react-i18next";
import SortIcon from "@mui/icons-material/Sort";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "components/shared/standardElements";
import LabelledTextInput from "components/shared/LabelledTextInput";

const OrderPickerDropdown = ({
  className,
  alignLeftOrRight = "right",
  onCreateNewOrder,
  orders,
  openOrderPickerDropdown = false,
  selectedOrderID
}) => {
  const { t, i18n } = useTranslation();
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsActive(false));

  const [isActive, setIsActive] = useState(openOrderPickerDropdown);
  const [name, setName] = useState();

  const onClickMenu = () => setIsActive(!isActive);

  const onSave = (e) => {
    e && e.preventDefault();
    onCreateNewOrder(name);
  };
  return (
    <DropDownContainer ref={dropdownRef}>
      <MenuTrigger onClick={onClickMenu} className={className}>
        <EditIcon fontSize='large' />
      </MenuTrigger>
      <Nav isActive={isActive} align={alignLeftOrRight}>
        <StyledForm onSubmit={onSave}>
          <LabelledTextInput
            labelText='name'
            placeholder={"Order Name"}
            onChange={(e) => setName(e.target.value)}
          />
          <StyledButton onClick={onSave}>Create</StyledButton>
        </StyledForm>
        <OrdersList>
          {orders != null &&
            Object.values(orders).map((order) => (
              <OrderItem key={order.id}>{order.name}</OrderItem>
            ))}
        </OrdersList>
      </Nav>
    </DropDownContainer>
  );
};

export default OrderPickerDropdown;

const DropDownContainer = styled.div`
  position: relative;
`;

const MenuTrigger = styled.button`
  padding: 0 5px;
  background: ${COLORS.primaryInverted};
  color: ${COLORS.text};
  cursor: pointer;
  border: none;
  font-weight: ${FONTWEIGHT.heavy};
  border-radius: ${CONSTANTS.borderRadius};
  border: 1px solid ${COLORS.secondary};
  height: 100%;
  /* padding: 10px; */
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding-right: 0px;
  }

  @media (hover: hover) {
    &:hover {
      background: ${COLORS.secondary};
      /* color: ${COLORS.primaryInverted}; */
    }
  }
`;

const NameHeader = styled.div`
  padding: 4px 10px;
  text-transform: uppercase;
  background-color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.primaryInverted};
`;

const Nav = styled.nav`
  text-align: center;
  background: #ffffff;
  border-radius: ${CONSTANTS.borderRadius};
  position: absolute;
  z-index: 1000;
  top: 50px;
  right: ${({ align }) => align === "right" && 0};
  left: ${({ align }) => align === "left" && 0};
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  overflow: hidden;

  opacity: ${(props) => (props.isActive ? 1 : 0)};
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  transform: ${(props) =>
    props.isActive ? "translateY(0)" : "translateY(-20px)"};
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const Spacer = styled.li`
  height: 1px;
  margin: 0 5px;
  background-color: ${COLORS.secondary};
`;

const Item = styled.li`
  /* border-bottom: 1px solid #dddddd; */
  padding: 15px 20px;
  margin: 5px;
  border-radius: ${CONSTANTS.borderRadius};
  cursor: pointer;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
  background-color: ${({ isSelected }) =>
    isSelected ? COLORS.secondary : "inherit"};

  &:hover {
    background-color: ${COLORS.tertiary};
  }
  a {
    text-decoration: none;
    padding: 15px 20px;
    display: block;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const OrdersList = styled.div``;

const OrderItem = styled.div``;
