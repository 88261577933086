import React, { useState, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Select from "react-select";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

const SeasonPicker = forwardRef(
  (
    {
      className,
      activeSeasons,
      schemas,
      defaultValue,
      value,
      onChange,
      selectionRequiredError,
      isDisabled
    },
    inputRef
  ) => {
    //HOOKS
    const { t, i18n } = useTranslation();

    //STATE
    const [seasonOptions, setSeasonOptions] = useState();

    useEffect(() => {
      if (activeSeasons == null || schemas == null) return;
      let options = [];

      activeSeasons.forEach((season) => {
        const seasonOptions = schemas[season.key].schemas.map((schema) => ({
          seasonKey: season.key,
          seasonName: season.name,
          seasonName_fr: season.name_fr,
          siloKey: schema.key,
          siloName: schema.name,
          siloName_fr: schema.name_fr
        }));
        options.push({
          seasonKey: season.key,
          seasonName: season.name,
          seasonName_fr: season.name_fr,
          options: seasonOptions
        });
      });
      setSeasonOptions(options);
    }, [activeSeasons, schemas]);

    const selectMenuCustomStyles = {
      control: (provided, { isFocused }) => ({
        ...provided,
        borderRadius: CONSTANTS.borderRadius,
        border: isFocused
          ? `1px solid ${COLORS.secondary}`
          : selectionRequiredError
          ? `1px solid ${COLORS.alertRed}`
          : `1px solid ${COLORS.secondary}`,
        boxShadow: isFocused ? null : null,
        "&:hover": {
          borderColor: isFocused ? COLORS.secondary : COLORS.secondary
        }
      }),
      menu: (provided) => ({
        ...provided,
        paddingBottom: "0px",
        marginBottom: "0px"
      }),
      menuList: (provided) => ({
        ...provided,
        backgroundColor: COLORS.secondary,
        marginBottom: "0px",
        paddingBottom: "0px"
      }),
      group: (provided) => ({
        ...provided,
        borderBottom: `1px solid ${COLORS.primaryInverted}`,
        marginBottom: "0px"
      }),
      option: (provided, { isSelected, isHighlighted }) => ({
        ...provided,
        backgroundColor: isSelected
          ? COLORS.text
          : isHighlighted
          ? COLORS.secondary
          : undefined,
        color: isSelected ? COLORS.primaryInverted : COLORS.text,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: COLORS.text,
          color: COLORS.primaryInverted
        }
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: COLORS.text,
        fontSize: FONTSIZE.medium,
        padding: "8px 2px",
        margin: 0
      }),
      placeholder: (provided, state) => ({
        ...provided,
        // textTransform: "uppercase",
        fontSize: FONTSIZE.medium,
        color: COLORS.secondary,
        padding: "8px 2px",
        margin: 0
      }),
      input: (provided, state) => ({
        ...provided,
        color: COLORS.text,
        fontSize: FONTSIZE.medium,
        padding: "8px 2px",
        margin: 0
      })
    };

    const formatGroupLabel = (data) => (
      <GroupLabel>
        <span>
          {i18n.language === "en" ? data.seasonName : data.seasonName_fr}
        </span>
      </GroupLabel>
    );

    const formatOptionLabel = (data) => (
      <OptionLabel>
        <span>
          {i18n.language === "en"
            ? `${data.seasonName}: ${data.siloName}`
            : `${data.seasonName_fr}: ${data.siloName_fr}`}
        </span>
      </OptionLabel>
    );

    return (
      <LabeledSeasonSelect className={className}>
        <SelectLabel>{t("select_a_season")}</SelectLabel>
        <Select
          isDisabled={isDisabled}
          inputRef={inputRef}
          placeholder={t("select_a_season")}
          isSearchable={false}
          styles={selectMenuCustomStyles}
          options={seasonOptions}
          formatGroupLabel={formatGroupLabel}
          formatOptionLabel={formatOptionLabel}
          value={value}
          onChange={onChange}
          isOptionSelected={(option) =>
            option.seasonKey === value?.seasonKey && option.key === value?.key
          }
        />
      </LabeledSeasonSelect>
    );
  }
);

export default SeasonPicker;

const LabeledSeasonSelect = styled.div`
  margin: 0 0 20px; ;
`;

const SelectLabel = styled.div`
  /* text-transform: uppercase; */
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding-left: 5px;
  text-transform: uppercase;
`;

const GroupLabel = styled.div`
  text-transform: uppercase;
`;

const OptionLabel = styled.div`
  /* text-transform: uppercase; */
`;
