import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Modal from "components/shared/modal/Modal";
import ProductPickerScrollArea from "components/pages/booking/articlesTab/productPicker/ProductPickerScrollArea";
import ProductPickerHeader from "components/pages/booking/articlesTab/productPicker/ProductPickerHeader";
import { debounce } from "lodash";
import useWindowSize from "helpers/useWindowSize";

import { useDatabaseReader } from "database/useDatabaseReader";
import useArticleSearchReader from "database/articles/useArticleSearchReader";
import useCatalogueWriter from "database/catalogues/useCatalogueWriter";

const CatalogueProductPickerModal = ({ show, onClose, catalogueID }) => {
  //HOOKS
  const { t } = useTranslation();
  const searchBarRef = useRef();
  const { getCatalogueByID } = useDatabaseReader();
  const {
    searchByArticleNameOrNumber,
    searchByCollectionKey,
    retrieveArticlesByIDs
  } = useArticleSearchReader();
  const { updateCatalogueArticleIDs } = useCatalogueWriter();

  const { width } = useWindowSize();

  //STATE
  const [catalogue, setCatalogue] = useState();
  const [selectedSeasonKey, setSelectedSeasonKey] = useState(
    catalogue?.seasonKey
  );
  const [selectedSeasonSiloKey, setSelectedSeasonSiloKey] = useState(
    catalogue?.seasonSiloKey
  );
  const [searchResults, setSearchResults] = useState();
  const [articleSort, setArticleSort] = useState("collection");
  const [initialSelectedArticles, setInitialSelectedArticles] = useState();
  const [selectedArticles, setSelectedArticles] = useState();

  useEffect(() => {
    if (catalogueID == null) return;
    getCatalogueByID(catalogueID).then((catalogue) => {
      setCatalogue(catalogue);
      setSelectedSeasonKey(catalogue.seasonKey);
      setSelectedSeasonSiloKey(catalogue.seasonSiloKey);
    });
  }, [catalogueID, getCatalogueByID]);

  //This should only run on first setup and then selected articles are managed
  //Initial selected articles should only be set once on initial setup
  useEffect(() => {
    if (catalogue == null || initialSelectedArticles != null) return;
    retrieveArticlesByIDs(catalogue.articleIDs).then((result) => {
      setInitialSelectedArticles(Object.values(result));
      if (selectedArticles == null || selectedArticles?.length === 0) {
        setSelectedArticles(Object.values(result));
      }
    });
    setInitialSelectedArticles(catalogue.articles);
    if (selectedArticles == null || selectedArticles?.length === 0) {
      setSelectedArticles(catalogue.articles);
    }
  }, [
    catalogue,
    initialSelectedArticles,
    retrieveArticlesByIDs,
    selectedArticles
  ]);

  const searchTermChanged = debounce(async (searchTerm) => {
    if (searchTerm.length > 0) {
      const results = await searchByArticleNameOrNumber(
        searchTerm,
        selectedSeasonKey
      );
      setSearchResults(results);
    } else {
      setSearchResults();
    }
  }, 350);

  const onSortChange = (sort) => {
    setArticleSort(sort);
  };

  const onCloseProductPicker = () => {
    setCatalogue();
    setInitialSelectedArticles();
    setSelectedArticles();
    onClose();
  };

  //Single Article Selection Toggle
  const onArticleSelection = (selectedArticle) => {
    let articlesToWrite = [];
    if (selectedArticles.some((article) => article.id === selectedArticle.id)) {
      articlesToWrite = selectedArticles.filter(
        (article) => article.id !== selectedArticle.id
      );
    } else {
      articlesToWrite = [...selectedArticles, selectedArticle];
    }
    setSelectedArticles(articlesToWrite);
    updateCatalogueArticleIDs(
      articlesToWrite.map((article) => article.id),
      catalogue.id
    );
  };

  //Articles Addition and Subtraction from Booking
  const onAddArticlesFromGroup = async (groupKey) => {
    const result = await searchByCollectionKey(selectedSeasonKey, groupKey);
    const newArticles = Object.values(result);
    let newSelectedArticles = [...selectedArticles];
    newArticles.forEach((newArticle) => {
      if (!selectedArticles.some((article) => article.id === newArticle.id)) {
        newSelectedArticles.push(newArticle);
      }
    });
    //console.log(newSelectedArticles);
    setSelectedArticles(newSelectedArticles);
    updateCatalogueArticleIDs(
      newSelectedArticles.map((article) => article.id),
      catalogue.id
    );
  };

  const onRemoveArticlesFromCollection = async (collectionKey) => {
    const newSelectedArticles = selectedArticles.filter(
      (article) =>
        !article[`${selectedSeasonKey}_collectionKeys`].some((item) =>
          item.includes(collectionKey)
        )
    );

    setSelectedArticles(newSelectedArticles);
    updateCatalogueArticleIDs(
      newSelectedArticles.map((article) => article.id),
      catalogue.id
    );
  };

  return (
    <Modal
      title={t("article_selection")}
      show={show}
      onClose={onCloseProductPicker}
      maxHeight={"88vh"}
      width={width > 1350 ? "1300px" : "96vw"}
    >
      <Container>
        <ProductPickerHeader
          searchBarRef={searchBarRef}
          searchTermChanged={searchTermChanged}
          onSortChange={onSortChange}
          enableCartButton={false}
          enableSaveButton={true}
          showOrderPicker={false}
          selectedArticles={selectedArticles}
          onSaveButtonPressed={onCloseProductPicker}
        />
        <ProductPickerScrollArea
          allowMultipleSeasons={true}
          allowOrderEntry={false}
          searchResults={searchResults}
          selectedArticles={selectedArticles}
          articleSort={articleSort}
          onArticleSelection={onArticleSelection}
          onAddArticlesFromGroup={onAddArticlesFromGroup}
          onRemoveArticlesFromCollection={onRemoveArticlesFromCollection}
          initialSelectedArticles={initialSelectedArticles}
          showCollectionHeader={true}
          showInventoryButton={true}
          selectedSeasonKey={selectedSeasonKey}
          selectedSeasonSiloKey={selectedSeasonSiloKey}
        />
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  gap: 10px 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: hidden;
`;

export default CatalogueProductPickerModal;
