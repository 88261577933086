import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import Modal from "components/shared/modal/Modal";
import { Button } from "components/shared/standardElements";

import { COLORS } from "helpers/designConstants";

const UserNoticeModal = ({ show, onClose, title, message, ...props }) => {
  const { t } = useTranslation();

  return (
    <Modal title={title} show={show} onClose={onClose} {...props}>
      <Container>
        <MessageWrapper>{message}</MessageWrapper>
        <ButtonWrapper>
          <StyledButton cancel onClick={onClose} type='button'>
            {t("ok_button")}
          </StyledButton>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 30px;
  max-width: 700px;
`;

const MessageWrapper = styled.div`
  text-align: center;
  color: ${COLORS.text};
  text-transform: uppercase;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin: 15px 5px 0 5px;
`;

export default UserNoticeModal;
