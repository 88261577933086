import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
// import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

let firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

//For emulators this connects to local emulator
if (
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("192.168")
) {
  firebaseConfig.databaseURL = `http://${window.location.hostname}:9000?ns=booking5test-b0a76`;
}

const setupEmulators = () => {
  if (
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("192.168")
  ) {
    //Auth Emulator
    connectAuthEmulator(getAuth(), `http://${window.location.hostname}:9099`);

    //Functions Emulator
    connectFunctionsEmulator(getFunctions(), window.location.hostname, 5001);

    //Firestore Emulator
    const db = getFirestore();
    connectFirestoreEmulator(db, window.location.hostname, 8080);

    //Realtime DB Emulator
    const rtdb = getDatabase();
    connectDatabaseEmulator(rtdb, window.location.hostname, 9000);

    //Storage Emulator
    // const storage = getStorage();
    // connectStorageEmulator(storage, window.location.hostname, 9199);
  }
};

const inititializeFirebase = () => {
  // console.log(process.env);
  // console.log(process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX);
  let firebaseApp;
  if (!getApps().length) {
    firebaseApp = initializeApp(firebaseConfig);
    setupEmulators();
  } else {
    firebaseApp = getApp();
  }
  return firebaseApp;
};

export default inititializeFirebase;

// export default firebaseApp;
