import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import BookingPublicLayout from "../bookingPublic/BookingPublicLayout";

import { useAuth } from "database/useAuth";
import { useArticleReader } from "database/articles/useArticleReader";
import { useCourseDatabase } from "database/useCourseDatabase";
import usePublicOrderWriter from "database/orders/usePublicOrderWriter";
import { useSearchParams } from "react-router-dom";

import { COLORS, CONSTANTS } from "helpers/designConstants";
import { Button } from "components/shared/standardElements";
import ScrollArea from "components/shared/ScrollArea";
import Loading from "components/shared/Loading";

import CreateEditOrderNameModal from "components/shared/sharedOrderTab/CreateEditOrderNameModal";
import OrderCard from "components/shared/sharedOrderTab/OrderCard";
import OrderTabCartModal from "components/shared/sharedOrderTab/OrderTabCartModal";
import ConfirmModal from "components/shared/ConfirmModal";
import OrderSummaryModal from "components/shared/sharedOrderTab/OrderSummaryModal";
import OrderSummaryPDF from "components/shared/downloads/OrderSummaryPDF";
import { generateOrderForm } from "components/shared/downloads/createOrderForms";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import ConfirmOrderSubmissionModal from "./ConfirmOrderSubmissionModal";

let interval = undefined;
let loading_progress = 40;

const OrderingDashboard = () => {
  const { t } = useTranslation();
  const { deviceID, booking, orders, setBookingID } = useCourseDatabase();
  const { overallCategories, activeSeasons } = useArticleReader();
  const { authContextLoaded, user } = useAuth();
  const [searchParams] = useSearchParams();
  const {
    updateDBOrderQtys,
    updateOrderName,
    createNewOrder,
    duplicateOrder,
    completeOrder,
    submitOrder,
    setCurrentEditorID
  } = usePublicOrderWriter();

  //STATE
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState();
  const [showCreateOrderModal, setShowCreateOrderModal] = useState(false);
  const [editNameOrder, setEditNameOrder] = useState();
  const [orderForCart, setOrderForCart] = useState();
  const [orderForSummary, setOrderForSummary] = useState();
  const [orderForDuplication, setOrderForDuplication] = useState();
  const [orderForDownload, setOrderForDownload] = useState();
  const [orderForSubmission, setOrderForSubmission] = useState();
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    if (authContextLoaded && user != null && booking == null) {
      const currentParams = Object.fromEntries([...searchParams]);
      setBookingID(currentParams.bid);
    }
  }, [authContextLoaded, user, booking, searchParams, setBookingID]);

  //Unset editing id on any open orders to keep database in sync
  useEffect(() => {
    //Chrome Failsafe to unset the deviceID on the order if the user refreshes the page
    window.onbeforeunload = (e) => {
      if (orderForCart?.id != null) {
        setCurrentEditorID(null, orderForCart.id);
      }
    };
    //Other Browswers Failsafe to unset the deviceID on the order if the user refreshes the page
    window.onunload = (e) => {
      if (orderForCart?.id != null) {
        setCurrentEditorID(null, orderForCart.id);
      }
    };
    return () => {
      if (orderForCart?.id != null) {
        setCurrentEditorID(null, orderForCart.id);
      }
    };
  }, [orderForCart?.id, setCurrentEditorID]);

  useEffect(() => {
    if (orders == null) return;
    setFilteredOrders(
      Object.values(orders).filter((order) => {
        if (order.isRepManaged && order.completionDate == null) return false;
        return true;
      })
    );
  }, [orders]);

  const onEditOrder = async (order) => {
    setIsLoading(true);
    setOrderForCart(order);
    await setCurrentEditorID(deviceID, order.id);
    setIsLoading(false);
  };

  const onCloseCart = async () => {
    await setCurrentEditorID(null, orderForCart.id);
    setOrderForCart();
  };

  const onSelectViewCart = (order) => {
    setOrderForCart(order);
  };

  const onSaveNewName = async (newName) => {
    setIsLoading(true);
    if (editNameOrder == null && showCreateOrderModal === true) {
      await createNewOrder(newName);
      setShowCreateOrderModal(false);
    } else {
      await updateOrderName(newName, editNameOrder.id);
      setEditNameOrder();
    }
    setIsLoading(false);
  };

  const onDuplicateOrder = (order) => {
    setOrderForDuplication(order);
  };
  const onConfirmDuplicateOrder = async (newName) => {
    setIsLoading(true);
    let order = { ...orderForDuplication };
    delete order.id;
    delete order.completionDate;
    order.name = newName;
    order.creationDate = new Date();
    order.isRepManaged = false;
    await duplicateOrder(order);
    setIsLoading(false);
    setOrderForDuplication();
  };

  const onDownloadOrder = async (order) => {
    setIsLoading(true);
    //Create PDF Order Summary
    const OrderSummary = (
      <OrderSummaryPDF
        bookingArticles={booking?.articles}
        order={order}
        seasonKey={booking?.seasonKey}
        overallCategories={overallCategories}
        courseName={booking?.courseName}
        priceToShow={booking?.priceToShowOrdering}
      />
    );
    const summaryBlob = await pdf(OrderSummary).toBlob();
    saveAs(summaryBlob, `${order.name}-Summary.pdf`);

    setIsLoading(false);
  };

  const onSelectSubmitOrder = (order) => {
    setOrderForSubmission(order);
  };
  const onConfirmSubmitOrder = async (name, email) => {
    setLoadingProgress(15);
    setIsLoading(true);

    const OrderSummary = (
      <OrderSummaryPDF
        bookingArticles={booking?.articles}
        order={orderForSubmission}
        seasonKey={booking?.seasonKey}
        overallCategories={overallCategories}
        courseName={booking?.courseName}
        priceToShow={booking?.priceToShowOrdering}
      />
    );

    //Awaitable helper function to convert blob to base64
    const blobToBase64 = (blob) => {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    };

    //PDF Summary
    const summaryAttachmentBlob = await pdf(OrderSummary).toBlob();
    let base64SummaryPDF = await blobToBase64(summaryAttachmentBlob);
    base64SummaryPDF = base64SummaryPDF.substr(
      base64SummaryPDF.indexOf(",") + 1
    );

    setLoadingProgress(35);

    //Excel Order Form
    //Create Excel Order Form for reps
    let base64Excel = null;
    const selectedSeason = activeSeasons.find(
      (season) => season.key === booking.seasonKey
    );
    if (selectedSeason.orderFormVerified) {
      const orderFormURL = selectedSeason.orderForm;

      const articleDataObject = {};
      booking.articles.forEach((article) => {
        articleDataObject[article.id] = article;
      });
      const orderFormAsExcelBuffer = await generateOrderForm(
        orderFormURL,
        articleDataObject,
        orderForSubmission
      );
      setLoadingProgress(40);
      const excelBlob = new Blob([orderFormAsExcelBuffer], {
        type: "application/octet-stream"
      });
      base64Excel = await blobToBase64(excelBlob);
      base64Excel = base64Excel.substr(base64Excel.indexOf(",") + 1);
    }

    //Settting up fake progress to await for the cloud function to finish
    interval = setInterval(() => {
      // console.log(loading_progress);
      setLoadingProgress((loadingProgress) => loadingProgress + 1);
      loading_progress += 1;
      if (loading_progress >= 100) {
        clearInterval(interval);
      }
    }, 300);

    await submitOrder(
      orderForSubmission.id,
      name,
      email,
      base64SummaryPDF,
      base64Excel
    );
    clearInterval(interval);
    setLoadingProgress(100);
    completeOrder(orderForSubmission.id);
    setOrderForSubmission();
    //setting a pause so that the progress bar can visually end at 100%.
    await new Promise((r) => setTimeout(r, 600));
    setIsLoading(false);
    setLoadingProgress();
  };

  return (
    <BookingPublicLayout title={booking?.name} subTitle={t("order_entry")}>
      <Loading
        isLoading={isLoading}
        withProgress={loadingProgress != null}
        progress={loadingProgress}
      />
      <Container>
        <Header>
          <StyledButton
            primary
            fontsize='small'
            onClick={() => setShowCreateOrderModal(true)}
          >
            {`+ ${t("new_order")}`}
          </StyledButton>
        </Header>
        <StyledScrollArea>
          {filteredOrders.map((filteredOrder) => (
            <OrderCard
              key={filteredOrder.id}
              deviceID={deviceID}
              order={filteredOrder}
              booking={booking}
              onSelectEditName={setEditNameOrder}
              onSelectViewCart={onSelectViewCart}
              onSelectViewSummary={setOrderForSummary}
              onSelectSubmitOrder={onSelectSubmitOrder}
              onSelectEditOrder={onEditOrder}
              onSelectDuplicate={onDuplicateOrder}
              onSelectDownloadOrder={onDownloadOrder}
            />
          ))}
        </StyledScrollArea>
      </Container>
      <CreateEditOrderNameModal
        title={showCreateOrderModal ? "New Order Name" : "Change Order Name"}
        show={editNameOrder != null || showCreateOrderModal}
        onClose={() => {
          setEditNameOrder();
          setShowCreateOrderModal(false);
        }}
        orderName={orders?.[editNameOrder?.id]?.name}
        onSaveNewName={onSaveNewName}
      />
      <OrderTabCartModal
        title={t("cart_title")}
        show={orderForCart != null}
        onClose={onCloseCart}
        booking={booking}
        order={orderForCart}
        updateDBOrderQtys={updateDBOrderQtys}
        showRemoveArticleButton={false}
      />
      <OrderSummaryModal
        title={t("summary")}
        show={orderForSummary != null}
        onClose={() => setOrderForSummary()}
        booking={booking}
        order={orderForSummary}
      />
      {/* This is for duplicating order */}
      <CreateEditOrderNameModal
        title={t("duplicate_order")}
        show={orderForDuplication != null}
        onClose={() => setOrderForDuplication()}
        onSaveNewName={onConfirmDuplicateOrder}
      />
      <ConfirmOrderSubmissionModal
        title={t("submit_order")}
        show={orderForSubmission != null}
        onClose={() => setOrderForSubmission()}
        onSubmitOrder={onConfirmSubmitOrder}
      />
    </BookingPublicLayout>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Header = styled.div`
  display: flex;
  padding: 5px;
  border-radius: ${CONSTANTS.borderRadius} ${CONSTANTS.borderRadius} 0 0;
  border-bottom: 2px solid ${COLORS.secondary};
  background: ${COLORS.tertiary};
  box-shadow: 0px 2px 5px ${COLORS.secondary};
  z-index: 1;
`;

const StyledButton = styled(Button)`
  /* margin-left: 10px; */
  text-transform: uppercase;
`;

const StyledScrollArea = styled(ScrollArea)`
  grid-template-columns: repeat(auto-fit, minmax(350px, 0.3333fr));
`;

export default OrderingDashboard;
