import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";
import { t } from "i18next";

const ContactPicker = ({
  className,
  contactOptions,
  linkedContactIDs,
  onSelectedContactID,
  onCreatedContact
}) => {
  //HOOKS
  const { t, i18n } = useTranslation();

  const selectRef = useRef();

  //STATE
  const [parsedContactOptions, setParsedContactOptions] = useState([]);

  useEffect(() => {
    if (contactOptions == null) return;

    let contacts = Object.entries(contactOptions).map(
      ([contactID, contact]) => ({
        ...contact,
        id: contactID
      })
    );
    contacts.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    setParsedContactOptions(
      contacts?.map((contact) => ({
        label: contact.name,
        value: {
          id: contact.id,
          name: contact.name,
          email: contact.email,
          courseName: contact.courseName
        }
      }))
    );
  }, [contactOptions]);

  const selectMenuCustomStyles = {
    control: (provided, { isFocused }) => ({
      ...provided,
      borderRadius: CONSTANTS.borderRadius,
      border: `1px solid ${COLORS.secondary}`,
      boxShadow: isFocused ? null : null,
      "&:hover": {
        borderColor: isFocused ? COLORS.text : COLORS.text
      }
    }),
    menu: (provided) => ({
      ...provided,
      margin: "3px 1px",
      width: "99.5%"
    }),
    option: (provided, { isSelected, isHighlighted, isDisabled }) => ({
      ...provided,
      backgroundColor: "unset",
      color: isDisabled ? COLORS.secondary : COLORS.text,
      cursor: isDisabled ? "not-allowed" : "pointer",
      "&:hover": {
        backgroundColor: isDisabled ? "unset" : COLORS.tertiary,
        color: isDisabled ? COLORS.secondary : COLORS.text
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: COLORS.text,
      fontSize: FONTSIZE.medium,
      padding: "8px 2px",
      margin: 0
    }),
    placeholder: (provided, state) => ({
      ...provided,
      // textTransform: "uppercase",
      fontSize: FONTSIZE.medium,
      color: COLORS.secondary,
      padding: "8px 0px",
      margin: 0
    }),
    input: (provided, state) => ({
      ...provided,
      color: COLORS.text,
      fontSize: FONTSIZE.medium,
      padding: "8px 2px",
      margin: 0
    })
  };

  const formatOptionLabel = (data) => (
    <OptionLabel>
      <OptionName>{data.value.name || data.label}</OptionName>
      {/* <OptionCourseName>{data.value.courseName}</OptionCourseName> */}
      <OptionEmail>{data.value.email}</OptionEmail>
    </OptionLabel>
  );

  const onContactSelection = (data) => {
    onSelectedContactID(data.value.id);
  };

  const onCreateNewContact = (name) => {
    onCreatedContact(name);
  };

  return (
    <LabeledContactSelect className={className}>
      <SelectLabel>{t("contacts_tab")}</SelectLabel>
      <CreatableSelect
        ref={selectRef}
        // closeMenuOnSelect={false}
        placeholder={t("search_create_contacts")}
        isSearchable={true}
        styles={selectMenuCustomStyles}
        options={parsedContactOptions}
        onChange={onContactSelection}
        value={null}
        formatOptionLabel={formatOptionLabel}
        formatCreateLabel={(inputValue) =>
          `${t("create_button")}: ${inputValue}`
        }
        onCreateOption={onCreateNewContact}
        isOptionDisabled={(option) =>
          linkedContactIDs?.includes(option.value.id)
        }
      />
    </LabeledContactSelect>
  );
};

export default ContactPicker;

const LabeledContactSelect = styled.div`
  margin: 20px 0;
`;

const SelectLabel = styled.div`
  /* text-transform: uppercase; */
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding-left: 5px;
  text-transform: uppercase;
`;

const OptionLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionName = styled.div`
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
`;

const OptionCourseName = styled.div`
  padding-left: 5px;
  font-size: ${FONTSIZE.medium};
  text-transform: uppercase;
`;

const OptionEmail = styled.div`
  padding-left: 5px;
  font-size: ${FONTSIZE.xsmall};
`;
