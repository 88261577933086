import React, { useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";
import useArticleSearchReader from "database/articles/useArticleSearchReader";
import BookingPublicLayout from "../bookingPublic/BookingPublicLayout";
import NestedNav from "components/shared/nestedNavigation/NestedNav";
import { useArticleReader } from "database/articles/useArticleReader";
import { useAuth } from "database/useAuth";

import ArticleCard from "components/shared/ArticleCard";
import useArticleZoomModal from "components/shared/articleZoomModal/useArticleZoomModal";
import ArticleZoomModal from "components/shared/articleZoomModal/ArticleZoomModal";

const PublicPage = () => {
  //HOOKS
  const { t, i18n } = useTranslation();
  const { user, login } = useAuth();
  const { activeSeasons, schemas } = useArticleReader();
  const { searchByCollectionKey } = useArticleSearchReader();
  const {
    toggleArticleZoomModal,
    isZoomModalShowing,
    onZoomArticle,
    zoomArticle,
    zoomArticleImages
  } = useArticleZoomModal();

  //STATE
  const [reversedActiveSeasons, setReversedActiveSeasons] = useState(
    [...activeSeasons].reverse()
  );
  const [selectedSeason, setSelectedSeason] = useState();
  const [articles, setArticles] = useState();
  const [initialSelection, setInitialSelection] = useState();
  const [navLocation, setNavLocation] = useState([]);

  const articleScrollContainer = useRef();

  const userMadeSelection = useCallback(
    async (item) => {
      const result = await searchByCollectionKey(
        selectedSeason,
        item?.key,
        true
      );
      setArticles(result);
    },
    [searchByCollectionKey, selectedSeason]
  );

  useEffect(() => {
    login("scotty.r.harris@gmail.com", "bullshit");
  }, [login]);

  useEffect(() => {
    if (activeSeasons == null) return;
    setReversedActiveSeasons([...activeSeasons].reverse());
  }, [activeSeasons]);

  useEffect(() => {
    if (activeSeasons == null || user == null) return;
    setSelectedSeason(activeSeasons?.[activeSeasons.length - 1]?.key);
  }, [activeSeasons, user]);

  //Get the initial selection
  //Iterate through the nesting and pick the first item in each level
  useEffect(() => {
    if (schemas == null || selectedSeason == null || user == null) return;
    let isBottom = false;
    let selection = [];
    let currentDepth = schemas[selectedSeason]?.schemas[0];
    while (!isBottom) {
      if (currentDepth?.groups != null) {
        selection.push(0);
        currentDepth = currentDepth.groups[0];
      } else {
        selection.push(0);
        isBottom = true;
        userMadeSelection(currentDepth);
      }
    }
    setInitialSelection(selection);
  }, [userMadeSelection, selectedSeason, schemas, user]);

  //Reset article scroll container to top on new sidebar selection
  useEffect(() => {
    if (articles != null) {
      articleScrollContainer.current.scrollTop = 0;
    }
  }, [articles]);

  const onUpdateCurrentNavigationLocation = useCallback(
    (location) => {
      if (schemas == null || selectedSeason == null) return;

      const schema = schemas?.[selectedSeason]?.schemas;

      let composedBreadCrumbs = [];
      let currentDepthData;
      location?.forEach((selection, depth) => {
        if (depth === 0) {
          currentDepthData = schema?.[selection];
        } else {
          if (depth <= currentDepthData?.groups?.length) {
            currentDepthData = currentDepthData?.groups[selection];
          }
        }
        composedBreadCrumbs.push(
          i18n.language === "en"
            ? currentDepthData?.name
            : currentDepthData?.name_fr
        );
        if (depth !== location.length - 1) composedBreadCrumbs.push(" ‣ ");
      });

      setNavLocation(composedBreadCrumbs);
    },
    [i18n.language, schemas, selectedSeason]
  );

  return (
    <BookingPublicLayout
      title={
        activeSeasons.find((season) => season.key === selectedSeason)?.name
      }
    >
      <Wrapper>
        <LeftSideBar>
          <SeasonPicker>
            <ul>
              {reversedActiveSeasons.map((season, index) => (
                <SeasonListItem
                  key={season.key}
                  isSelected={season.key === selectedSeason}
                  onClick={() =>
                    setSelectedSeason(reversedActiveSeasons[index].key)
                  }
                >
                  {i18n.language === "en" ? season.name : season.name_fr}
                </SeasonListItem>
              ))}
            </ul>
          </SeasonPicker>
          <NestedNav
            shouldAllowSelection={false}
            userSelectedItem={userMadeSelection}
            navListMap={schemas?.[selectedSeason]?.schemas}
            initialSelection={initialSelection}
            backgroundColor={"white"}
            onUpdateCurrentNavigationLocation={
              onUpdateCurrentNavigationLocation
            }
          />
        </LeftSideBar>
        {/* )} */}
        <CollectionSectionBody ref={articleScrollContainer}>
          <SectionHeader>
            <HeaderBreadCrumbs>
              {navLocation.map((text, index) => (
                <span key={`crubms-${index}`}>{text}</span>
              ))}
            </HeaderBreadCrumbs>
          </SectionHeader>
          <ScrollBody>
            {articles?.map((article) => (
              <ArticleCard
                key={article.id}
                seasonKey={selectedSeason}
                article={article}
                onZoomArticle={onZoomArticle}
              />
            ))}
          </ScrollBody>
        </CollectionSectionBody>
        <ArticleZoomModal
          show={isZoomModalShowing}
          onClose={toggleArticleZoomModal}
          imageList={zoomArticleImages}
          article={zoomArticle}
        />
      </Wrapper>
    </BookingPublicLayout>
  );
};

export default PublicPage;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr;
  /* border: 2px solid ${COLORS.secondary}; */
  border-radius: ${CONSTANTS.borderRadius};
  position: relative;
`;

const LeftSideBar = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  width: ${({ shouldHide }) => (shouldHide ? "0px" : "100%")};
  grid-template-rows: auto 1fr;
  border-right: 1px solid ${COLORS.secondary};
  transition: width 0.3s ease-in-out;
`;

const SeasonPicker = styled.div`
  background: ${COLORS.tertiary};
  border-bottom: 1px solid ${COLORS.secondary};

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;

const SeasonListItem = styled.li`
  margin: 5px;
  padding: 10px;
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.medium};
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ isSelected }) =>
    isSelected ? COLORS.primary : COLORS.secondary};
  background: ${({ isSelected }) => isSelected && COLORS.secondary};
  border-radius: 4px;
`;

const CollectionSectionBody = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: min-content 1fr;
  background: ${COLORS.tertiary};
`;

const ScrollBody = styled.div`
  padding: 3px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 0.5fr));
  /* grid-template-rows: min-content 1fr; */
  grid-gap: 3px;
  align-content: start;
  /* background: ${COLORS.tertiary}; */
  /* position: relative; */
`;

const SectionHeader = styled.div`
  grid-column: 1/-1;
  z-index: 15;
  width: 100%;
  padding: 12px 5px;
  background-color: ${COLORS.secondary};
  border-top-right-radius: 4px;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 5px lightgrey;
`;

const HeaderBreadCrumbs = styled.div`
  color: ${COLORS.text};
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  padding-left: 10px;
  display: flex;
  align-items: center;

  span {
    padding: 3px;
    :last-child {
      color: ${COLORS.primary};
      font-size: ${FONTSIZE.medium};
    }
  }
`;
