import { netPrice } from "./helperFunctions";

export const calculateOrderSummaryAndOrderQtys = (
  orderValues,
  overallCategories,
  articles,
  priceToShow
) => {
  const categoryTotals = {
    totalApparel: { qty: 0, msrpDollars: 0, netDollars: 0 },
    totalFootwear: { qty: 0, msrpDollars: 0, netDollars: 0 },
    totalDollars: { msrpDollars: 0, netDollars: 0 }
  };
  Object.keys(overallCategories).forEach((categoryKey) => {
    categoryTotals[categoryKey] = { qty: 0, msrpDollars: 0, netDollars: 0 };
  });
  let orderQtys = {};

  Object.entries(orderValues).forEach(([articleID, order]) => {
    const article = articles.find((article) => article.id === articleID);
    order.orderQtys.forEach((qty, index) => {
      if (qty != null) {
        //Set the quantities
        if (orderQtys[articleID] == null) orderQtys[articleID] = {};
        orderQtys[articleID][article.userFriendlySizes[index]] = qty;

        //Track overall totals
        categoryTotals[article.overallCategoryKey].qty += qty;

        categoryTotals[article.overallCategoryKey].msrpDollars +=
          qty * article.retailPrice;
        if (priceToShow === "WHSL" || priceToShow === "MSRP+WHSL")
          categoryTotals[article.overallCategoryKey].netDollars +=
            qty * netPrice(article.retailPrice, article.priceCode);

        //Track total dollars
        categoryTotals.totalDollars.msrpDollars += qty * article.retailPrice;
        if (priceToShow === "WHSL" || priceToShow === "MSRP+WHSL")
          categoryTotals.totalDollars.netDollars +=
            qty * netPrice(article.retailPrice, article.priceCode);

        //Calculate totals for apparel
        if (
          !["accessories", "mensFootwear", "womensFootwear"].some(
            (element) => element === article.overallCategoryKey
          )
        ) {
          categoryTotals.totalApparel.qty += qty;
          categoryTotals.totalApparel.msrpDollars += qty * article.retailPrice;
          if (priceToShow === "WHSL" || priceToShow === "MSRP+WHSL")
            categoryTotals.totalApparel.netDollars +=
              qty * netPrice(article.retailPrice, article.priceCode);
        }

        //Calculate totals for footwear
        if (
          ["mensFootwear", "womensFootwear"].some(
            (element) => element === article.overallCategoryKey
          )
        ) {
          categoryTotals.totalFootwear.qty += qty;
          categoryTotals.totalFootwear.msrpDollars += qty * article.retailPrice;
          if (priceToShow === "WHSL" || priceToShow === "MSRP+WHSL")
            categoryTotals.totalFootwear.netDollars +=
              qty * netPrice(article.retailPrice, article.priceCode);
        }
      }
    });
  });

  return { categoryTotals, orderQtys };
};
