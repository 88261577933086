import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";
import React from "react";
import styled from "styled-components/macro";
import { MiniButton } from "components/shared/standardElements";

const DashboardCard = ({
  title,
  className,
  children,
  shouldScroll,
  shouldShowEditButton,
  editButtonTitle,
  onClickedEditButton
}) => {
  return (
    <OuterContainer className={className}>
      <Header>
        <Title>{title}</Title>
        {shouldShowEditButton && (
          <MiniButton onClick={onClickedEditButton}>
            {editButtonTitle}
          </MiniButton>
        )}
      </Header>
      <Content shouldScroll={shouldScroll}>{children}</Content>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  background-color: ${COLORS.primaryInverted};
  color: ${COLORS.text};
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  padding: 8px;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.secondary};
  padding-bottom: 2px;
`;

const Title = styled.div`
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
  padding: 2px 0;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: ${(props) => (props.shouldScroll ? "auto" : "inherit")};
`;

export default DashboardCard;
