import { useAuth } from "database/useAuth";
import { Navigate, Outlet } from "react-router-dom";

const AdidasRepRoutes = () => {
  const { user, authContextLoaded } = useAuth();
  return user?.type === "adidasRep" ? (
    <Outlet />
  ) : (
    authContextLoaded && <Navigate to='/' replace />
  );
};

export default AdidasRepRoutes;
