import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Summary from "./Summary";
import { useArticleReader } from "database/articles/useArticleReader";
import useArticleZoomModal from "components/shared/articleZoomModal/useArticleZoomModal";
import ArticleZoomModal from "components/shared/articleZoomModal/ArticleZoomModal";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";
import CartArticleCard from "./CartArticleCard";
import { cloneDeep } from "lodash";

import { GroupedVirtuoso } from "react-virtuoso";

const OrderEntry = ({
  booking,
  selectedArticles,
  formControl,
  getFormValues,
  setFormValue,
  overallCategorySummary,
  showRemoveArticleButton,
  didSelecteRemoveArticle
}) => {
  const { t, i18n } = useTranslation();
  const { schemas } = useArticleReader();

  const {
    toggleArticleZoomModal,
    isZoomModalShowing,
    onZoomArticle,
    zoomArticle,
    zoomArticleImages
  } = useArticleZoomModal();

  const [sections, setSections] = useState([]);
  const [articles, setArticles] = useState([]);
  const [articleGroupTracking, setArticleGroupTracking] = useState({});

  //Parse the selected articles and create sections to display
  useEffect(() => {
    if (schemas == null || booking == null || selectedArticles == null) return;

    let constructedSections = [];
    let constructedArticles = [];
    let constructedArticleGroupTracking = {};

    //Filter out existing collection keys to reduce down the sections
    let existingKeys = [];
    if (booking?.sortOrder === "collection") {
      existingKeys = selectedArticles
        ?.map((article) =>
          article[`${booking.seasonKey}_collectionKeys`].map((key) => key)
        )
        ?.reduce((flatten, arr) => [...flatten, ...arr], []);
    } else if (booking?.sortOrder === "category") {
      existingKeys = selectedArticles?.map(
        (article) => article[`${booking.seasonKey}_generalCategoryKey`]
      );
    }

    //currentLocation is used in the loop below to track what the section titles should be
    let currentLocation = [];

    //Recursion function to be used below to determine what Nav Menu items to show
    const processSchemaItem = (item) => {
      item.groups = item.groups.filter((groupItem) =>
        existingKeys.some((key) => key.includes(groupItem.key))
      );
      item.groups.forEach((groupItem, index) => {
        if (groupItem.groups != null) {
          currentLocation.push(
            i18n.language === "en" ? groupItem.name : groupItem.name_fr
          );
          currentLocation.push(" ‣ ");
          processSchemaItem(groupItem, index);
        } else {
          currentLocation.push(groupItem.name);
          let section = {};
          section.location = [...currentLocation];
          section.locationKey = groupItem.key;

          if (booking?.sortOrder === "collection") {
            section.articles = [
              ...selectedArticles.filter((article) =>
                article[`${booking.seasonKey}_collectionKeys`].includes(
                  groupItem.key
                )
              )
            ];
            section.articles.sort((a, b) =>
              a[`${booking.seasonKey}_${groupItem.key}_sort`] >
              b[`${booking.seasonKey}_${groupItem.key}_sort`]
                ? 1
                : a[`${booking.seasonKey}_${groupItem.key}_sort`] <
                  b[`${booking.seasonKey}_${groupItem.key}_sort`]
                ? -1
                : 0
            );
          } else if (booking?.sortOrder === "category") {
            section.articles = [
              ...selectedArticles.filter((article) =>
                article[`${booking.seasonKey}_generalCategoryKey`].includes(
                  groupItem.key
                )
              )
            ];
            section.articles.sort((a, b) =>
              a.retailPrice < b.retailPrice
                ? 1
                : a.retailPrice > b.retailPrice
                ? -1
                : a.articleName.toLowerCase() > b.articleName.toLowerCase()
                ? 1
                : a.articleName.toLowerCase() < b.articleName.toLowerCase()
                ? -1
                : 0
            );
          }
          currentLocation.pop();
          constructedSections.push(section);

          section.articles.forEach((article) => {
            if (
              constructedArticleGroupTracking[article.articleNumber] == null
            ) {
              constructedArticleGroupTracking[article.articleNumber] = [];
            }
            constructedArticleGroupTracking[article.articleNumber].push({
              groupIndex: constructedSections.length - 1,
              sectionLocation: section.location
            });
            constructedArticles.push(article);
          });
        }
      });
      currentLocation.pop();
      currentLocation.pop();
    };

    let filteredSchema;

    if (booking?.sortOrder === "collection") {
      filteredSchema = cloneDeep(schemas[booking.seasonKey].schemas).filter(
        (schema) => existingKeys.some((key) => key.includes(schema.key))
      );
    } else if (booking?.sortOrder === "category") {
      filteredSchema = cloneDeep(
        schemas[booking.seasonKey].categorySchemas
      ).filter((schema) =>
        existingKeys.some((key) => key.includes(schema.key))
      );
    }

    filteredSchema.forEach((schema) => {
      currentLocation.push(schema.name);
      currentLocation.push(" ‣ ");
      processSchemaItem(schema);
    });

    setSections(constructedSections);
    setArticles(constructedArticles);
    setArticleGroupTracking(constructedArticleGroupTracking);
  }, [booking, i18n.language, schemas, selectedArticles]);

  return (
    <Wrapper>
      <OrderEntryWrapper>
        <CartScrollArea>
          <GroupedVirtuoso
            style={{ height: "100%" }}
            overscan={5}
            groupCounts={sections.map((section) => section.articles.length)}
            groupContent={(index) => {
              const header = sections[index];
              return (
                <SectionHeader index={index}>
                  <HeaderBreadCrumbs>
                    {header.location.map((text, index) => (
                      <span key={`crubms-${header.locationKey}-${index}`}>
                        {text}
                      </span>
                    ))}
                  </HeaderBreadCrumbs>
                </SectionHeader>
              );
            }}
            itemContent={(index, groupIndex) => {
              const article = articles[index];
              return (
                <CartCardWrapper>
                  {articleGroupTracking[article.articleNumber].length > 1 ? (
                    <ArticleDuplicateMessage>
                      {t("duplicate_cart_article_1")}
                      <span>
                        {articleGroupTracking[article.articleNumber]
                          .filter((item) => item.groupIndex !== groupIndex)
                          .map((sectionInfo, index) =>
                            index === 0
                              ? ` ${
                                  sectionInfo.sectionLocation[
                                    sectionInfo.sectionLocation.length - 1
                                  ]
                                }`
                              : ` & ${
                                  sectionInfo.sectionLocation[
                                    sectionInfo.sectionLocation - 1
                                  ]
                                }`
                          )}
                      </span>
                      {`. ${t("duplicate_cart_article_2")}`}
                    </ArticleDuplicateMessage>
                  ) : (
                    ""
                  )}

                  <CartArticleCard
                    seasonKey={booking.seasonKey}
                    articleDetails={article}
                    onZoomArticle={onZoomArticle}
                    formControl={formControl}
                    getFormValues={getFormValues}
                    setFormValue={setFormValue}
                    priceToShow={booking?.priceToShowOrdering}
                    showRemoveButton={showRemoveArticleButton}
                    didSelectRemove={didSelecteRemoveArticle}
                  />
                </CartCardWrapper>
              );
            }}
          />
        </CartScrollArea>
      </OrderEntryWrapper>
      {overallCategorySummary != null &&
        Object.keys(overallCategorySummary).length > 0 && (
          <Summary
            totals={overallCategorySummary}
            displayPrice={booking.priceToShowOrdering}
          />
        )}
      <ArticleZoomModal
        show={isZoomModalShowing}
        onClose={toggleArticleZoomModal}
        imageList={zoomArticleImages}
        article={zoomArticle}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  padding-top: 12px;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr auto;
  gap: 0 10px;
`;

const OrderEntryWrapper = styled.div`
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  overflow: hidden;
`;

const CartScrollArea = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${COLORS.tertiary};
  border-radius: 6px;
`;

const SectionHeader = styled.div`
  z-index: 15;
  width: 100%;
  padding: 10px 5px;
  /* margin-top: ${({ index }) => (index > 0 ? "50px" : "unset")}; */
  background-color: ${COLORS.secondary};
  /* border-bottom: 3px solid ${COLORS.secondary}; */
  /* position: sticky; */
  /* top: 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 5px lightgrey;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const HeaderBreadCrumbs = styled.div`
  color: ${COLORS.text};
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  padding-left: 10px;
  display: flex;
  align-items: center;

  span {
    padding: 3px;
    :last-child {
      color: ${COLORS.primary};
      font-size: ${FONTSIZE.medium};
    }
  }
`;

const CartCardWrapper = styled.div`
  min-height: 100px;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  padding: 5px;
  margin: 5px 5px 5px 5px;
  background: ${COLORS.primaryInverted};
`;

const ArticleDuplicateMessage = styled.div`
  width: 100%;
  padding: 4px 8px;
  font-size: ${FONTSIZE.xsmall};
  color: ${COLORS.green};
  border-radius: ${CONSTANTS.borderRadius};
  border: 1px solid ${COLORS.green};
  background: honeydew;
  text-align: center;

  span {
    text-transform: uppercase;
    font-weight: ${FONTWEIGHT.heavy};
  }
`;

export default OrderEntry;
