import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect
} from "react";
import { useAuth } from "./useAuth";
import useArticleSearchReader from "./articles/useArticleSearchReader";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  where,
  query
} from "firebase/firestore";

const CatalogueDatabaseContext = createContext();

const CatalogueDatabaseContextProvider = ({ children }) => {
  const { user, login, authContextLoaded } = useAuth();

  const { retrieveArticlesByIDs } = useArticleSearchReader();

  const [currentlySelectedArticles, setCurrentlySelectedArticles] = useState();
  const [catalogueID, setCatalogueID] = useState();
  const [catalogue, setCatalogue] = useState();
  const [pageError, setPageError] = useState(false);

  //Monitor Catalogue Doc
  useEffect(() => {
    if (!authContextLoaded || user == null || catalogueID == null) return;
    const unsubscribe = onSnapshot(
      doc(getFirestore(), "catalogues", catalogueID),
      (doc) => {
        let catalogue = doc.data();
        catalogue.id = doc.id;

        //Parse Firestore Timestamps on creationDate to Javascript dates
        catalogue.creationDate = catalogue.creationDate.toDate();

        setCatalogue(catalogue);
      },
      (error) => {
        console.log(error);
        setPageError(true);
      }
    );

    return () => unsubscribe();
  }, [authContextLoaded, catalogueID, user]);

  // Hyrate Articles for Selected Booking
  //This runs everytime the booking info changes
  useEffect(() => {
    if (
      catalogue?.id == null ||
      catalogue?.articleIDs == null ||
      catalogueID == null
    )
      return;
    if (catalogue.id === catalogueID) {
      if (catalogue.articleIDs.length === 0) {
        setCurrentlySelectedArticles([]);
        setCatalogue((prev) => ({ ...prev, articles: [] }));
      } else {
        retrieveArticlesByIDs(catalogue.articleIDs).then((bookingArticles) => {
          setCurrentlySelectedArticles(Object.values(bookingArticles));
          setCatalogue((prev) => ({
            ...prev,
            articles: Object.values(bookingArticles)
          }));
        });
      }
    }
  }, [
    catalogue?.articleIDs,
    catalogue?.id,
    catalogueID,
    retrieveArticlesByIDs
  ]);

  //This sets the articles on the booking after it has been updated in firebase
  //It comes back raw with no article data attached other than the article ids
  useEffect(() => {
    if (catalogue?.articles == null && currentlySelectedArticles != null) {
      setCatalogue((prev) => ({
        ...prev,
        articles: currentlySelectedArticles
      }));
    }
  }, [currentlySelectedArticles, catalogue?.articles]);

  const loginViewer = useCallback(
    async (catalogueID) => {
      setCatalogueID(catalogueID);
      try {
        if (
          user == null ||
          (authContextLoaded &&
            !user.possibleTypes.some((type) =>
              ["god", "adidasRep", "adidasManager"].includes(type)
            ))
        ) {
          await login(`viewer@${catalogueID}.com`, catalogueID);
        } else {
          console.log("Current user can view");
        }
        return;
      } catch (error) {
        setPageError(true);
        console.log(error);
      }
    },
    [authContextLoaded, user, login]
  );

  return (
    <CatalogueDatabaseContext.Provider
      value={{
        loginViewer,
        catalogue,
        pageError
      }}
    >
      {children}
    </CatalogueDatabaseContext.Provider>
  );
};

export default CatalogueDatabaseContextProvider;

export const useCatalogueDatabase = () => useContext(CatalogueDatabaseContext);
