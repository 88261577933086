import { getFirestore, doc, collection } from "firebase/firestore";

export const firebaseRandomID = () => {
  return doc(collection(getFirestore(), "randomIDGenerator")).id;
};

export const convertObjectToArray = (obj) => {
  let array = [];
  Object.entries(obj).forEach(([key, value]) => {
    array.push({ id: key, ...value });
  });
  return array;
};

export const diffDays = (date, otherDate) =>
  Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

export const formatPrice = (cents) => {
  return (cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
};

export const netPrice = (retailPrice, priceCode) => {
  if (priceCode === "a") {
    return (retailPrice * 0.5).toFixed(2);
  } else if (priceCode === "c") {
    return (retailPrice * 0.6).toFixed(2);
  } else {
    return "Price Error";
  }
};

export const seasonNameConversion = (databaseSeasonName) => {
  if (databaseSeasonName.startsWith("Spring")) {
    const newName = databaseSeasonName.replace("Spring", "Spring ");
    return newName;
  } else if (databaseSeasonName.startsWith("Summer")) {
    const newName = databaseSeasonName.replace("Summer", "Summer ");
    return newName;
  } else if (databaseSeasonName.startsWith("SS")) {
    const newName = databaseSeasonName.replace("SS", "Spring ");
    return newName;
  } else if (databaseSeasonName.startsWith("FW")) {
    const newName = databaseSeasonName.replace("FW", "Summer ");
    return newName;
  } else {
    return databaseSeasonName;
  }
};

export const highResImageURLFor = (articleNumber, allArticles) => {
  if (allArticles.length > 0) {
    let filteredArticles = allArticles.filter((article) => {
      return article.articleNumber === articleNumber;
    });
    if (filteredArticles.length > 0) {
      let url = filteredArticles[0]["imageURL"].replace(
        "WebImages",
        "HighResImages"
      );
      url = url.replace("_web", "");
      return url.replace("_web", "");
    }
  }
};

export const testAPIdelay = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));
