import React from "react";
import { useTranslation } from "react-i18next";
import { formatColumn } from "accounting";
import {
  Page,
  Text,
  View,
  Image,
  Svg,
  Path,
  Document,
  StyleSheet,
  Font
} from "@react-pdf/renderer";
import { calculateOrderSummaryAndOrderQtys } from "helpers/calculateOrderSummaryAndOrderQtys";
import NumberFormat from "react-number-format";

const OrderSummaryPDF = ({
  bookingArticles,
  order,
  seasonKey,
  overallCategories,
  courseName,
  priceToShow
}) => {
  const { t, i18n } = useTranslation();

  let defaultValues = {};

  bookingArticles.forEach((article) => {
    //FIXME This is a bit of a hack to avoid issues where the article was dropped from the season
    //This if wrapper is here for this hack
    if (article.seasons.includes(seasonKey)) {
      article.userFriendlySizes.forEach((size, index) => {
        if (defaultValues[article.id] == null)
          defaultValues[article.id] = { orderQtys: [] };

        defaultValues[article.id].orderQtys[index] =
          order.qtys[article.id]?.[size] || null;
      });
    }
  });

  const { categoryTotals } = calculateOrderSummaryAndOrderQtys(
    defaultValues,
    overallCategories,
    //FIXME This is a bit of a hack to avoid issues where the article was dropped from the season
    //Standard is to just pass the booking articles
    bookingArticles.filter((article) => article.seasons.includes(seasonKey)),
    priceToShow
  );

  const sortedArticles = Object.values(bookingArticles).sort((a, b) => {
    if (
      overallCategories[a.overallCategoryKey].sortOrder >
      overallCategories[b.overallCategoryKey].sortOrder
    ) {
      return 1;
    } else if (
      overallCategories[a.overallCategoryKey].sortOrder <
      overallCategories[b.overallCategoryKey].sortOrder
    ) {
      return -1;
    }
    return 0;
  });

  const sortedAndFilteredArticles = sortedArticles.filter(
    //FIXME This is a bit of a hack to avoid issues where the article was dropped from the season
    //The check for the seasonKey is the hack
    (article) =>
      order.qtys[article.id] != null && article.seasons.includes(seasonKey)
  );

  return (
    <Document>
      <Page size='LETTER' style={styles.body}>
        <View style={styles.documentHeader}>
          <View style={styles.logo}>
            <Svg viewBox='0 0 100 68' style={{ height: 27, width: 40 }}>
              <Path
                d='m49.735 58.35c0-2.684 2.154-4.838 4.838-4.838s4.837 2.154 4.837 4.838-2.153 4.838-4.837 4.838-4.838-2.154-4.838-4.838m19.682 0c0-2.684 2.154-4.838 4.838-4.838 2.683 0 4.837 2.154 4.837 4.838s-2.154 4.838-4.837 4.838c-2.684 0-4.838-2.154-4.838-4.838m-45.626 0c0-2.684 2.153-4.838 4.838-4.838 2.683 0 4.837 2.154 4.837 4.838s-2.154 4.838-4.837 4.838c-2.685 0-4.838-2.154-4.838-4.838m-19.682 0c0-2.684 2.153-4.838 4.837-4.838 2.685 0 4.838 2.154 4.838 4.838s-2.153 4.838-4.838 4.838c-2.684 0-4.837-2.154-4.837-4.838m39.794-8.549h-4.208v16.932h4.208zm-30.152 16.932h4.208v-16.932h-4.208v1.359c-1.425-.994-3.148-1.557-5.003-1.557-4.838 0-8.748 3.909-8.748 8.747 0 4.871 3.91 8.747 8.748 8.747 1.855 0 3.578-.563 5.003-1.557zm65.308 0h4.208v-16.932h-4.208v1.359c-1.425-.994-3.148-1.557-5.003-1.557-4.838 0-8.747 3.909-8.747 8.747 0 4.871 3.909 8.747 8.747 8.747 1.855 0 3.578-.563 5.003-1.557zm5.766-5.501c.132 3.413 3.048 5.832 7.919 5.832 3.976 0 7.256-1.723 7.256-5.533 0-2.651-1.491-4.208-4.937-4.871l-2.684-.53c-1.723-.331-2.916-.663-2.916-1.69 0-1.126 1.16-1.623 2.618-1.623 2.121 0 2.982 1.06 3.048 2.319h4.242c-.232-3.314-2.883-5.533-7.157-5.533-4.407 0-7.025 2.418-7.025 5.566 0 3.844 3.115 4.473 5.799 4.97l2.22.398c1.59.298 2.286.762 2.286 1.69 0 .828-.795 1.656-2.684 1.656-2.75 0-3.645-1.424-3.678-2.651zm-51.392-17.196v7.124c-1.425-.994-3.148-1.557-5.003-1.557-4.838 0-8.748 3.909-8.748 8.747 0 4.871 3.91 8.747 8.748 8.747 1.855 0 3.578-.563 5.003-1.557v1.193h4.208v-22.697zm25.944 0v7.124c-1.425-.994-3.148-1.557-5.003-1.557-4.838 0-8.747 3.909-8.747 8.747 0 4.871 3.909 8.747 8.747 8.747 1.855 0 3.578-.563 5.003-1.557v1.193h4.208v-22.697zm-48.443-5.003 2.85 5.003h19.649l-7.787-13.453zm32.969 9.211v-4.208h15.474l-16.501-28.562-14.745 8.449 11.564 20.113v4.208zm21.737-4.208h19.682l-25.448-44.036-14.711 8.482z'
                fill-rule='nonzero'
                fill='black'
              />
            </Svg>
          </View>
          <Text style={styles.title}>{t("order_summary_title")}</Text>
          <View style={styles.clubInfo}>
            <Text style={styles.clubName}>{courseName}</Text>
            <Text style={styles.eventName}>{order.name}</Text>
          </View>
        </View>
        {sortedAndFilteredArticles.map((article) => (
          <View key={article.id} wrap={false} style={styles.articleBox}>
            <View style={styles.articleImageWrapper}>
              <Image
                src={
                  article.imageURLs.filter(
                    (url) => url.includes("_MAIN_") && url.includes("_thumb")
                  )[0] || "/emptyImagePlaceholder.png"
                }
              />
            </View>
            <View style={styles.articleInfoWrapper}>
              <View style={styles.articleInfoHeader}>
                <Text style={styles.articleName}>{article.articleName}</Text>
                <Text style={styles.articlePrice}>
                  {(priceToShow === "MSRP" || priceToShow === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={article.retailPrice}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` MSRP`}</span>
                    </>
                  )}

                  {priceToShow === "MSRP+WHSL" && ` / `}
                  {(priceToShow === "WHSL" || priceToShow === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={
                          article.retailPrice *
                          (article.priceCode === "c"
                            ? 0.6
                            : article.priceCode === "a"
                            ? 0.5
                            : 1)
                        }
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                        displayType='text'
                      />
                      <span>{` WHSL`}</span>
                    </>
                  )}
                </Text>
              </View>
              <View style={styles.articleColorIntroWrapper}>
                <Text style={styles.articleColor}>
                  {i18n.language === "en" ? article.color : article.color_fr}
                </Text>
                <Text style={styles.articlePrice}>
                  {`INTRO: ${
                    i18n.language === "en"
                      ? article[`${seasonKey}_deliveryDate`]
                      : article[`${seasonKey}_deliveryDate_fr`]
                  }`}
                </Text>
              </View>
              <Text style={styles.articleNumber}>{article.articleNumber}</Text>
              <View style={styles.articleSizesWrapper}>
                {article.userFriendlySizes.map((size) => (
                  <View
                    key={`${article.id}-${size}`}
                    style={styles.articleSizeBox}
                  >
                    <Text style={styles.articleSize}>{size}</Text>
                    <View style={styles.sizeQtyBox}>
                      <Text style={styles.sizeQty}>
                        {order.qtys[article.id][size] || " "}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        ))}

        <View wrap={false} style={styles.summaryWrapper}>
          <View style={styles.summaryTitleWrapper}>
            <Text style={styles.summaryTitle}>OVERALL SUMMARY</Text>
          </View>
          <View style={styles.summaryInnerWrapper}>
            <View style={styles.summarySubSummary}>
              <Text style={styles.subSummaryTitle}>MENS</Text>
              <View style={styles.subSummaryCategories}>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>POLOS</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.mensPolos.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals.mensPolos.msrpDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals?.mensPolos.netDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>BOTTOMS</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.mensBottoms.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals.mensBottoms.msrpDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals?.mensBottoms.netDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>LYG/OWR</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.mensLayeringOuterwear.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={
                              categoryTotals.mensLayeringOuterwear.msrpDollars
                            }
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={
                              categoryTotals?.mensLayeringOuterwear.netDollars
                            }
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>FOOTWEAR</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.mensFootwear.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals.mensFootwear.msrpDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals?.mensFootwear.netDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.summarySubSummary}>
              <Text style={styles.subSummaryTitle}>WOMENS</Text>
              <View style={styles.subSummaryCategories}>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>POLOS</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.womensPolos.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals.womensPolos.msrpDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals?.womensPolos.netDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>BOTTOMS</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.womensBottoms.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals.womensBottoms.msrpDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals?.womensBottoms.netDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>LYG/OWR</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.womensLayeringOuterwear.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={
                              categoryTotals.womensLayeringOuterwear.msrpDollars
                            }
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={
                              categoryTotals?.womensLayeringOuterwear.netDollars
                            }
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>FOOTWEAR</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.womensFootwear.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals.womensFootwear.msrpDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals?.womensFootwear.netDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.summarySubSummary}>
              <Text style={styles.subSummaryTitle}>TOTALS</Text>
              <View style={styles.subSummaryCategories}>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>APPAREL</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.totalApparel.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals.totalApparel.msrpDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals?.totalApparel.netDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>ACCS</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.accessories.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals.accessories.msrpDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals?.accessories.netDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>FOOTWEAR</Text>
                    <Text
                      style={styles.categoryQty}
                    >{`${categoryTotals.totalFootwear.qty} pcs`}</Text>
                  </View>
                  <View style={styles.categoryBottomRow}>
                    <Text style={styles.categoryDollars}>
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals.totalFootwear.msrpDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}

                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals?.totalFootwear.netDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.subSummaryCategory}>
                  <View style={styles.categoryTopRow}>
                    <Text style={styles.categoryTitle}>TOTAL</Text>
                    <Text style={styles.categoryQty}>
                      {" "}
                      {(priceToShow === "MSRP" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals.totalDollars.msrpDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            displayType='text'
                          />
                          <span>{` MSRP`}</span>
                        </>
                      )}
                      {priceToShow === "MSRP+WHSL" && ` / `}
                      {(priceToShow === "WHSL" ||
                        priceToShow === "MSRP+WHSL") && (
                        <>
                          <NumberFormat
                            value={categoryTotals?.totalDollars.netDollars}
                            prefix='$'
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            displayType='text'
                          />
                          <span>{` WHSL`}</span>
                        </>
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default OrderSummaryPDF;

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/booking5test-b0a76.appspot.com/o/fonts%2FRobotoCondensed-Bold.ttf?alt=media&token=23b46442-2e32-4109-aea5-356b523c62b6",
      fontWeight: 700
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/booking5test-b0a76.appspot.com/o/fonts%2FRobotoCondensed-Regular.ttf?alt=media&token=183a52e9-cda9-4443-ae4a-f4bedddce5a9",
      fontWeight: 400
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/booking5test-b0a76.appspot.com/o/fonts%2FRobotoCondensed-Light.ttf?alt=media&token=557a8c37-a97f-4d02-9a70-7e9c47f59484",
      fontWeight: 300
    }
  ]
});

Font.register({
  family: "Roboto-Mono",
  fonts: [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/booking5test-b0a76.appspot.com/o/fonts%2FRobotoMono-Medium.ttf?alt=media&token=1c8ddafd-b0f6-41d1-ac72-091e550afd71",
      fontWeight: 500
    }
  ]
});

const styles = StyleSheet.create({
  body: {
    paddingTop: "30pt",
    paddingBottom: "40pt",
    paddingHorizontal: "28pt",
    fontFamily: "Roboto",
    fontSize: 12
  },
  documentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: "2px"
  },
  logo: {
    flexBasis: 0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start"
  },
  title: {
    fontWeight: 700
  },
  clubInfo: {
    height: "100%",
    fontSize: 10,
    flexBasis: 0,
    flexGrow: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end"
  },
  clubName: {
    fontWeight: 400,
    textTransform: "uppercase"
  },
  eventName: {
    fontWeight: 300,
    textTransform: "uppercase"
  },
  articleBox: {
    padding: "5px 0",
    borderTop: "0.5px solid lightgray",
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  articleImageWrapper: {
    width: "105pt",
    height: "122pt",
    objectFit: "contain",
    paddingRight: "5px"
  },
  articleInfoWrapper: {
    height: "100%",
    flexGrow: 1,
    display: "flex"
  },
  articleInfoHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderBottom: "0.5px solid black"
  },
  articleName: {
    fontWeight: 700
  },
  articlePrice: {
    fontSize: 9
  },
  articleColorIntroWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },
  articleColor: {
    fontSize: 10,
    fontWeight: 400
  },
  articleNumber: {
    fontSize: 10,
    fontWeight: 300
  },
  articleSizesWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 2
  },
  articleSizeBox: {
    width: 25,
    height: 35,
    marginRight: 2,
    marginBottom: 2,
    display: "flex",
    alignItems: "center",
    borderRadius: 5,
    border: "0.5px solid black"
  },
  articleSize: {
    textAlign: "center",
    width: "100%",
    padding: "2px 0",
    fontSize: 8,
    fontWeight: 700,
    borderBottom: "0.5px solid black"
  },
  sizeQtyBox: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center"
  },
  sizeQty: {
    textAlign: "center",
    padding: "2px 0",
    fontSize: 9,
    fontWeight: 400
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "black"
  },
  summaryWrapper: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    paddingTop: "30px"
  },
  summaryTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid black"
  },
  summaryTitle: {
    color: "black",
    fontSize: 14,
    fontWeight: 700,
    textAlign: "center",
    padding: "2px 10px"
  },
  summaryInnerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap"
  },
  summarySubSummary: {
    minWidth: "49%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "5px",
    border: "1px solid lightgrey",
    borderRadius: "8px",
    margin: "5px 0"
  },
  subSummaryTitle: {
    fontWeight: 700,
    fontFamily: "Roboto-Mono",
    fontSize: 13,
    borderBottom: "1px solid black"
  },
  subSummaryCategories: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  subSummaryCategory: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    padding: "5px 5px 5px 0",
    marginLeft: "10px",
    borderBottom: "1px solid lightgrey"
  },
  categoryTopRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  categoryBottomRow: {
    display: "flex",
    justifyContent: "flex-end"
  },
  categoryTitle: {
    fontFamily: "Roboto-Mono",
    fontWeight: 500
  },
  categoryQty: {
    fontFamily: "Roboto-Mono",
    fontWeight: 500,
    textAlign: "right"
  },
  categoryDollars: {
    fontFamily: "Roboto-Mono",
    fontWeight: 500,
    fontSize: 9
  }
});
