import { useCallback } from "react";
import { bookingSearchClient } from "services/typesense";
import { useDatabaseReader } from "database/useDatabaseReader";

const useBookingSearchReader = () => {
  const { bookingsSearchAPIKey } = useDatabaseReader();

  const searchForBooking = useCallback(
    async (searchTerm, filter) => {
      let searchParameters = {
        q: searchTerm,
        query_by: "name, courseName",
        per_page: 50,
        filter_by: filter,
        exhaustive_search: true,
        num_typos: 0,
        typo_tokens_threshold: 0
      };

      const client = bookingSearchClient(bookingsSearchAPIKey);
      const results = await client
        .collections(
          `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.bookings`
        )
        .documents()
        .search(searchParameters);

      return results.hits.map((hit) => hit.document);
    },
    [bookingsSearchAPIKey]
  );

  return {
    searchForBooking
  };
};

export default useBookingSearchReader;
