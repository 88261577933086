import React, { useRef, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/Person";
import styled from "styled-components/macro";
//Import Helpers
import { COLORS, FONTWEIGHT, CONSTANTS } from "helpers/designConstants";
import useOnClickOutside from "helpers/useOnClickOutside";
import { useAuth } from "database/useAuth";
import EditLoggedInUser from "./EditLoggedInUser";

const AdminDropdownMenu = ({ didSelectUploadData }) => {
  const { t } = useTranslation();

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsActive(false));

  const [isActive, setIsActive] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);

  const { user, logout } = useAuth();

  const onClickUserMenu = () => setIsActive(!isActive);

  const onClickShowEditUser = () => {
    setIsActive(!isActive);
    setShowEditUserModal(!showEditUserModal);
  };

  return (
    <Fragment>
      <DropDownContainer ref={dropdownRef}>
        <MenuTrigger onClick={onClickUserMenu} isActive={isActive}>
          <PersonIcon sx={{ height: "24px" }} />
          <span>{user?.displayName.match(/\b(\w)/g).join("")}</span>
        </MenuTrigger>
        <Nav isActive={isActive}>
          <NameHeader>{user?.displayName}</NameHeader>
          <ul>
            {(user?.possibleTypes.includes("god") ||
              user?.possibleTypes.includes("adidasManager")) && (
              <li onClick={didSelectUploadData}>
                {t("upload_data_menu_item")}
              </li>
            )}
            <li onClick={onClickShowEditUser}>{t("edit_user_menu_item")}</li>
            <li onClick={() => logout()}>{t("logout_menu_item")}</li>
          </ul>
        </Nav>
      </DropDownContainer>
      <EditLoggedInUser
        title={t("edit_user_title")}
        user={user}
        show={showEditUserModal}
        onClose={() => setShowEditUserModal(!showEditUserModal)}
      />
    </Fragment>
  );
};

const DropDownContainer = styled.div`
  position: relative;
`;

const MenuTrigger = styled.button`
  background: ${({ isActive }) => (isActive ? COLORS.text : "none")};
  color: ${({ isActive }) => (isActive ? COLORS.primaryInverted : COLORS.text)};
  cursor: pointer;
  border: none;
  font-weight: ${FONTWEIGHT.heavy};
  border-radius: ${CONSTANTS.borderRadius};
  /* border: 1px solid ${COLORS.text}; */
  padding: 2px 8px 2px 4px;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding-right: 0px;
  }

  @media (hover: hover) {
    &:hover {
      background: ${COLORS.text};
      color: ${COLORS.primaryInverted};
    }
  }
`;

const NameHeader = styled.div`
  padding: 4px 10px;
  text-transform: uppercase;
  background-color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.primaryInverted};
`;

const Nav = styled.nav`
  text-align: center;
  background: #ffffff;
  border-radius: ${CONSTANTS.borderRadius};
  position: absolute;
  z-index: 1000;
  top: 40px;
  right: 0;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;

  opacity: ${(props) => (props.isActive ? 1 : 0)};
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  transform: ${(props) =>
    props.isActive ? "translateY(0)" : "translateY(-20px)"};
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    border-bottom: 1px solid #dddddd;
    padding: 15px 20px;
    cursor: pointer;
    color: ${COLORS.text};
    font-weight: ${FONTWEIGHT.heavy};

    &:hover {
      background-color: ${COLORS.tertiary};
    }
    a {
      text-decoration: none;
      padding: 15px 20px;
      display: block;
    }
  }
`;

export default AdminDropdownMenu;
