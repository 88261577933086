import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Modal from "components/shared/modal/Modal";
import { Button } from "components/shared/standardElements";
import LabelledTextInput from "components/shared/LabelledTextInput";
import { COLORS, FONTSIZE } from "helpers/designConstants";
import { useForm } from "react-hook-form";

const ConfirmOrderSubmissionModal = ({
  title,
  show,
  onClose,
  onSubmitOrder
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    onSubmitOrder(data.name, data.email);
  };

  return (
    <Modal
      title={title}
      show={show}
      onClose={onClose}
      disableCloseOnOutsideClick
    >
      <Message>
        <p>{t("submit_order_message_1")}</p>
        <p>{t("submit_order_message_2")}</p>
      </Message>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledLabelledTextInput
          labelText={t("name_title")}
          type='text'
          id='name'
          name='name'
          placeholder={t("full_name_label")}
          // onChange={(e) => setName(e.target.value)}
          {...register("name", { required: t("name_required_error") })}
        />
        {errors.name && (
          <StyledInputErrorMessage>
            {errors.name.message}
          </StyledInputErrorMessage>
        )}
        <StyledLabelledTextInput
          labelText={t("email_address")}
          type='email'
          id='email'
          name='email'
          placeholder={t("email_address")}
          // onChange={(e) => setEmail(e.target.value)}
          {...register("email", {
            required: t("email_required_error"),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: t("invalid_email_error")
            }
          })}
        />
        {errors.email && (
          <StyledInputErrorMessage>
            {errors.email.message}
          </StyledInputErrorMessage>
        )}
        <StyledButton>{t("send_button")}</StyledButton>
      </StyledForm>
    </Modal>
  );
};

const Message = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  padding: 10px 0;
  p {
    margin: 3px 0;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
`;

const StyledLabelledTextInput = styled(LabelledTextInput)`
  padding: 20px 0 0;
`;

const StyledButton = styled(Button)`
  margin: 50px 0;
`;

const StyledInputErrorMessage = styled.div`
  padding: 3px 0 0 5px;
  color: ${COLORS.alertRed};
  font-size: ${FONTSIZE.xsmall};
  align-self: flex-start;
`;

export default ConfirmOrderSubmissionModal;
