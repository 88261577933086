import React from "react";

import styled from "styled-components/macro";
import { COLORS, CONSTANTS } from "helpers/designConstants";

const ScrollAreaCard = ({ onClick, className, children }) => {
  return (
    <Container className={className} onClick={onClick}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  background: ${COLORS.primaryInverted};
  border-radius: ${CONSTANTS.borderRadius};
  border: 1px solid ${COLORS.secondary};
  padding: 5px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    /* border: 1px solid ${COLORS.text}; */
    /* background: ${COLORS.secondary}; */
    box-shadow: 0px 0px 4px ${COLORS.text};
  }
`;

export default ScrollAreaCard;
