import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

import useArticleSearchReader from "database/articles/useArticleSearchReader";
import NestedNav from "components/shared/nestedNavigation/NestedNav";
import { useArticleReader } from "database/articles/useArticleReader";

import ArticleCard from "components/shared/ArticleCard";
import useArticleZoomModal from "components/shared/articleZoomModal/useArticleZoomModal";
import ArticleZoomModal from "components/shared/articleZoomModal/ArticleZoomModal";
import useInventoryModal from "components/shared/inventoryModal/useInventoryModal";
import InventoryModal from "components/shared/inventoryModal/InventoryModal";
import { MiniButton } from "components/shared/standardElements";
import EditArticleQtysModal from "components/shared/editArticleQtysModal/EditArticleQtysModal";
import Loading from "components/shared/Loading";
import { flattenDeep, xor, isEqual } from "lodash";

const ProductPickerScrollArea = ({
  pricingToShowPreview,
  pricingToShowOrdering,
  allowMultipleSeasons = false,
  allowOrderEntry = true,
  showInventoryButton,
  selectedSeasonKey,
  selectedSeasonSiloKey,
  articleSort,
  searchResults,
  selectedArticles = [],
  onArticleSelection,
  onAddArticlesFromGroup,
  onRemoveArticlesFromCollection,
  initialSelectedArticles,
  shouldLockProduct,
  showCollectionHeader,
  formControl,
  getFormValues,
  setFormValue,
  overallCategorySummary
}) => {
  //HOOKS
  const { t, i18n } = useTranslation();
  const { searchByCategoryKey, searchByCollectionKey } =
    useArticleSearchReader();
  const {
    activeSeasons,
    schemas,
    getCollectionKeysForSeason,
    getGeneralCategoryKeysForSeason
  } = useArticleReader();
  const {
    toggleArticleZoomModal,
    isZoomModalShowing,
    onZoomArticle,
    zoomArticle,
    zoomArticleImages
  } = useArticleZoomModal();
  const {
    isInventoryModalShowing,
    toggleInventoryModal,
    inventoryArticle,
    onViewInventory
  } = useInventoryModal();

  //STATE
  const [selectedSeason, setSelectedSeason] = useState();
  const [selectedSeasonSilo, setSelectedSeasonSilo] = useState();
  const [navLocation, setNavLocation] = useState([]);
  const [visibleArticles, setVisibleArticles] = useState();
  const [allArticleGroupKeys, setAllArticleGroupKeys] = useState([]);
  const [initialSelection, setInitialSelection] = useState();
  const [articleIDForOrderEdit, setArticleIDForOrderEdit] = useState();

  const articleScrollContainer = useRef();

  useEffect(() => {
    if (
      activeSeasons == null ||
      Object.keys(schemas).length === 0 ||
      selectedSeasonKey == null
    )
      return;
    setSelectedSeason(
      activeSeasons.filter((season) => season.key === selectedSeasonKey)[0]
    );
    let selectedSchema = [];
    if (articleSort === "collection") {
      selectedSchema = schemas?.[selectedSeasonKey]?.schemas;
    } else if (articleSort === "category") {
      selectedSchema = schemas?.[selectedSeasonKey]?.categorySchemas;
    }

    setSelectedSeasonSilo(
      selectedSchema.filter((schema) => schema.key === selectedSeasonSiloKey)[0]
    );
  }, [
    activeSeasons,
    articleSort,
    schemas,
    selectedSeasonKey,
    selectedSeasonSiloKey
  ]);

  useEffect(() => {
    if (selectedSeasonKey == null || articleSort == null) return;
    if (articleSort === "collection") {
      getCollectionKeysForSeason(selectedSeasonKey).then(
        (seasonCollectionKeys) => {
          setAllArticleGroupKeys(seasonCollectionKeys);
        }
      );
    } else if (articleSort === "category") {
      getGeneralCategoryKeysForSeason(selectedSeasonKey).then(
        (generalCategoryKeys) => {
          setAllArticleGroupKeys(generalCategoryKeys);
        }
      );
    }
  }, [
    getCollectionKeysForSeason,
    getGeneralCategoryKeysForSeason,
    selectedSeasonKey,
    articleSort
  ]);

  const userMadeSelection = useCallback(
    async (item) => {
      if (articleSort === "collection") {
        const result = await searchByCollectionKey(
          selectedSeasonKey,
          item?.key,
          true
        );
        setVisibleArticles(result);
      } else if (articleSort === "category") {
        const result = await searchByCategoryKey(
          selectedSeasonKey,
          item?.key,
          true
        );
        setVisibleArticles(result);
      }
    },
    [articleSort, searchByCategoryKey, searchByCollectionKey, selectedSeasonKey]
  );

  const onAddArticlesInGroup = async (key) => {
    onAddArticlesFromGroup(key);
  };

  const onRemoveArticlesInCollection = async (key) => {
    onRemoveArticlesFromCollection(key);
  };

  const onUpdateCurrentNavigationLocation = useCallback(
    (location) => {
      if (schemas == null || selectedSeasonKey == null) return;

      let schema = [];
      if (articleSort === "collection") {
        schema = schemas?.[selectedSeasonKey]?.schemas;
      } else if (articleSort === "category") {
        schema = schemas?.[selectedSeasonKey]?.categorySchemas;
      }

      let composedBreadCrumbs = [];
      let currentDepthData;
      location?.forEach((selection, depth) => {
        if (depth === 0) {
          currentDepthData = schema?.[selection];
        } else {
          if (depth <= currentDepthData?.groups?.length) {
            currentDepthData = currentDepthData?.groups[selection];
          }
        }
        composedBreadCrumbs.push(
          i18n.language === "en"
            ? currentDepthData?.name
            : currentDepthData?.name_fr
        );
        if (depth !== location.length - 1) composedBreadCrumbs.push(" ‣ ");
      });

      setNavLocation(composedBreadCrumbs);
    },
    [articleSort, i18n.language, schemas, selectedSeasonKey]
  );

  useEffect(() => {
    if (searchResults) {
      setVisibleArticles(searchResults);
    }
  }, [searchResults]);

  //Get the initial selection
  //Iterate through the nesting and pick the first item in each level
  useEffect(() => {
    if (
      Object.keys(schemas).length === 0 ||
      activeSeasons == null ||
      selectedSeasonSilo == null
    )
      return;
    if (!searchResults) {
      let isBottom = false;
      let selection = [];
      let currentDepth = selectedSeasonSilo;
      while (!isBottom) {
        if (currentDepth?.groups != null) {
          selection.push(0);
          currentDepth = currentDepth?.groups[0];
        } else {
          selection.push(0);
          isBottom = true;
          userMadeSelection(currentDepth);
        }
      }

      setInitialSelection(selection);
    }
  }, [
    userMadeSelection,
    selectedSeasonSilo,
    searchResults,
    schemas,
    activeSeasons
    // initialSelection,
    // navLocation
  ]);

  //Reset article scroll container to top on new sidebar selection
  useEffect(() => {
    if (!searchResults && articleScrollContainer?.current != null) {
      articleScrollContainer.current.scrollTop = 0;
    }
  }, [visibleArticles, searchResults]);

  return schemas == null ||
    activeSeasons == null ||
    selectedSeason == null ||
    selectedSeasonSilo == null ? (
    <Loading />
  ) : (
    <Wrapper shouldHideMenu={searchResults != null}>
      <LeftSideBar shouldHide={searchResults != null}>
        <SeasonPicker>
          <ul>
            <SeasonListItem key={selectedSeasonKey} isSelected={true}>
              {i18n.language === "en"
                ? selectedSeason?.name
                : selectedSeason?.name_fr}
            </SeasonListItem>
          </ul>
        </SeasonPicker>
        <NestedNav
          userSelectedItem={userMadeSelection}
          onUpdateCurrentNavigationLocation={onUpdateCurrentNavigationLocation}
          navListMap={[selectedSeasonSilo]}
          initialSelection={initialSelection}
          backgroundColor={"white"}
          onAddArticlesInGroup={onAddArticlesInGroup}
          onRemoveArticlesInCollection={onRemoveArticlesInCollection}
          currentlySelectedArticleGroupKeys={
            articleSort === "collection"
              ? flattenDeep(
                  selectedArticles.map(
                    (article) => article[`${selectedSeasonKey}_collectionKeys`]
                  )
                )
              : selectedArticles.map(
                  (article) =>
                    article[`${selectedSeasonKey}_generalCategoryKey`]
                )
          }
          seasonArticleGroupKeys={allArticleGroupKeys}
        />
      </LeftSideBar>
      <CollectionSectionBody ref={articleScrollContainer}>
        {/* {showCollectionHeader && searchResults == null ? ( */}
        <SectionHeader>
          <HeaderBreadCrumbs>
            {showCollectionHeader && searchResults == null
              ? navLocation.map((text, index) => (
                  <span key={`crubms-${index}`}>{text}</span>
                ))
              : "Performing Search..."}
          </HeaderBreadCrumbs>
        </SectionHeader>
        {/* ) : (
          <div />
        )} */}
        <ScrollBody>
          {visibleArticles?.map((article) => (
            <ArticleCard
              key={article.articleNumber}
              seasonKey={selectedSeason.key}
              priceToShow={pricingToShowPreview}
              article={article}
              showEditButton={allowOrderEntry}
              showInventoryButton={showInventoryButton}
              shouldShowLimitedSoldOut
              isSelectable
              isSelected={selectedArticles.some(
                (selectedArticle) => selectedArticle.id === article.id
              )}
              didSelectAddRemove={onArticleSelection}
              onZoomArticle={onZoomArticle}
              onViewInventory={onViewInventory}
              didSelectEditQuantities={setArticleIDForOrderEdit}
              isLocked={
                initialSelectedArticles?.some(
                  (intitialArticle) => intitialArticle.id === article.id
                ) && shouldLockProduct
              }
            />
          ))}
        </ScrollBody>
      </CollectionSectionBody>
      <ArticleZoomModal
        show={isZoomModalShowing}
        onClose={toggleArticleZoomModal}
        imageList={zoomArticleImages}
        article={zoomArticle}
      />
      {showInventoryButton && (
        <InventoryModal
          show={isInventoryModalShowing}
          onClose={toggleInventoryModal}
          article={inventoryArticle}
        />
      )}
      {allowOrderEntry && (
        <EditArticleQtysModal
          show={articleIDForOrderEdit != null}
          onClose={() => setArticleIDForOrderEdit()}
          articleID={articleIDForOrderEdit}
          articleDetails={
            visibleArticles?.filter(
              (article) => article.id === articleIDForOrderEdit
            )[0]
          }
          formControl={formControl}
          getFormValues={getFormValues}
          setFormValue={setFormValue}
          overallCategorySummary={overallCategorySummary}
          displayPrice={pricingToShowOrdering}
          seasonKey={selectedSeasonKey}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  position: relative;

  display: flex;
`;

const LeftSideBar = styled.div`
  flex-shrink: ${({ shouldHide }) => (shouldHide ? 100 : 0)};
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  border-right: 1px solid ${COLORS.secondary};
  transition: flex-shrink 0.6s ease-in-out;
`;

const SeasonPicker = styled.div`
  background: ${COLORS.tertiary};
  border-bottom: 1px solid ${COLORS.secondary};

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;

const SeasonListItem = styled.li`
  margin: 5px;
  padding: 10px;
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.medium};
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ isSelected }) =>
    isSelected ? COLORS.primary : COLORS.secondary};
  background: ${({ isSelected }) => isSelected && COLORS.secondary};
  border-radius: 4px;
`;

const CollectionSectionBody = styled.div`
  flex-grow: 100;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: min-content 1fr;
  background: ${COLORS.tertiary};
  transition: all 0.6s ease-in-out;
`;

const ScrollBody = styled.div`
  padding: 3px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 0.33333fr));
  /* grid-template-rows: min-content 1fr; */
  grid-gap: 3px;
  align-content: start;
  /* background: ${COLORS.tertiary}; */
  /* position: relative; */
`;

const SectionHeader = styled.div`
  grid-column: 1/-1;
  z-index: 15;
  width: 100%;
  padding: 12px 5px;
  background-color: ${COLORS.secondary};
  border-top-right-radius: 4px;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 5px lightgrey;
`;

// const HeaderBreadCrumbs = styled.div`
//   color: ${COLORS.text};
//   text-transform: uppercase;
//   font-size: ${FONTSIZE.xsmall};
//   font-weight: ${FONTWEIGHT.heavy};
//   padding-left: 10px;
// `;

const HeaderBreadCrumbs = styled.div`
  color: ${COLORS.text};
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  padding-left: 10px;
  display: flex;
  align-items: center;

  span {
    padding: 3px;
    :last-child {
      color: ${COLORS.primary};
      font-size: ${FONTSIZE.medium};
    }
  }
`;

const HeaderButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledMiniButton = styled(MiniButton)`
  margin-left: 10px;
`;

export default ProductPickerScrollArea;
