import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/shared/modal/Modal";
import styled from "styled-components/macro";
import { COLORS, FONTSIZE } from "helpers/designConstants";
import { Button } from "components/shared/standardElements";
import { useForm } from "react-hook-form";
import LabelledTextInput from "components/shared/LabelledTextInput";

const CreateNewContactModal = ({
  show,
  onClose,
  name,
  courseName,
  existingEmails,
  onCreateContact
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const handleFormSubmit = (data) => {
    onCreateContact(data);
  };

  return (
    <Modal
      title='CREATE CONTACT'
      show={show}
      onClose={onClose}
      disableCloseOnOutsideClick
      maxHeight={"90vh"}
    >
      <Container>
        <StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
          <InputAndErrorsBlock>
            <LabelledTextInput
              labelText={t("name_title")}
              type='text'
              id='name'
              name='name'
              capitalize
              placeholder={t("full_name_label")}
              defaultValue={name}
              {...register("name", { required: t("name_required_error") })}
            />
            {errors.name && (
              <StyledInputErrorMessage>
                {errors.name.message}
              </StyledInputErrorMessage>
            )}
          </InputAndErrorsBlock>
          <InputAndErrorsBlock>
            <LabelledTextInput
              labelText={t("email_address")}
              type='email'
              id='email'
              name='email'
              placeholder={t("email_address")}
              {...register("email", {
                required: t("email_required_error"),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t("invalid_email_error")
                },
                validate: (value) =>
                  !existingEmails.includes(value) || t("email_exists_error")
              })}
            />
            {errors.email && (
              <StyledInputErrorMessage>
                {errors.email.message}
              </StyledInputErrorMessage>
            )}
          </InputAndErrorsBlock>
          <InputAndErrorsBlock>
            <LabelledTextInput
              labelText={t("course_name_label")}
              type='text'
              id='courseName'
              name='courseName'
              capitalize
              placeholder={t("course_name_label")}
              defaultValue={courseName}
              {...register("courseName", {
                required: t("course_name_required_error")
              })}
            />
            {errors.name && (
              <StyledInputErrorMessage>
                {errors.name.message}
              </StyledInputErrorMessage>
            )}
          </InputAndErrorsBlock>
          <Button primary type='submit'>
            CREATE
          </Button>
        </StyledForm>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  height: 100%;
  min-width: 300px;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-width: 400px;
`;

const InputAndErrorsBlock = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const StyledInputErrorMessage = styled.div`
  padding: 3px 0 0 5px;
  color: ${COLORS.alertRed};
  font-size: ${FONTSIZE.xsmall};
  align-self: flex-start;
`;

export default CreateNewContactModal;
