import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Modal from "components/shared/modal/Modal";
import { Button } from "components/shared/standardElements";
import LabelledTextInput from "components/shared/LabelledTextInput";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

import useOrderWriter from "database/orders/useOrderWriter";
import Loading from "components/shared/Loading";

const OrderPickerModal = ({
  title,
  show,
  onClose,
  orders,
  selectedOrderID,
  onSelectedOrderID
}) => {
  const { t } = useTranslation();
  const { createNewOrder } = useOrderWriter();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState();

  const onCreateNewOrder = async (e) => {
    e && e.preventDefault();
    setIsLoading(true);
    const orderID = await createNewOrder(name, true);
    onSelectedOrderID(orderID);
    setIsLoading(false);
    onClose();
  };

  const didSelectOrder = (orderID) => {
    onSelectedOrderID(orderID);
    onClose();
  };

  return (
    <Modal show={show} onClose={onClose} title={title} maxHeight={"88vh"}>
      <Loading isLoading={isLoading} />
      <Content>
        <StyledForm onSubmit={onCreateNewOrder}>
          <LabelledTextInput
            labelText={t("name_title")}
            placeholder={t("order_name")}
            onChange={(e) => setName(e.target.value)}
          />
          <StyledButton onClick={onCreateNewOrder}>
            {t("create_button")}
          </StyledButton>
        </StyledForm>
        {orders != null && Object.keys(orders).length > 0 && (
          <>
            <SelectOrderHeader>{t("select_order")}</SelectOrderHeader>
            <OrdersList>
              {orders != null &&
                orders.map((order) => (
                  <OrderItem
                    key={order.id}
                    isSelected={selectedOrderID === order.id}
                    onClick={() => didSelectOrder(order.id)}
                  >
                    {order.name}
                  </OrderItem>
                ))}
              <OrderItem onClick={() => didSelectOrder()}>None</OrderItem>
            </OrdersList>
          </>
        )}
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const StyledForm = styled.form`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const SelectOrderHeader = styled.div`
  margin-top: 30px;
  border-top: 1px solid ${COLORS.secondary};
  padding: 20px 0 10px;
  text-transform: uppercase;
  text-align: center;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.medium};
`;

const OrdersList = styled.div`
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
`;

const OrderItem = styled.div`
  cursor: pointer;
  background: ${({ isSelected }) =>
    isSelected ? COLORS.secondary : "inherit"};
  padding: 12px;
  text-transform: uppercase;
  color: ${COLORS.text};

  :hover {
    background: ${({ isSelected }) =>
      isSelected ? COLORS.secondary : COLORS.tertiary};
  }

  :not(:first-child) {
    border-top: 1px solid ${COLORS.secondary};
  }
`;

export default OrderPickerModal;
