import React, { Children, useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS, CONSTANTS } from "helpers/designConstants";
import TabTitle from "./TabTitle";

import { useSearchParams, useLocation } from "react-router-dom";

const TabView = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    currentParams.selectedTab == null &&
      setSearchParams({ ...currentParams, selectedTab: 0 }, { replace: true });
  }, [searchParams, setSearchParams, location]);

  const selectedTab = () => {
    return parseInt(Object.fromEntries([...searchParams]).selectedTab);
  };

  return (
    <Container>
      <TabTitles>
        {Children.map(
          children,
          (item, index) =>
            item != null && (
              <TabTitle
                key={index}
                index={index}
                isHighlighted={item?.props.isHighlighted}
                title={item?.props.title}
                alertCount={item?.props.alertCount}
                setSelectedTab={(tab) =>
                  setSearchParams(
                    {
                      ...Object.fromEntries([...searchParams]),
                      selectedTab: tab
                    },
                    { replace: true }
                  )
                }
                isSelected={selectedTab() === index ? true : false}
              />
            )
        )}
      </TabTitles>
      <Content>
        {Array.isArray(children) ? children[selectedTab()] : children}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`;

const TabTitles = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  /* border: 2px solid ${COLORS.tertiary}; */
  border-radius: ${CONSTANTS.borderRadius};
  overflow: hidden;
  height: 100%;
  background: ${COLORS.tertiary};
`;

export default TabView;
