import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components/macro";
import { toast } from "react-toastify";

import useNavigation from "helpers/useNavigation";

import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
//Basic Styled Components Import
import { Button } from "components/shared/standardElements";

//Context Import
import { useAuth } from "database/useAuth";
import { useDatabaseReader } from "database/useDatabaseReader";

//Components Import
import LoginContentWrapper from "components/shared/loginContentWrapper/LoginContentWrapper";
import LabelledTextInput from "components/shared/LabelledTextInput";
import ConfirmModal from "components/shared/ConfirmModal";
import UserTypePickerModal from "./UserTypePickerModal";
import Loading from "components/shared/Loading";
import Checkbox from "components/shared/Checkbox";

const Errors = {
  "auth/invalid-email": "Invalid Email Format",
  "auth/user-not-found": "User Not Found",
  "auth/wrong-password": "Incorrect Password",
  "auth/internal-error": "Please fill in both fields",
  "auth/user-disabled": "The user has been disabled"
};

const AdminLogin = () => {
  //HOOKS
  const { t, i18n } = useTranslation();
  const { login, user, sendPasswordReset, updateUserType } = useAuth();
  const { userHasNoAssociatedCourses, courses, selectedCourse } =
    useDatabaseReader();

  const [keepLoggedIn, setKeepLoggedIn] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showConfirmPasswordReset, setShowConfirmPasswordReset] =
    useState(false);
  const [showUserTypePickerModal, setShowUserTypePickerModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [navigateForward] = useNavigation();

  const userChoseUserType = (userType) => {
    updateUserType(userType);
  };

  useEffect(() => {
    if (user != null) {
      if (user.type === "adidasRep") {
        navigateForward("/reps/mainDashboard");
      }
    }
  }, [user, navigateForward]);

  const onTextInputChange = (e) => {
    if (e.target.id === "email") {
      setEmail(e.target.value);
    } else if (e.target.id === "password") {
      setPassword(e.target.value);
    }
  };

  const submitLoginCredentials = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      //User is returned on login but don't need it here
      await login(email, password, keepLoggedIn);
    } catch (error) {
      toast.error(Errors[error.code]);
      setLoading(false);
    }
  };

  const onConfirmPasswordReset = async () => {
    setShowConfirmPasswordReset(false);
    let toastID;
    try {
      toastID = await toast.promise(sendPasswordReset(email), {
        pending: t("pending_password_reset_message"),
        success: t("password_reset_sent_message"),
        error: t("password_reset_error_message")
      });
    } catch (error) {
      toast.update(toastID, { render: Errors[error.code], type: "error" });
    }
  };

  const onChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Container>
      {process.env.REACT_APP_USER_ENVIRONMENT !== "prod" && (
        <TestServer userEnv={process.env.REACT_APP_USER_ENVIRONMENT}>
          TEST SERVER
        </TestServer>
      )}
      <LoginContentWrapper title=''>
        <form onSubmit={submitLoginCredentials}>
          <InputsWrapper>
            <EmailPasswordInput
              marginBottom='10px'
              type='email'
              labelText={t("email_address")}
              id='email'
              onChange={onTextInputChange}
              value={email}
            />
            <EmailPasswordInput
              marginBottom='15px'
              type='password'
              labelText={t("password")}
              id='password'
              onChange={onTextInputChange}
              value={password}
            />
            <StyledCheckbox
              color={COLORS.text}
              selected={keepLoggedIn}
              onChange={() => setKeepLoggedIn(!keepLoggedIn)}
              label={t("keep_logged_in")}
            />
            <StyledButton type='submit'>{t("go_button")}</StyledButton>
          </InputsWrapper>
        </form>
        <PasswordReset
          onClick={
            email !== "" ? () => setShowConfirmPasswordReset(true) : null
          }
        >
          {t("forgot_password")}
        </PasswordReset>
        <LanguagePicker>
          <Language
            selected={i18n.language === "en"}
            onClick={() => onChangeLanguage("en")}
          >
            EN
          </Language>
          <Language
            selected={i18n.language === "fr-CA"}
            onClick={() => onChangeLanguage("fr-CA")}
          >
            FR
          </Language>
        </LanguagePicker>
      </LoginContentWrapper>
      <ConfirmModal
        show={showConfirmPasswordReset}
        onClose={() => setShowConfirmPasswordReset(false)}
        onConfirm={onConfirmPasswordReset}
        closeButtonHidden={true}
        disableCloseOnOutsideClick={true}
        title={t("confirm_password_reset")}
        message={t("password_reset_message", { email: email })}
        buttonTitle={t("send_button")}
      />
      <UserTypePickerModal
        show={showUserTypePickerModal}
        userChoseUserType={userChoseUserType}
        userTypes={user?.possibleTypes}
      />
      <Loading isLoading={loading} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
`;

const TestServer = styled.div`
  width: 100%;
  padding: 5px;
  color: ${({ userEnv }) =>
    userEnv === "dev" ? COLORS.alertRed : userEnv === "local" && COLORS.green};
  font-weight: ${FONTWEIGHT.heavy};
  border-top: ${({ userEnv }) =>
    userEnv === "dev"
      ? `3px solid ${COLORS.alertRed}`
      : userEnv === "local" && `3px solid ${COLORS.green}`};
  border-bottom: ${({ userEnv }) =>
    userEnv === "dev"
      ? `3px solid ${COLORS.alertRed}`
      : userEnv === "local" && `3px solid ${COLORS.green}`};
  text-align: center;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmailPasswordInput = styled(LabelledTextInput)`
  min-width: 300px;
`;

const StyledCheckbox = styled(Checkbox)`
  color: ${COLORS.text};
  text-transform: uppercase;
  padding: 0 0 15px 0;
`;

const StyledButton = styled(Button)`
  width: 75px;
`;

const PasswordReset = styled.div`
  font-size: ${FONTSIZE.small};
  color: ${COLORS.text};
  padding-top: 20px;
  text-decoration: underline;
  cursor: pointer;
`;

const LanguagePicker = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-around;
  color: ${COLORS.text};
  width: 100px;
  cursor: pointer;
`;

const Language = styled.div`
  text-decoration: ${({ selected }) => selected && "underline"};

  :hover {
    text-decoration: underline;
  }
`;

export default AdminLogin;
