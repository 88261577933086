import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  CONSTANTS,
  COLORS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

import ImageComponent from "components/shared/ImageComponent";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { netPrice } from "helpers/helperFunctions";

const ArticleCard = ({
  seasonKey,
  article,
  className,
  showEditButton,
  showInventoryButton,
  didSelectEditQuantities,
  didSelectAddRemove,
  isSelectable,
  isSelected,
  onZoomArticle,
  onViewInventory,
  limitWarnings,
  shouldShowLimitedSoldOut,
  priceToShow,
  isLocked
}) => {
  //HOOKS
  const { t, i18n } = useTranslation();

  let limitedSizes;
  let soldOut;
  if (shouldShowLimitedSoldOut) {
    limitedSizes = article?.sizes.some(
      (size) => article?.inventory.overallTotals?.sizes[size] === 0
    );
    soldOut =
      article?.inventory.overallTotals != null &&
      article?.inventory.overallTotals?.total === 0
        ? true
        : false;
  }

  return (
    <Container
      className={className}
      limitWarnings={limitWarnings}
      isSelected={isSelected}
    >
      {(limitedSizes || soldOut) && shouldShowLimitedSoldOut && (
        <InventoryInfo>
          <InventoryInfoTextWrapper>
            <InventoryInfoText>
              {soldOut
                ? t("sold_out_tag")
                : limitedSizes
                ? t("limited_sizes_tag")
                : ""}
            </InventoryInfoText>
          </InventoryInfoTextWrapper>
        </InventoryInfo>
      )}
      <ImageWrapper>
        <Image
          imageURLs={
            article?.imageURLs?.filter(
              (url) => url.includes("_MAIN_") && url.includes("_thumb")
            )[0]
          }
        />
      </ImageWrapper>
      <InfoWrapper>
        <ArticleInfo>
          <ArticleName>{article?.articleName}</ArticleName>
          <ArticleNumber>{article?.articleNumber}</ArticleNumber>
          <ArticleColor>
            {i18n.language === "en" ? article?.color : article?.color_fr}
          </ArticleColor>
          <ArticleSizes>
            <span>{t("sizes_label")}: </span>
            {article?.displaySizeRange}
          </ArticleSizes>
          {article != null && (
            <ArticlePrice>
              {priceToShow === "MSRP" ? (
                `$ ${article.retailPrice} ${t("MSRP")}`
              ) : priceToShow === "WHSL" ? (
                `$ ${netPrice(article.retailPrice, article.priceCode)} ${t(
                  "WHSL"
                )}`
              ) : priceToShow === "MSRP+WHSL" ? (
                <>
                  <div>{`$ ${article.retailPrice} ${t("MSRP")} /`}</div>
                  <div>{`$ ${netPrice(
                    article.retailPrice,
                    article.priceCode
                  )} ${t("WHSL")}`}</div>
                </>
              ) : priceToShow === "NONE" ? (
                ""
              ) : (
                `$ ${article?.retailPrice} ${t("MSRP")}`
              )}
            </ArticlePrice>
          )}
          <IntroDate>
            <span>{t("intro_date_label")}: </span>
            {i18n.language === "en"
              ? article?.[`${seasonKey}_deliveryDate`]
              : article?.[`${seasonKey}_deliveryDate_fr`]}
          </IntroDate>
        </ArticleInfo>
        <ActionButtonGroup>
          <ActionButton onClick={() => onZoomArticle(article)}>
            <ZoomInIcon />
          </ActionButton>
          {showInventoryButton && (
            <ActionButton onClick={() => onViewInventory(article)}>
              <FormatListNumberedIcon />
            </ActionButton>
          )}
          {showEditButton && (
            <ActionButton
              onClick={() => didSelectEditQuantities(article.id)}
              limitWarnings={limitWarnings}
            >
              <EditIcon />
            </ActionButton>
          )}
          {isSelectable && !isLocked && (
            <AddRemoveButton isSelected={isSelected}>
              {isSelected ? (
                <RemoveIcon onClick={() => didSelectAddRemove(article)} />
              ) : (
                <AddIcon onClick={() => didSelectAddRemove(article)} />
              )}
            </AddRemoveButton>
          )}
        </ActionButtonGroup>
      </InfoWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 5px;
  display: flex;
  background: ${COLORS.pageBackground};
  border-radius: ${CONSTANTS.borderRadius};
  border: 1px solid
    ${({ limitWarnings, isSelected }) =>
      limitWarnings
        ? COLORS.alertOrange
        : isSelected
        ? COLORS.green
        : COLORS.secondary};
  width: 100%;
  position: relative;
`;

const InventoryInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;

  clip: rect(0px, 150px, 150px, 0px);
  overflow: visible;
`;

const InventoryInfoTextWrapper = styled.div`
  width: 200px;
  transform: rotate(-45deg);
  background: rgba(211, 211, 211, 0.4);
  border: 1px solid ${COLORS.alertOrange};
  padding: 5px;
  display: flex;
  justify-content: center;
`;

const InventoryInfoText = styled.div`
  width: 50px;
  text-transform: uppercase;
  color: ${COLORS.alertOrange};
  font-weight: ${FONTWEIGHT.heavy};
`;

const ImageWrapper = styled.div`
  width: 45%;
  height: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled(ImageComponent)`
  max-width: 100%;
  max-height: 100%;
`;

const InfoWrapper = styled.div`
  max-width: 55%;
  padding: 5px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ArticleInfo = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-between;
`;

const ArticleName = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  overflow-wrap: break-word;
`;

const ArticleNumber = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleColor = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  overflow-wrap: break-word;
`;

const ArticleSizes = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  text-transform: uppercase;

  & > span {
    font-weight: ${FONTWEIGHT.heavy};
  }
`;

const ArticlePrice = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 5px;
`;

const IntroDate = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  text-transform: uppercase;

  & > span {
    font-weight: ${FONTWEIGHT.heavy};
  }
`;

const ActionButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ActionButton = styled.div`
  margin: 5px;
  width: 30px;
  height: 30px;
  border: 2px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  color: ${COLORS.secondary};
  /* align-self: flex-end; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-color: ${({ limitWarnings }) => limitWarnings && COLORS.alertOrange};
  color: ${({ limitWarnings }) => limitWarnings && COLORS.alertOrange};

  @media (hover: hover) {
    :hover {
      background: ${({ limitWarnings }) =>
        limitWarnings ? COLORS.alertOrange : COLORS.secondary};
      color: white;
    }
  }
`;

const AddRemoveButton = styled.div`
  margin: 5px;
  width: 30px;
  height: 30px;
  border: 2px solid
    ${(props) => (props.isSelected ? COLORS.alertRed : COLORS.select)};
  border-radius: ${CONSTANTS.borderRadius};
  color: ${(props) => (props.isSelected ? COLORS.alertRed : COLORS.select)};
  /* align-self: flex-end; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      background: ${(props) =>
        props.isSelected ? COLORS.alertRed : COLORS.select};
      color: white;
    }
  }
`;

export default ArticleCard;
