import React from "react";
import styled from "styled-components/macro";
import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS
} from "helpers/designConstants";

import SaveIcon from "@mui/icons-material/Save";

const CartHeader = ({ onSave, showSaveButton }) => {
  return (
    <Header>
      <LeftHeader></LeftHeader>
      <RightHeader>
        {showSaveButton && (
          <SaveButton onClick={onSave}>
            <SaveIcon fontSize='large' />
          </SaveButton>
        )}
      </RightHeader>
    </Header>
  );
};

const Header = styled.div`
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 10px; */
`;
const LeftHeader = styled.div``;

const NavText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  transition: 0.3s;

  &:hover {
    color: ${COLORS.primaryHover};
  }
`;

const RightHeader = styled.div`
  display: flex;
`;

// const SaveButton = styled.button`
//   padding: 0;
//   border: none;
//   background: none;
//   position: relative;
//   padding-right: 10px;
//   color: ${COLORS.text};
//   font-size: ${FONTSIZE.xlarge};
//   display: flex;
//   align-items: center;
//   cursor: pointer;
// `;

const SaveButton = styled.div`
  position: relative;
  background: ${COLORS.primaryInverted};
  padding: 0 5px;
  color: ${COLORS.text};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};

  @media (hover: hover) {
    &:hover {
      background: ${COLORS.secondary};
      /* color: ${COLORS.primaryInverted}; */
    }
  }
`;

export default CartHeader;
