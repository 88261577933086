import { useCallback } from "react";
import { articleSearchClient } from "services/typesense";

import { useArticleReader } from "database/articles/useArticleReader";
import { chunk } from "lodash";

const useArticleSearchReader = () => {
  const { activeSeasons } = useArticleReader();

  const retrieveArticlesByIDs = useCallback(async (ids) => {
    process.env.NODE_ENV === "development" &&
      console.log("Calling to Typesense to get Articles by ID");
    if (ids == null || ids.length === 0) return {};
    try {
      const chunks = chunk(ids, 200);
      let searchRequests = { searches: [] };
      chunks.forEach((chunk) => {
        searchRequests.searches.push({
          collection: `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`,
          filter_by: ids && ids.length ? `id: [${chunk}]` : undefined
        });
      });

      // Search parameters that are common to all searches go here
      let commonSearchParams = {
        q: "*",
        per_page: 200
      };

      let result = await articleSearchClient.multiSearch.perform(
        searchRequests,
        commonSearchParams
      );

      let retrievedArticles = {};
      result.results.forEach((chunk) => {
        chunk.hits.map((hit) =>
          !hit.document.dropped
            ? (retrievedArticles[hit.document.id] = hit.document)
            : null
        );
      });

      return retrievedArticles;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const retrieveAllActiveSeasonArticleIDs = useCallback(async () => {
    process.env.NODE_ENV === "development" &&
      console.log("Calling to Typesense to get all active season ArticleIDs");
    let searchParameters = {
      q: "*",
      query_by: "articleNumber",
      filter_by: activeSeasons,
      per_page: 200,
      include_fields: "id, articleNumber"
    };

    try {
      let retrievedArticles = [];
      let result = await articleSearchClient
        .collections(
          `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`
        )
        .documents()
        .search(searchParameters);
      retrievedArticles = retrievedArticles.concat(
        result.hits.map((hit) => hit.document)
      );
      if (result.hits.length < result.found) {
        const pages = Math.ceil(result.found / result.hits.length);
        for (let page = 2; page <= pages; page++) {
          searchParameters.page = page;
          let result = await articleSearchClient
            .collections(
              `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`
            )
            .documents()
            .search(searchParameters);
          retrievedArticles = retrievedArticles.concat(
            result.hits.map((hit) => hit.document)
          );
        }
      }
      let articleNumberMap = {};
      retrievedArticles.forEach((articleInfo) => {
        if (!articleInfo.dropped) {
          if (articleNumberMap[articleInfo.articleNumber] == null) {
            articleNumberMap[articleInfo.articleNumber] = [];
          }
          articleNumberMap[articleInfo.articleNumber].push(articleInfo.id);
        }
      });

      return articleNumberMap;
    } catch (error) {
      console.log(error);
    }
  }, [activeSeasons]);

  const searchByArticleNameOrNumber = useCallback(
    async (searchTerm, season) => {
      let searchParameters = {
        q: searchTerm,
        query_by: "articleNumber, articleName",
        filter_by: `seasons:=${season}`,
        per_page: 20,
        exhaustive_search: true,
        num_typos: 0,
        typo_tokens_threshold: 0
      };
      const results = await articleSearchClient
        .collections(
          `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`
        )
        .documents()
        .search(searchParameters);

      const articles = results.hits.map((hit) => hit.document);
      return articles.filter((article) => !article.dropped);
    },
    []
  );

  const searchByCollectionKey = useCallback(
    async (season, collectionKey, shouldSort = false) => {
      process.env.NODE_ENV === "development" &&
        console.log("Calling to Typesense to get Articles by collectionKey");
      try {
        let searchParameters = {
          q: collectionKey,
          query_by: `${season}_collectionKeys`,
          filter_by: `seasons:=${season}`,
          per_page: 200,
          num_typos: 0,
          exhaustive_search: true
        };

        if (shouldSort)
          searchParameters.sort_by = `${season}_${collectionKey}_sort:asc`;

        let retrievedArticles = [];
        let result = await articleSearchClient
          .collections(
            `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`
          )
          .documents()
          .search(searchParameters);

        result.hits.map((hit) => retrievedArticles.push(hit.document));

        if (result.hits.length < result.found) {
          const pages = Math.ceil(result.found / result.hits.length);
          for (let page = 2; page <= pages; page++) {
            searchParameters.page = page;
            let result = await articleSearchClient
              .collections(
                `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`
              )
              .documents()
              .search(searchParameters);
            result.hits.map((hit) => retrievedArticles.push(hit.document));
          }
        }
        return retrievedArticles.filter((article) => !article.dropped);
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const searchByCategoryKey = useCallback(
    async (season, categoryKey, shouldSort = false) => {
      try {
        let searchParameters = {
          q: categoryKey,
          query_by: `${season}_generalCategoryKey`,
          filter_by: `seasons:=${season}`,
          // sort_by: shouldSort
          //   ? "retailPrice: desc, articleName: asc"
          //   : undefined,
          per_page: 200,
          num_typos: 0,
          exhaustive_search: true
        };

        if (shouldSort)
          searchParameters.sort_by = "retailPrice: desc, articleName: asc";

        let retrievedArticles = [];
        let result = await articleSearchClient
          .collections(
            `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`
          )
          .documents()
          .search(searchParameters);

        result.hits.map(
          // (hit) => (retrievedArticles[hit.document.id] = hit.document)
          (hit) => retrievedArticles.push(hit.document)
        );

        if (result.hits.length < result.found) {
          const pages = Math.ceil(result.found / result.hits.length);
          for (let page = 2; page <= pages; page++) {
            searchParameters.page = page;
            let result = await articleSearchClient
              .collections(
                `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.articles`
              )
              .documents()
              .search(searchParameters);
            result.hits.map(
              // (hit) => (retrievedArticles[hit.document.id] = hit.document)
              (hit) => retrievedArticles.push(hit.document)
            );
          }
        }
        return retrievedArticles.filter((article) => !article.dropped);
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const exportDocumentsFromCollection = useCallback(async (collection) => {
    return articleSearchClient.collections(collection).documents().export();
  }, []);

  return {
    retrieveArticlesByIDs,
    retrieveAllActiveSeasonArticleIDs,
    searchByArticleNameOrNumber,
    searchByCollectionKey,
    searchByCategoryKey,
    exportDocumentsFromCollection
  };
};

export default useArticleSearchReader;
