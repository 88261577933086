import React, { useState, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

const CoursePicker = forwardRef(
  (
    {
      className,
      courseOptions,
      defaultValue,
      value,
      onChange,
      selectionRequiredError
    },
    inputRef
  ) => {
    //HOOKS
    const { t, i18n } = useTranslation();

    //STATE
    const [parsedCourseOptions, setParsedCourseOptions] = useState(
      courseOptions?.map((courseName) => ({
        label: courseName,
        value: courseName
      }))
    );

    useEffect(() => {
      setParsedCourseOptions(
        courseOptions?.map((courseName) => ({
          label: courseName,
          value: courseName
        }))
      );
    }, [courseOptions]);

    const selectMenuCustomStyles = {
      control: (provided, { isFocused }) => ({
        ...provided,
        borderRadius: CONSTANTS.borderRadius,
        border: isFocused
          ? `1px solid ${COLORS.secondary}`
          : selectionRequiredError
          ? `1px solid ${COLORS.alertRed}`
          : `1px solid ${COLORS.secondary}`,
        boxShadow: isFocused ? null : null,
        "&:hover": {
          borderColor: isFocused ? COLORS.secondary : COLORS.secondary
        }
      }),
      menuList: (provided) => ({
        ...provided,
        backgroundColor: COLORS.tertiary,
        maxHeight: "200px",
        paddingLeft: "10px"
      }),
      group: (provided) => ({
        ...provided,
        borderBottom: `1px solid ${COLORS.secondary}`
      }),
      option: (provided, { isSelected, isHighlighted }) => ({
        ...provided,
        padding: "15px 10px",
        borderBottom: `1px solid ${COLORS.secondary}`,
        backgroundColor: isSelected
          ? COLORS.text
          : isHighlighted
          ? COLORS.secondary
          : undefined,
        color: isSelected ? COLORS.primaryInverted : COLORS.text,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: COLORS.text,
          color: COLORS.primaryInverted
        }
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: COLORS.text,
        fontSize: FONTSIZE.medium,
        padding: "8px 2px",
        margin: 0
      }),
      placeholder: (provided, state) => ({
        ...provided,
        // textTransform: "uppercase",
        fontSize: FONTSIZE.medium,
        color: COLORS.secondary,
        padding: "8px 0px",
        margin: 0
      }),
      input: (provided, state) => ({
        ...provided,
        color: COLORS.text,
        fontSize: FONTSIZE.medium,
        padding: "8px 2px",
        margin: 0
      })
    };

    const formatOptionLabel = (data) => (
      <OptionLabel>
        <span>{data.label}</span>
      </OptionLabel>
    );

    return (
      <LabeledSeasonSelect className={className}>
        <SelectLabel>{t("course_name")}</SelectLabel>
        <CreatableSelect
          inputRef={inputRef}
          placeholder={t("search_create_course")}
          isSearchable={true}
          styles={selectMenuCustomStyles}
          options={parsedCourseOptions}
          value={value}
          onChange={onChange}
          formatOptionLabel={formatOptionLabel}
          formatCreateLabel={(inputValue) =>
            `${t("create_button")}: ${inputValue}`
          }
        />
      </LabeledSeasonSelect>
    );
  }
);

export default CoursePicker;

const LabeledSeasonSelect = styled.div`
  margin: 20px 0;
`;

const SelectLabel = styled.div`
  /* text-transform: uppercase; */
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding-left: 5px;
  text-transform: uppercase;
`;

const OptionLabel = styled.div`
  /* text-transform: uppercase; */
`;
