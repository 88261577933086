import React from "react";
import styled from "styled-components/macro";
import {
  CONSTANTS,
  COLORS,
  FONTWEIGHT,
  FONTSIZE
} from "helpers/designConstants";

import LanguageDropdownMenu from "components/shared/LanguageDropdownMenu";

const BookingPublicLayout = ({
  title,
  subTitle,
  pageHeaderRight,
  children
}) => {
  return (
    <PageWrapper>
      <PageHeader>
        <LeftSideHeaderWrapper>
          <Image src='/icon-adidas-logo.svg'></Image>
          <Spacer />
          <LanguageDropdownMenu alignLeftOrRight='left' />
        </LeftSideHeaderWrapper>
        <PageTitleWrapper>
          <PageTitle>{title}</PageTitle>
          <PageSubtitle>{subTitle}</PageSubtitle>
        </PageTitleWrapper>

        {pageHeaderRight}
      </PageHeader>
      <ContentWrapper>{children}</ContentWrapper>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  width: 100%;
  max-width: ${({ isMobile }) => (isMobile ? "unset" : "1350px")};
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
`;

const PageHeader = styled.div`
  margin: 10px;
  /* height: 100px; */
  padding: 10px;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  background-color: ${COLORS.tertiary};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
`;

const LeftSideHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  height: 40px;
`;

const Spacer = styled.div`
  margin: 0 15px;
  padding: 15px 0;
  width: 1px;
  background-color: ${COLORS.text};
`;

const PageTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageTitle = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.large};
  font-weight: ${FONTWEIGHT.heavy};
`;

const PageSubtitle = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
`;

const ContentWrapper = styled.div`
  margin: 10px 10px 30px;
  overflow: hidden;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  position: relative;
`;

export default BookingPublicLayout;
