import React, { Suspense } from "react";
import styled from "styled-components/macro";
import { useImage } from "react-image";

import { ErrorBoundary } from "react-error-boundary";

const ImageComponent = ({ imageURLs, className }) => {
  return imageURLs == null ? (
    <StyledPlaceholderImg src='/emptyImagePlaceholder.png' alt='' />
  ) : (
    <ErrorBoundary
      fallbackRender={() => (
        <StyledPlaceholderImg src='/emptyImagePlaceholder.png' alt='' />
      )}
    >
      <Suspense
        fallback={
          <StyledPlaceholderImg src='/emptyImagePlaceholder.png' alt='' />
        }
      >
        <SuspenseImageComponent imageURLs={imageURLs} className={className} />
      </Suspense>
    </ErrorBoundary>
  );
};

const StyledPlaceholderImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export default ImageComponent;

//react-image component, broken out here so that we can handle empty imageURLS
const SuspenseImageComponent = ({ imageURLs, className }) => {
  const { src } = useImage({ srcList: imageURLs });

  return <img className={className} src={src} alt='' />;
};
