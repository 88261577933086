import styled from "styled-components/macro";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

import { Link } from "react-router-dom";

export const Button = styled.button`
  flex-shrink: 0;
  text-decoration: none;
  border: none;
  text-transform: uppercase;
  opacity: ${({ disabled }) => {
    return disabled && 0.3;
  }};
  background: ${({ dark, destructive, cancel, highlighted }) => {
    return (
      (destructive && COLORS.primaryInverted) ||
      (cancel && COLORS.primaryInverted) ||
      (highlighted && COLORS.green) ||
      (dark && COLORS.darkButton) ||
      COLORS.primaryInverted
    );
  }};
  color: ${({ destructive, cancel, highlighted }) => {
    return (
      (destructive && COLORS.alertRed) ||
      (highlighted && COLORS.primaryInverted) ||
      (cancel && COLORS.text) ||
      COLORS.text
    );
  }};
  border: 1px solid
    ${({ destructive, cancel, highlighted }) => {
      return (
        (destructive && COLORS.alertRed) ||
        (cancel && COLORS.text) ||
        (highlighted && COLORS.green) ||
        COLORS.text
      );
    }};
  border-radius: ${CONSTANTS.borderRadius};
  padding: 13px 12px 11px 12px;
  font-size: ${(props) =>
    props.fontsize ? FONTSIZE[props.fontsize] : FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  height: var(--height);
  width: var(--width);
  margin: var(--margin);
  transition: 0.3s;
  cursor: pointer;
  cursor: ${({ disabled }) => {
    return disabled ? "not-allowed" : "pointer";
  }};

  @media (hover: hover) {
    &:hover {
      background: ${({ disabled, destructive, cancel, highlighted }) => {
        return (
          (destructive && COLORS.alertRed) ||
          (cancel && COLORS.text) ||
          (highlighted && COLORS.green) ||
          (disabled && COLORS.primaryInverted) ||
          COLORS.text
        );
      }};
      border-color: ${({ cancel }) => {
        return cancel && COLORS.text;
      }};
      color: ${({ disabled, destructive, cancel }) => {
        return (
          (destructive && COLORS.primaryInverted) ||
          (cancel && COLORS.primaryInverted) ||
          (disabled && COLORS.text) ||
          COLORS.primaryInverted
        );
      }};
    }
  }
`;

export const MiniButton = styled.button`
  flex-shrink: 0;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  line-height: 1;
  opacity: ${({ disabled }) => {
    return disabled && 0.3;
  }};
  background: ${({
    dark,
    destructive,
    cancel,
    highlighted,
    highlightedOutline
  }) => {
    return (
      (destructive && COLORS.primaryInverted) ||
      (cancel && COLORS.primaryInverted) ||
      (highlighted && COLORS.green) ||
      (highlightedOutline && COLORS.primaryInverted) ||
      (dark && COLORS.darkButton) ||
      COLORS.primaryInverted
    );
  }};
  color: ${({ destructive, cancel, highlighted, highlightedOutline }) => {
    return (
      (destructive && COLORS.alertRed) ||
      (highlighted && COLORS.primaryInverted) ||
      (highlightedOutline && COLORS.green) ||
      (cancel && COLORS.text) ||
      COLORS.text
    );
  }};
  border: 1px solid
    ${({ destructive, cancel, highlighted, highlightedOutline }) => {
      return (
        (destructive && COLORS.alertRed) ||
        (cancel && COLORS.text) ||
        (highlighted && COLORS.green) ||
        (highlightedOutline && COLORS.green) ||
        COLORS.text
      );
    }};
  border-radius: 4px;
  padding: 5px 8px;
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.heavy};
  transition: 0.2s;
  cursor: ${({ disabled }) => {
    return disabled ? "not-allowed" : "pointer";
  }};

  @media (hover: hover) {
    &:hover {
      background: ${({
        disabled,
        destructive,
        cancel,
        highlighted,
        highlightedOutline
      }) => {
        return (
          (destructive && COLORS.alertRed) ||
          (cancel && COLORS.text) ||
          (highlighted && COLORS.green) ||
          (highlightedOutline && COLORS.green) ||
          (disabled && COLORS.primaryInverted) ||
          COLORS.text
        );
      }};
      border-color: ${({ cancel }) => {
        return cancel && COLORS.text;
      }};
      color: ${({ disabled, destructive, cancel }) => {
        return (
          (destructive && COLORS.primaryInverted) ||
          (cancel && COLORS.primaryInverted) ||
          (disabled && COLORS.text) ||
          COLORS.primaryInverted
        );
      }};
    }
  }
`;

export const StyledRouterLink = styled(Link)`
  &:link {
    color: ${COLORS.linkPrimary};
  }

  &:visited {
    color: ${COLORS.linkPrimary};
  }
`;

export const TextInput = styled.input.attrs({})`
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  padding: 10px;
  background-color: ${COLORS.primaryInverted};
  font-size: ${FONTSIZE.medium};
  width: 100%;
  color: ${COLORS.text};
  -webkit-appearance: none;

  &:focus {
    outline: none !important;
    /* border: 1px solid ${COLORS.text}; */
  }

  &::placeholder {
    opacity: 40%;
  }
`;
