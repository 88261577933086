import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "normalize.css";
import "./index.css";
import App from "./App";
import "services/i18n.js";
import reportWebVitals from "./reportWebVitals";
import Loading from "components/shared/Loading";

if (process.env.REACT_APP_USER_ENVIRONMENT !== "local") {
  Sentry.init({
    dsn: "https://de2ed0f892d44ec3825027bc17b62205@o1299279.ingest.sentry.io/6562559",
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_USER_ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    ignoreErrors: ["ResizeObserver loop limit exceeded"]
  });
}

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading isLoading={true} />}>
      <Sentry.ErrorBoundary fallback={<Loading isLoading={true} />}>
        <App />
      </Sentry.ErrorBoundary>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
