import React from "react";
import styled from "styled-components/macro";
import ScrollAreaCard from "components/shared/ScrollAreaCard";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import { useTranslation } from "react-i18next";

const BookingCard = ({ booking, ...props }) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledScrollAreaCard {...props} inProgress={!booking.isComplete}>
      <Content>
        <NamesWrapper>
          <Name>{booking.name}</Name>
          <CourseName>{booking.courseName}</CourseName>
        </NamesWrapper>
        <Season>{`${
          i18n.language === "en" ? booking.seasonName : booking.seasonName_fr
        }: ${
          i18n.language === "en"
            ? booking.seasonSiloName
            : booking.seasonSiloName_fr
        }`}</Season>
        <StatusWrapper>
          <StatusLine>{`${t("created_on")}: ${new Date(
            booking.creationDate
          ).toLocaleString(i18n.language, {
            month: "short",
            day: "numeric",
            year: "numeric"
          })}`}</StatusLine>
          {!booking.isComplete && (
            <StatusLine inProgress={!booking.isComplete}>
              {t("booking_in_progress")}
            </StatusLine>
          )}
          {Object.keys(booking.shareTracking).length === 0 ? (
            <StatusLine inProgress={booking.isComplete ? undefined : true}>
              {t("booking_unshared")}
            </StatusLine>
          ) : (
            <StatusLine inProgress={false}>
              {`${t("last_shared")}: ${new Date(
                Object.values(booking.shareTracking).sort((a, b) =>
                  a.lastSent < b.lastSent ? 1 : a.lastSent > b.lastSent ? -1 : 0
                )[0].lastSent
              ).toLocaleString(i18n.language, {
                month: "short",
                day: "numeric",
                year: "numeric"
              })}`}
            </StatusLine>
          )}
          {booking.repOrdersInProgress?.length > 0 && (
            <StatusLine inProgress={booking.isComplete ? undefined : true}>
              {t("num_rep_orders_open_withCount", {
                count: booking.repOrdersInProgress.length
              })}
            </StatusLine>
          )}
          {booking.buyerOrdersInProgress?.length > 0 && (
            <StatusLine inProgress={booking.isComplete ? undefined : true}>
              {t("num_buyer_orders_open_withCount", {
                count: booking.buyerOrdersInProgress.length
              })}
            </StatusLine>
          )}
          {(booking.repOrdersComplete?.length > 0 ||
            booking.buyerOrdersComplete?.length > 0) && (
            <StatusLine inProgress={false}>
              {t("num_orders_complete_withCount", {
                count:
                  (booking.repOrdersComplete?.length || 0) +
                  (booking.buyerOrdersComplete?.length || 0)
              })}
            </StatusLine>
          )}
        </StatusWrapper>
      </Content>
    </StyledScrollAreaCard>
  );
};

const StyledScrollAreaCard = styled(ScrollAreaCard)`
  border: ${({ inProgress }) =>
    inProgress
      ? `1px solid ${COLORS.alertOrange}`
      : `1px solid ${COLORS.secondary}`};
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${COLORS.text};
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
`;

const NamesWrapper = styled.div`
  /* flex-grow: 1; */
  padding-bottom: 10px;
  border-bottom: 1px solid ${COLORS.secondary};
`;

const Name = styled.div`
  font-size: ${FONTSIZE.medium};
  text-transform: uppercase;
  font-weight: ${FONTWEIGHT.heavy};
`;

const CourseName = styled.div`
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};

  /* margin-bottom: 10px; */
`;

const Season = styled.div`
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
`;

const StatusWrapper = styled.div`
  flex-grow: 1;
  padding: 5px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const StatusLine = styled.div`
  padding: 2px 0;
  text-transform: uppercase;
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${({ inProgress }) =>
    inProgress
      ? COLORS.alertOrange
      : inProgress === false
      ? COLORS.green
      : "inherit"};
`;

export default BookingCard;
