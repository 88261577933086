import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import Modal from "components/shared/modal/Modal";

import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import ImageComponent from "components/shared/ImageComponent";

const InventoryModal = ({ show, onClose, article }) => {
  //HOOKS
  const { t, i18n } = useTranslation();

  return (
    <Modal show={show} onClose={onClose} title={t("inventory_title")}>
      <Container>
        <ArticleDetailsWrapper>
          <ImageWrapper>
            <Image
              imageURLs={
                article?.imageURLs.filter(
                  (url) => url.includes("_MAIN_") && url.includes("_thumb")
                )[0]
              }
            />
          </ImageWrapper>
          <InfoWrapper>
            <ArticleName>{article?.articleName}</ArticleName>
            <ArticleNumber>{article?.articleNumber}</ArticleNumber>
            <ArticleColor>
              {i18n.language === "en" ? article?.color : article?.color_fr}
            </ArticleColor>
          </InfoWrapper>
        </ArticleDetailsWrapper>
        <SizeInputContainer>
          <LabelBox>
            {article?.inventory.incoming == null ? (
              // <InventoryDateTitle>{`${new Date().toLocaleString(i18n.language, {
              //   dateStyle: "medium"
              // })}`}</InventoryDateTitle>
              <InventoryDateTitle>NO DATA</InventoryDateTitle>
            ) : (
              article?.inventory.incoming?.map((dateData, index) => (
                <InventoryDateTitle key={dateData.date} row={index}>
                  {new Date(dateData.date).toLocaleString(i18n.language, {
                    dateStyle: "medium"
                  })}
                </InventoryDateTitle>
              ))
            )}
            <InventoryTotalTitle>{t("total")}</InventoryTotalTitle>
          </LabelBox>
          {article?.userFriendlySizes.map((friendlySize, index) => {
            const databaseSize = article.sizes[index];
            return (
              <SizeContainer key={friendlySize}>
                <SizeTitle>{friendlySize}</SizeTitle>

                {article?.inventory.incoming == null ? (
                  <InventoryQty>-</InventoryQty>
                ) : (
                  article?.inventory.incoming?.map((dateData, index) => (
                    <InventoryQty key={dateData.date} row={index}>
                      {dateData.sizes[databaseSize] != null
                        ? dateData.sizes[databaseSize]
                        : "-"}
                    </InventoryQty>
                  ))
                )}
                <InventoryQtyTotal>
                  {article?.inventory.overallTotals == null
                    ? "-"
                    : article?.inventory.overallTotals?.sizes[databaseSize] !=
                      null
                    ? article?.inventory.overallTotals?.sizes[databaseSize]
                    : "-"}
                </InventoryQtyTotal>
              </SizeContainer>
            );
          })}
        </SizeInputContainer>
        <LastUpdateWrapper>
          {`${t("inventory_updated")}: ${new Date(
            article?.lastInventoryUpdate
          ).toLocaleString(i18n.language, {
            dateStyle: "medium",
            timeStyle: "short"
          })}`}
        </LastUpdateWrapper>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  max-width: 90vw;
  max-height: 65vh;
  overflow: auto;
  display: grid;
  grid-template-rows: max-content max-content max-content;
  align-items: center;
`;

const ArticleDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
  margin-bottom: 5px;
`;

const ImageWrapper = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
`;

const Image = styled(ImageComponent)`
  max-width: 30vw;
  max-height: 30vh;
  padding: ${({ placeholder }) => placeholder && "20px"};
`;

const InfoWrapper = styled.div`
  /* width: 100%; */
  height: 100%;
  padding: 5px;
  /* flex-grow: 2; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
`;

const ArticleName = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleNumber = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleColor = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  word-wrap: break-word;
`;

const SizeInputContainer = styled.div`
  padding: 10px;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: stretch;
`;

const LabelBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
`;

const InventoryDateTitle = styled.div`
  width: 100%;
  padding: 3px;
  padding-right: 5px;
  text-align: right;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  background-color: ${({ row }) => row % 2 === 0 && COLORS.tertiary};
  white-space: nowrap;
`;

const InventoryTotalTitle = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.text};
  padding: 3px;
  padding-right: 5px;
  text-align: right;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.heavy};
  background-color: ${COLORS.secondary};
  text-transform: uppercase;
`;

const SizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 52px;
  /* margin: 5px; */
  flex-shrink: 0;
`;

const SizeTitle = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid ${COLORS.secondary};
`;

const InventoryQty = styled.div`
  width: 100%;
  padding: 3px;
  text-align: center;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  background-color: ${({ row }) => row % 2 === 0 && COLORS.tertiary};
`;

const InventoryQtyTotal = styled.div`
  width: 100%;
  /* margin-top: 3px; */
  border-top: 1px solid ${COLORS.text};
  padding: 3px;
  text-align: center;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.heavy};
  background-color: ${COLORS.secondary};
`;

const LastUpdateWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  justify-content: center;
  display: flex;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.xsmall};
  text-transform: uppercase;
`;

export default InventoryModal;
