import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import SearchBar from "components/shared/SearchBar";
import ScrollArea from "components/shared/ScrollArea";
import CreateEditBookingModal from "components/shared/createEditBookingModal/CreateEditBookingModal";
import {
  CONSTANTS,
  COLORS,
  FONTWEIGHT,
  FONTSIZE
} from "helpers/designConstants";
import { Button, MiniButton } from "components/shared/standardElements";
import BookingCard from "./BookingCard";

import { useDatabaseReader } from "database/useDatabaseReader";
import useBookingWriter from "database/bookings/useBookingWriter";
import useBookingSearchReader from "database/bookings/useBookingSearchReader";
import useNavigation from "helpers/useNavigation";
import Loading from "components/shared/Loading";
import { debounce } from "lodash";

const BookingsTab = () => {
  //HOOKS
  const { t } = useTranslation();
  const { setSelectedBookingID, bookingsSearchAPIKey } = useDatabaseReader();
  const { createNewBooking } = useBookingWriter();
  const { searchForBooking } = useBookingSearchReader();
  const [navigateForward] = useNavigation();

  //STATE
  const [showCreateBookingModal, setShowCreateBookingModal] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [filterToggle, setFilterToggle] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (bookingsSearchAPIKey == null) return;
    const filter =
      filterToggle === "all"
        ? undefined
        : filterToggle === "inProgress"
        ? "isComplete: false"
        : undefined;

    const search = searchTerm.length > 0 ? searchTerm : "*";
    searchForBooking(search, filter).then((results) => {
      setFilteredBookings(
        results.sort((a, b) =>
          a.creationDate < b.creationDate
            ? 1
            : a.creationDate > b.creationDate
            ? -1
            : 0
        )
      );
    });
  }, [searchForBooking, bookingsSearchAPIKey, filterToggle, searchTerm]);

  useEffect(() => {
    if (filterToggle === "inProgress") {
      setFilteredBookings((prev) =>
        prev.filter((booking) => !booking.isComplete)
      );
    } else {
    }
  }, [filterToggle]);

  //METHODS
  const onSearchBarChanged = debounce(async (searchTerm) => {
    setSearchTerm(searchTerm);
  }, 350);

  const onSelectBooking = (bookingID) => {
    setSelectedBookingID(bookingID);
    navigateForward("/reps/booking", { bookingID: bookingID });
  };

  const onCreateBooking = async (data) => {
    setShowCreateBookingModal(false);
    setIsLoading(true);
    const bookingID = await createNewBooking(data);
    setIsLoading(false);
    onSelectBooking(bookingID);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container>
        <Header>
          <SearchSection>
            <StyledSearchBar
              placeholder={`${t("search")}...`}
              searchTermChanged={onSearchBarChanged}
            />
            <StyledButton
              primary
              fontsize='small'
              onClick={() => setShowCreateBookingModal(true)}
            >
              {`+ ${t("new_booking")}`}
            </StyledButton>
          </SearchSection>
          <FilterSection>
            {`${t("filter")}:`}
            <MiniButton
              highlighted={filterToggle === "all"}
              onClick={() => setFilterToggle("all")}
            >
              {t("show_all_button")}
            </MiniButton>
            <MiniButton
              highlighted={filterToggle === "inProgress"}
              onClick={() => setFilterToggle("inProgress")}
            >
              {t("in_progress_button")}
            </MiniButton>
          </FilterSection>
        </Header>
        <StyledScrollArea>
          {filteredBookings.map((booking) => (
            <BookingCard
              key={booking.id}
              booking={booking}
              onClick={() => onSelectBooking(booking.id)}
            />
          ))}
        </StyledScrollArea>
      </Container>
      <CreateEditBookingModal
        title='Create New Booking'
        show={showCreateBookingModal}
        onClose={() => setShowCreateBookingModal(false)}
        onCreateUpdateBooking={onCreateBooking}
        maxHeight={"90vh"}
      />
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: ${CONSTANTS.borderRadius} ${CONSTANTS.borderRadius} 0 0;
  border-bottom: 2px solid ${COLORS.secondary};
  background: ${COLORS.tertiary};
  box-shadow: 0px 2px 5px ${COLORS.secondary};
  z-index: 1;
`;

const SearchSection = styled.div`
  display: flex;
  align-items: center;
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.small};
  text-transform: uppercase;
  padding: 10px 5px 5px;

  button {
    margin-left: 10px;
  }
`;

const StyledSearchBar = styled(SearchBar)`
  min-width: 325px;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  text-transform: uppercase;
`;

const StyledScrollArea = styled(ScrollArea)`
  grid-template-columns: repeat(auto-fit, minmax(400px, 0.3333fr));
`;

export default BookingsTab;
