import CreateEditBookingModal from "components/shared/createEditBookingModal/CreateEditBookingModal";
import { useTranslation } from "react-i18next";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import React, { useState } from "react";
import styled from "styled-components/macro";
import useBookingWriter from "database/bookings/useBookingWriter";

import DashboardCard from "./DashboardCard";
import { Button } from "components/shared/standardElements";

const DetailsCard = ({ className, booking, orders }) => {
  const { t, i18n } = useTranslation();
  const { updateBookingDetails, deleteBooking } = useBookingWriter();

  const [showEditModal, setShowEditModal] = useState(false);

  const onUpdateBooking = async (data) => {
    await updateBookingDetails(data);
    setShowEditModal(false);
  };

  return (
    <DashboardCard
      className={className}
      title={t("details")}
      shouldShowEditButton
      editButtonTitle={t("edit_button")}
      onClickedEditButton={() => setShowEditModal(true)}
    >
      <Wrapper>
        <BookingInfoWrapper>
          <InfoBlock>
            <Label>{t("name_title")}</Label>
            <InfoText>{booking?.name}</InfoText>
          </InfoBlock>
          <InfoBlock>
            <Label>{t("course_name")}</Label>
            <InfoText>{booking?.courseName}</InfoText>
          </InfoBlock>
          <InfoBlock>
            <Label>{t("season")}</Label>
            <InfoText>
              {i18n.language === "en"
                ? booking?.seasonName
                : booking?.seasonName_fr}
            </InfoText>
          </InfoBlock>
          <InfoBlock>
            <Label>{t("preview_page_price")}</Label>
            <InfoText>
              {booking?.priceToShowPreview === "MSRP"
                ? t("MSRP")
                : booking?.priceToShowPreview === "WHSL"
                ? t("WHSL")
                : booking?.priceToShowPreview === "MSRP+WHSL"
                ? `${t("MSRP")} + ${t("WHSL")}`
                : t("none")}
            </InfoText>
          </InfoBlock>
          <InfoBlock>
            <Label>{t("order_page_price")}</Label>
            <InfoText>
              {booking?.priceToShowOrdering === "MSRP"
                ? t("MSRP")
                : booking?.priceToShowOrdering === "WHSL"
                ? t("WHSL")
                : booking?.priceToShowOrdering === "MSRP+WHSL"
                ? `${t("MSRP")} + ${t("WHSL")}`
                : t("none")}
            </InfoText>
          </InfoBlock>
          <InfoBlock>
            <Label>{t("sort")}</Label>
            <InfoText>
              {booking?.sortOrder === "collection"
                ? t("collection")
                : t("category")}
            </InfoText>
          </InfoBlock>
        </BookingInfoWrapper>
        {/* <DeleteBookingWrapper>
          <Button destructive onClick={() => deleteBooking(booking.id)}>
            DELETE BOOKING
          </Button>
        </DeleteBookingWrapper> */}
      </Wrapper>
      <CreateEditBookingModal
        show={showEditModal}
        onClose={() => setShowEditModal(false)}
        title='Edit Details'
        booking={booking}
        onCreateUpdateBooking={onUpdateBooking}
        seasonSelectionIsDisabled={
          booking?.articleIDs.length > 0 ||
          (orders != null && Object.keys(orders).length > 0)
        }
      />
    </DashboardCard>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BookingInfoWrapper = styled.div``;

const DeleteBookingWrapper = styled.div`
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${COLORS.secondary};
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

const Label = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  text-transform: uppercase;
`;
const InfoText = styled.div`
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
`;

export default DetailsCard;
