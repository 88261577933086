import { useAuth } from "database/useAuth";
import { Navigate, Outlet } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const PurchaserRoutes = ({ children }) => {
  const { user, authContextLoaded } = useAuth();
  const [searchParams] = useSearchParams();

  return user?.type === "bookingPurchaser" ? (
    <Outlet>{children}</Outlet>
  ) : (
    authContextLoaded && (
      <Navigate
        to={`/ext/preview?bid=${Object.fromEntries([...searchParams]).bid}`}
        replace
      />
    )
  );
};

export default PurchaserRoutes;
