import React, {
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle
} from "react";

import styled from "styled-components/macro";
import { COLORS, CONSTANTS, FONTSIZE } from "helpers/designConstants";

//MaterialUI Icon Imports
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const SearchBar = forwardRef(
  ({ size, placeholder, searchTermChanged, className }, ref) => {
    const [searchBarIsEmpty, setSearchBarIsEmpty] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    const innerOnChange = (e) => {
      setSearchTerm(e.target.value);
      if (e.target.value.length > 0) {
        setSearchBarIsEmpty(false);
      } else {
        setSearchBarIsEmpty(true);
      }
      searchTermChanged(e.target.value);
    };

    //Using a callback so that this can be wrapped in the useImperativeHandle hook
    const clearSearchBar = useCallback(() => {
      setSearchTerm("");
      searchTermChanged("");
      setSearchBarIsEmpty(true);
    }, [searchTermChanged]);

    //This exposes the clear method so that the parent can clear the search bar
    //Works in conjuction with forwardRef on this component itself
    useImperativeHandle(
      ref,
      () => ({
        clearSearch: clearSearchBar,
        searchTerm: searchTerm
      }),
      [clearSearchBar, searchTerm]
    );

    return (
      <Wrapper className={className}>
        <LeadingCap>
          <SearchIcon />
        </LeadingCap>
        <SearchInput
          ref={ref}
          placeholder={placeholder}
          type='text'
          onChange={innerOnChange}
          value={searchTerm}
        ></SearchInput>
        <TrailingCap>
          {searchBarIsEmpty ? (
            ""
          ) : (
            <CancelOutlinedIcon onClick={clearSearchBar} />
          )}
        </TrailingCap>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const LeadingCap = styled.div`
  border-top-left-radius: ${CONSTANTS.borderRadius};
  border-bottom-left-radius: ${CONSTANTS.borderRadius};
  border-top: 1px solid ${COLORS.text};
  border-left: 1px solid ${COLORS.text};
  border-bottom: 1px solid ${COLORS.text};
  font-size: ${FONTSIZE.xlarge};
  color: ${COLORS.text};
  padding: 0 5px;
  background-color: ${COLORS.primaryInverted};
`;

const SearchInput = styled.input`
  flex-grow: 1;
  border: none;
  //FIX FOR IPAD
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  //
  border-top: 1px solid ${COLORS.text};
  border-bottom: 1px solid ${COLORS.text};
  padding: 10px;
  font-size: ${FONTSIZE.medium};
  color: ${COLORS.text};

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 40%;
  }
`;

const TrailingCap = styled.div`
  border-top-right-radius: ${CONSTANTS.borderRadius};
  border-bottom-right-radius: ${CONSTANTS.borderRadius};
  border-top: 1px solid ${COLORS.text};
  border-right: 1px solid ${COLORS.text};
  border-bottom: 1px solid ${COLORS.text};
  font-size: ${FONTSIZE.xlarge};
  padding: 0 5px;
  background-color: ${COLORS.primaryInverted};
  color: ${COLORS.text};
`;

export default SearchBar;
