import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback
} from "react";
import * as Sentry from "@sentry/react";
import useLocalStorage from "helpers/useLocalStorage";
import { useAuth } from "database/useAuth";
import useArticleSearchReader from "./articles/useArticleSearchReader";
import sizeof from "firestore-size";

import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  getDoc
} from "firebase/firestore";

import { xor } from "lodash";
import { firebaseRandomID } from "helpers/helperFunctions";

//Create Context
const DatabaseReaderContext = createContext();

//Context Provider
const DatabaseReaderContextProvider = ({ children }) => {
  //Create a device id for tracking when user is editing an order
  const [deviceID, setDeviceID] = useLocalStorage("deviceID", null);
  if (deviceID == null) setDeviceID(firebaseRandomID());

  //Internal Variables
  const { user, authContextLoaded } = useAuth();
  const db = getFirestore();
  const { retrieveArticlesByIDs } = useArticleSearchReader();
  const [currentlySelectedArticles, setCurrentlySelectedArticles] = useState();

  //External state
  const [contactsDocID, setContactsDocID] = useState();
  const [selectedBookingID, setSelectedBookingID] = useState();
  const [selectedBooking, setSelectedBooking] = useState();
  const [
    selectedBookingPurchaserPassword,
    setSelectedBookingPurchaserPassword
  ] = useState();
  const [orders, setOrders] = useState();
  // const [selectedOrderID, setSelectedOrderID] = useState();
  const [bookingsSearchAPIKey, setBookingsSearchAPIKey] = useState();
  const [cataloguesSearchAPIKey, setCataloguesSearchAPIKey] = useState();
  const [contacts, setContacts] = useState();

  //If selectedBookingID changes, reset the selected articles and the selectedOrderID
  useEffect(() => {
    setCurrentlySelectedArticles();
    // setSelectedOrderID();
  }, [selectedBookingID]);

  //Monitor UserDoc
  //get Typesense Search Key for Bookings
  useEffect(() => {
    if (user == null || user.type !== "adidasRep" || !authContextLoaded) return;
    const unsubscribe = onSnapshot(
      doc(db, "users", user.uid),
      (doc) => {
        const userDoc = doc.data();
        if (doc.exists()) {
          setContactsDocID(userDoc.contactsDocID);
          setBookingsSearchAPIKey(userDoc.bookingsSearchAPIKey);
          setCataloguesSearchAPIKey(userDoc.cataloguesSearchAPIKey);
        } else {
          Sentry.captureMessage(
            `users/${user.uid} does not exist, uid: ${user.uid} in useDatabaseReader listener for userDoc`
          );
        }
      },
      (error) => {
        Sentry.captureException(error);
      }
    );
    return () => unsubscribe();
  }, [user, db, authContextLoaded]);

  //Monitor User's contacts Doc
  useEffect(() => {
    if (
      db == null ||
      user == null ||
      !authContextLoaded ||
      contactsDocID == null ||
      user.type !== "adidasRep"
    )
      return;
    const unsubscribe = onSnapshot(
      doc(db, "bookingContacts", contactsDocID),
      (doc) => {
        if (doc.exists()) {
          let dbContacts = {};

          Object.entries(doc.data().contacts).forEach(
            ([contactID, contact]) =>
              (dbContacts[contactID] = {
                ...contact,
                id: contactID
              })
          );
          // contacts.sort((a, b) =>
          //   a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          // );

          let courses = [
            ...new Set(
              Object.values(dbContacts).map((contact) => contact.courseName)
            )
          ];
          courses = [...new Set([...courses, ...doc.data().courseNames])];

          setContacts({
            contacts: dbContacts,
            courseNames: courses
          });
        } else {
          Sentry.captureMessage(
            `bookingContacts/${contactsDocID} does not exist in useDatabaseReader listener for user's contacts doc`
          );
        }
      }
    );
    return () => unsubscribe();
  }, [authContextLoaded, contactsDocID, db, user]);

  //Listen to Selected Booking Doc
  useEffect(() => {
    if (db == null || selectedBookingID == null) return;
    const unsubscribe = onSnapshot(
      doc(db, "bookings", selectedBookingID),
      (doc) => {
        if (doc.exists()) {
          let booking = doc.data();
          booking.id = doc.id;

          //Parse Firestore Timestamps on creationDate to Javascript dates
          booking.creationDate = booking.creationDate.toDate();

          //Parse Firestore Timestamps on shareTracking to Javascript dates
          Object.entries(booking.shareTracking).forEach(
            ([contactID, contact]) => {
              booking.shareTracking[contactID] = {
                lastSent: contact.lastSent?.toDate()
              };
            }
          );

          setSelectedBooking(booking);
        } else {
          Sentry.captureMessage(
            `bookings/${selectedBookingID} does not exist... in useDatabaseReader listener for selected booking`
          );
        }
      }
    );

    return () => unsubscribe();
  }, [db, selectedBookingID]);

  //Get Private Booking Data if user is adidasRep
  useEffect(() => {
    if (db == null || selectedBookingID == null) return;
    const unsubscribe = onSnapshot(
      doc(db, "bookings", selectedBookingID, "private", "privateData"),
      (doc) => {
        doc.exists() &&
          setSelectedBookingPurchaserPassword(doc.data().purchaserPassword);
      }
    );
    return () => unsubscribe();
  }, [db, selectedBookingID]);

  // Hyrate Articles for Selected Booking
  //This only happens on first setup or when the selected booking changes
  useEffect(() => {
    if (
      selectedBooking?.id == null ||
      selectedBooking?.articleIDs == null ||
      selectedBookingID == null
    )
      return;
    if (
      selectedBooking.id === selectedBookingID &&
      currentlySelectedArticles == null
    ) {
      if (selectedBooking.articleIDs.length === 0) {
        setCurrentlySelectedArticles([]);
        setSelectedBooking((prev) => ({ ...prev, articles: [] }));
      } else {
        retrieveArticlesByIDs(selectedBooking.articleIDs).then(
          (bookingArticles) => {
            setCurrentlySelectedArticles(Object.values(bookingArticles));
            setSelectedBooking((prev) => ({
              ...prev,
              articles: Object.values(bookingArticles)
            }));
          }
        );
      }
    }
  }, [
    currentlySelectedArticles,
    retrieveArticlesByIDs,
    selectedBooking?.articleIDs,
    selectedBooking?.id,
    selectedBookingID
  ]);

  //This sets the articles on the booking after it has been updated in firebase
  //It comes back raw with no article data attached other than the article ids
  useEffect(() => {
    if (
      selectedBooking?.articles == null &&
      currentlySelectedArticles != null
    ) {
      setSelectedBooking((prev) => ({
        ...prev,
        articles: currentlySelectedArticles
      }));
    }
  }, [currentlySelectedArticles, selectedBooking?.articles]);

  //Update methods to track added and removed articles from booking
  //This is done to limit the number of times we have to hit typesense for search
  const updateLocalBookingArticles = (articles) => {
    setCurrentlySelectedArticles(articles);
    let booking = {
      ...selectedBooking,
      articles: articles
    };
    setSelectedBooking(booking);
  };

  //Track Orders Associated with Booking
  useEffect(() => {
    if (db == null || selectedBookingID == null) return;
    const unsubscribe = onSnapshot(
      collection(db, "bookings", selectedBookingID, "orders"),
      (querySnapshot) => {
        let dbOrders = {};
        querySnapshot.forEach((doc) => {
          let order = doc.data();
          order.id = doc.id;

          //Parse Firestore Timestamps on creationDate & submissionDate to Javascript dates
          order.creationDate = order.creationDate.toDate();
          if (order.completionDate != null) {
            order.completionDate = order.completionDate.toDate();
          }

          dbOrders[doc.id] = order;
        });
        const openRepManagedOrders = Object.values(dbOrders).filter(
          (order) => order.isRepManaged && order.completionDate == null
        );
        // if (openRepManagedOrders.length === 1 && selectedOrderID == null) {
        //   setSelectedOrderID(openRepManagedOrders[0].id);
        // }

        setOrders(dbOrders);
      }
    );
    return () => unsubscribe();
  }, [db, selectedBookingID]);

  const getCatalogueByID = useCallback(async (catalogueID) => {
    const catalogueDoc = await getDoc(
      doc(getFirestore(), "catalogues", catalogueID)
    );
    let catalogue = catalogueDoc.data();
    catalogue.id = catalogueDoc.id;
    return catalogue;
  }, []);

  //Share variables and methods to children through the provider
  return (
    <DatabaseReaderContext.Provider
      value={{
        deviceID,
        bookingsSearchAPIKey,
        cataloguesSearchAPIKey,
        contacts,
        contactsDocID,
        selectedBooking,
        setSelectedBookingID,
        selectedBookingPurchaserPassword,
        updateLocalBookingArticles,
        orders,
        getCatalogueByID
        // selectedOrderID,
        // setSelectedOrderID
      }}
    >
      {children}
    </DatabaseReaderContext.Provider>
  );
};

export default DatabaseReaderContextProvider;

//CREATE A HOOK TO BE USED ON A CONSUMER COMPONENT TO READ AUTH VARIABLES AND METHODS
export const useDatabaseReader = () => useContext(DatabaseReaderContext);
