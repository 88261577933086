import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useArticleReader } from "database/articles/useArticleReader";
import { useDatabaseReader } from "database/useDatabaseReader";
import Modal from "components/shared/modal/Modal";
import { useForm, Controller } from "react-hook-form";
import LabelledTextInput from "components/shared/LabelledTextInput";

import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";
import SeasonPicker from "./SeasonPicker";
import CoursePicker from "./CoursePicker";
import Checkbox from "components/shared/Checkbox";
import { Button } from "components/shared/standardElements";

const CreateEditBookingModal = ({
  title,
  show,
  onClose,
  onCreateUpdateBooking,
  booking,
  seasonSelectionIsDisabled,
  ...rest
}) => {
  //HOOKS
  const { t } = useTranslation();
  const { activeSeasons, schemas } = useArticleReader();
  const { contacts } = useDatabaseReader();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm({
    defaultValues: {
      priceToShowPreview: booking?.priceToShowPreview || "MSRP",
      priceToShowOrdering: booking?.priceToShowOrdering || "MSRP",
      sortOrder: booking?.sortOrder || "collection"
    }
  });

  const watchPricePreviewSelection = watch("priceToShowPreview");
  const watchPriceOrderingSelection = watch("priceToShowOrdering");
  const watchSortOrderSelection = watch("sortOrder");

  const onSubmitForm = async (data) => {
    data = { ...data, courseName: data.courseName.value };
    onCreateUpdateBooking(data);
  };

  return (
    <Modal
      title={t("new_booking")}
      show={show}
      onClose={onClose}
      disableCloseOnOutsideClick
      maxHeight={"90vh"}
      {...rest}
    >
      <Container>
        <StyledForm onSubmit={handleSubmit(onSubmitForm)}>
          <StyledLabelledTextInput
            shouldAutoFocus
            labelText={t("booking_name")}
            type='text'
            name='bookingName'
            id='bookingName'
            placeholder='Booking Name'
            defaultValue={booking?.name}
            {...register("bookingName", { required: true })}
            error={errors?.bookingName}
          />
          {errors?.bookingName?.type === "required" && (
            <StyledInputErrorMessage>
              You must provide a name for the booking
            </StyledInputErrorMessage>
          )}
          <Controller
            control={control}
            name='courseName'
            defaultValue={
              booking != null
                ? {
                    label: booking?.courseName,
                    value: booking?.courseName
                  }
                : null
            }
            render={({ field }) => (
              <StyledCoursePicker
                courseOptions={contacts.courseNames}
                {...field}
                selectionRequiredError={errors?.courseName?.type === "required"}
              />
            )}
            rules={{ required: true }}
          />
          <Controller
            control={control}
            name='seasonOptions'
            defaultValue={
              booking != null
                ? {
                    seasonKey: booking?.seasonKey,
                    seasonName: booking?.seasonName,
                    seasonName_fr: booking?.seasonName_fr,
                    siloKey: booking?.seasonSiloKey,
                    siloName: booking?.seasonSiloName,
                    siloName_fr: booking?.seasonSiloName_fr
                  }
                : null
            }
            render={({ field }) => (
              <StyledSeasonPicker
                isDisabled={seasonSelectionIsDisabled}
                activeSeasons={activeSeasons}
                schemas={schemas}
                {...field}
                selectionRequiredError={
                  errors?.seasonOptions?.type === "required"
                }
              />
            )}
            rules={{ required: true }}
          />
          <PricingWrapper>
            <PricingTitleWrapper>
              <PricingTitle>{t("show_price")}</PricingTitle>
            </PricingTitleWrapper>
            <PricingInnerContainer>
              <InnerContainerTitle>{t("preview_page")}</InnerContainerTitle>
              <StyledCheckbox
                color={COLORS.text}
                label={t("MSRP")}
                selected={watchPricePreviewSelection === "MSRP"}
                type='radio'
                id='radio-preview-msrp'
                value='MSRP'
                {...register("priceToShowPreview")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={t("WHSL")}
                selected={watchPricePreviewSelection === "WHSL"}
                id='radio-preview-whsl'
                type='radio'
                value='WHSL'
                {...register("priceToShowPreview")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={`${t("MSRP")} + ${t("WHSL")}`}
                selected={watchPricePreviewSelection === "MSRP+WHSL"}
                id='radio-preview-msrp+whsl'
                type='radio'
                value='MSRP+WHSL'
                {...register("priceToShowPreview")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={t("none")}
                selected={watchPricePreviewSelection === "NONE"}
                id='radio-preview-none'
                type='radio'
                value='NONE'
                {...register("priceToShowPreview")}
              />
            </PricingInnerContainer>
            <Divider />
            <PricingInnerContainer>
              <InnerContainerTitle>{t("ordering_page")}</InnerContainerTitle>
              <StyledCheckbox
                color={COLORS.text}
                label={t("MSRP")}
                selected={watchPriceOrderingSelection === "MSRP"}
                type='radio'
                id='radio-ordering-msrp'
                value='MSRP'
                {...register("priceToShowOrdering")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={t("WHSL")}
                selected={watchPriceOrderingSelection === "WHSL"}
                id='radio-ordering-whsl'
                type='radio'
                value='WHSL'
                {...register("priceToShowOrdering")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={`${t("MSRP")} + ${t("WHSL")}`}
                selected={watchPriceOrderingSelection === "MSRP+WHSL"}
                id='radio-ordering-msrp+whsl'
                type='radio'
                value='MSRP+WHSL'
                {...register("priceToShowOrdering")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={t("none")}
                selected={watchPriceOrderingSelection === "NONE"}
                id='radio-ordering-none'
                type='radio'
                value='NONE'
                {...register("priceToShowOrdering")}
              />
            </PricingInnerContainer>
          </PricingWrapper>
          <SortOrderWrapper>
            <SortOrderTitleWrapper>
              <SortOrderTitle>{t("sort")}</SortOrderTitle>
            </SortOrderTitleWrapper>
            <SortOrderInnerContainer>
              {" "}
              <StyledCheckbox
                color={COLORS.text}
                label={t("collection")}
                selected={watchSortOrderSelection === "collection"}
                id='radio-collection'
                type='radio'
                value='collection'
                {...register("sortOrder")}
              />
              <StyledCheckbox
                color={COLORS.text}
                label={t("category")}
                selected={watchSortOrderSelection === "category"}
                id='radio-category'
                type='radio'
                value='category'
                {...register("sortOrder")}
              />
            </SortOrderInnerContainer>
          </SortOrderWrapper>
          <Button>
            {booking == null ? t("create_button") : t("update_button")}
          </Button>
        </StyledForm>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  min-width: 350px;
  height: 100%;
  padding: 5px;
  /* max-height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLabelledTextInput = styled(LabelledTextInput)`
  input {
    border-color: ${({ error }) => error && COLORS.alertRed};
  }
`;

const StyledInputErrorMessage = styled.p`
  margin-top: 0px;
  padding: 5px;
  color: ${COLORS.alertRed};
  font-size: ${FONTSIZE.xsmall};
  align-self: flex-start;
`;

const StyledSeasonPicker = styled(SeasonPicker)`
  width: 100%;
`;

const StyledCoursePicker = styled(CoursePicker)`
  width: 100%;
`;

const PricingWrapper = styled.div`
  margin: 0px 0 10px;
  position: relative;
  display: flex;
  /* flex-direction: column; */

  align-items: stretch;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
`;

const PricingTitleWrapper = styled.div`
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const PricingTitle = styled.div`
  background: ${COLORS.primaryInverted};
  padding: 0 5px;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
`;

const PricingInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 40px;
`;

const InnerContainerTitle = styled.div`
  padding: 0 5px 10px;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  text-decoration: underline;
  text-transform: uppercase;
`;

const Divider = styled.div`
  width: 1px;
  margin: 30px 0;
  background-color: ${COLORS.secondary};
`;

const StyledCheckbox = styled(Checkbox)`
  text-transform: uppercase;
  color: ${COLORS.text};
  padding: 8px 0;
`;

const SortOrderWrapper = styled.div`
  margin: 10px 0 50px;
  width: 100%;
  position: relative;
  display: flex;
  /* flex-direction: column; */

  align-items: stretch;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
`;

const SortOrderTitleWrapper = styled.div`
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const SortOrderTitle = styled.div`
  background: ${COLORS.primaryInverted};
  padding: 0 5px;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;
`;

const SortOrderInnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 40px;
`;
export default CreateEditBookingModal;
