import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import ProductPickerHeader from "./productPicker/ProductPickerHeader";
import ProductPickerScrollArea from "./productPicker/ProductPickerScrollArea";
import { debounce } from "lodash";
import { useArticleReader } from "database/articles/useArticleReader";
import { useDatabaseReader } from "database/useDatabaseReader";
import useArticleSearchReader from "database/articles/useArticleSearchReader";
import useBookingWriter from "database/bookings/useBookingWriter";
import useOrderWriter from "database/orders/useOrderWriter";
import { useForm } from "react-hook-form";
import Loading from "components/shared/Loading";
import CartModal from "./cartModal/CartModal";
import UserNoticeModal from "components/shared/UserNoticeModal";
import { netPrice } from "helpers/helperFunctions";
import { calculateOrderSummaryAndOrderQtys } from "helpers/calculateOrderSummaryAndOrderQtys";
import ConfirmModal from "components/shared/ConfirmModal";

const ArticlesTab = ({
  booking,
  updateLocalBookingArticles,
  selectedOrderID,
  setSelectedOrderID
}) => {
  const { t } = useTranslation();

  const searchBarRef = useRef();
  const { overallCategories } = useArticleReader();
  const { orders } = useDatabaseReader();
  const {
    searchByArticleNameOrNumber,
    searchByCollectionKey,
    searchByCategoryKey,
    retrieveArticlesByIDs
  } = useArticleSearchReader();
  const { updateRemoteBookingArticleIDs } = useBookingWriter();

  const { updateDBOrderQtys } = useOrderWriter();

  const { control, watch, getValues, setValue, reset } = useForm({});

  const [showCartModal, setShowCartModal] = useState(false);
  const [userNoticeMessage, setUserNoticeMessage] = useState();
  const [articleSort, setArticleSort] = useState("collection");
  const [searchResults, setSearchResults] = useState();
  const [initialSelectedArticles, setInitialSelectedArticles] = useState();
  const [selectedArticles, setSelectedArticles] = useState();
  const [currentOrderQtys, setCurrentOrderQtys] = useState();
  const [overallCategorySummary, setOverallCategorySummary] = useState();
  const [articleToConfirmDelete, setArticleToConfirmDelete] = useState();
  const [groupKeyToDelete, setGroupKeyToDelete] = useState();

  const searchTermChanged = debounce(async (searchTerm) => {
    if (searchTerm.length > 0) {
      const results = await searchByArticleNameOrNumber(
        searchTerm,
        booking?.seasonKey
      );
      setSearchResults(results);
    } else {
      setSearchResults();
    }
  }, 350);

  const onSortChange = (sort) => {
    setArticleSort(sort);
  };

  useEffect(() => {
    if (booking?.sortOrder != null) {
      setArticleSort(booking.sortOrder);
    }
  }, [booking?.sortOrder]);

  //Monitor orders for selected order becoming complete
  useEffect(() => {
    if (selectedOrderID == null) return;
    if (orders[selectedOrderID].completionDate != null) {
      setSelectedOrderID();
    }
  }, [orders, selectedOrderID, setSelectedOrderID]);

  //Set Initial Order Data
  useEffect(() => {
    if (selectedArticles == null || selectedOrderID == null || orders == null)
      return;

    const selectedOrder = orders[selectedOrderID];
    setCurrentOrderQtys(selectedOrder.qtys);

    let defaultValues = {};

    selectedArticles.forEach((article) => {
      article.userFriendlySizes.forEach((size, index) => {
        if (defaultValues[article.id] == null)
          defaultValues[article.id] = { orderQtys: [] };

        defaultValues[article.id].orderQtys[index] =
          selectedOrder.qtys[article.id]?.[size] || null;
      });
    });

    const { categoryTotals } = calculateOrderSummaryAndOrderQtys(
      defaultValues,
      overallCategories,
      selectedArticles,
      booking?.priceToShowOrdering
    );

    setOverallCategorySummary(categoryTotals);
    reset(defaultValues);
  }, [
    selectedArticles,
    orders,
    reset,
    selectedOrderID,
    overallCategories,
    booking?.priceToShowOrdering
  ]);

  //Order Manipulation
  useEffect(() => {
    const subscription = watch((value) => {
      Promise.all(
        Object.keys(value).map(async (articleID) => {
          let article = selectedArticles.find(
            (article) => article.id === articleID
          );
          if (article == null) {
            article = Object.values(
              await retrieveArticlesByIDs([articleID])
            )[0];
          }
          return article;
        })
      ).then((articles) => {
        const { categoryTotals, orderQtys } = calculateOrderSummaryAndOrderQtys(
          value,
          overallCategories,
          articles,
          booking?.priceToShowOrdering
        );

        setOverallCategorySummary(categoryTotals);
        setCurrentOrderQtys(orderQtys);
        updateDBOrderQtys(orderQtys, selectedOrderID);
      });
    });
    return () => subscription.unsubscribe();
  }, [
    booking?.priceToShowOrdering,
    overallCategories,
    retrieveArticlesByIDs,
    selectedArticles,
    selectedOrderID,
    updateDBOrderQtys,
    watch
  ]);

  //This should only run on first setup and then selected articles are managed
  //Initial selected articles should only be set once on initial setup
  useEffect(() => {
    if (booking == null || initialSelectedArticles != null) return;
    setInitialSelectedArticles(booking.articles);
    if (selectedArticles == null || selectedArticles?.length === 0) {
      setSelectedArticles(booking.articles);
    }
  }, [booking, initialSelectedArticles, selectedArticles]);

  //Single Article Selection Toggle
  const onArticleSelection = (selectedArticle) => {
    let shouldWriteToDB = true;
    let articlesToWrite = [];
    if (selectedArticles.some((article) => article.id === selectedArticle.id)) {
      if (currentOrderQtys?.[selectedArticle.id] != null) {
        setUserNoticeMessage(t("cannot_remove_article_notice"));
        return;
      } else if (
        (selectedOrderID == null &&
          orders != null &&
          Object.keys(orders).length > 0) ||
        (orders != null && Object.keys(orders).length > 1)
      ) {
        shouldWriteToDB = false;
        setArticleToConfirmDelete(selectedArticle);
      } else {
        articlesToWrite = selectedArticles.filter(
          (article) => article.id !== selectedArticle.id
        );
      }
    } else {
      articlesToWrite = [...selectedArticles, selectedArticle];
    }
    if (shouldWriteToDB) {
      setSelectedArticles(articlesToWrite);
      updateLocalBookingArticles(articlesToWrite);
      updateRemoteBookingArticleIDs(
        articlesToWrite.map((article) => article.id)
      );
    }
  };
  const onConfirmDeleteSingleArticle = () => {
    const articlesToWrite = selectedArticles.filter(
      (article) => article.id !== articleToConfirmDelete.id
    );
    setSelectedArticles(articlesToWrite);
    updateLocalBookingArticles(articlesToWrite);
    updateRemoteBookingArticleIDs(articlesToWrite.map((article) => article.id));
    setArticleToConfirmDelete();
  };

  //Articles Addition and Subtraction from Booking
  const onAddArticlesFromGroup = async (groupKey) => {
    let result;
    if (articleSort === "collection") {
      result = await searchByCollectionKey(booking.seasonKey, groupKey);
    } else if (articleSort === "category") {
      result = await searchByCategoryKey(booking.seasonKey, groupKey);
    }
    const newArticles = result;
    let newSelectedArticles = [...selectedArticles];
    newArticles.forEach((newArticle) => {
      if (!selectedArticles.some((article) => article.id === newArticle.id)) {
        newSelectedArticles.push(newArticle);
      }
    });
    //console.log(newSelectedArticles);
    setSelectedArticles(newSelectedArticles);
    updateLocalBookingArticles(newSelectedArticles);
    updateRemoteBookingArticleIDs(
      newSelectedArticles.map((article) => article.id)
    );
  };

  const onRemoveArticlesFromCollection = async (groupKey) => {
    let newSelectedArticles;

    if (
      (selectedOrderID == null &&
        orders != null &&
        Object.keys(orders).length > 0) ||
      (orders != null && Object.keys(orders).length > 1)
    ) {
      setGroupKeyToDelete(groupKey);
    } else {
      if (
        selectedArticles.some(
          (article) => currentOrderQtys?.[article.id] != null
        )
      ) {
        setUserNoticeMessage(t("cannot_remove_some_articles"));
      }

      if (articleSort === "collection") {
        newSelectedArticles = selectedArticles.filter(
          (article) =>
            currentOrderQtys?.[article.id] != null ||
            !article[`${booking.seasonKey}_collectionKeys`].some((item) =>
              item.includes(groupKey)
            )
        );
      } else if (articleSort === "category") {
        newSelectedArticles = selectedArticles.filter(
          (article) =>
            currentOrderQtys?.[article.id] != null ||
            !article[`${booking.seasonKey}_generalCategoryKey`].includes(
              groupKey
            )
        );
      }

      setSelectedArticles(newSelectedArticles);
      updateLocalBookingArticles(newSelectedArticles);
      updateRemoteBookingArticleIDs(
        newSelectedArticles.map((article) => article.id)
      );
    }
  };
  const onConfirmRemovalOfCollection = () => {
    let newSelectedArticles;
    if (articleSort === "collection") {
      newSelectedArticles = selectedArticles.filter(
        (article) =>
          currentOrderQtys?.[article.id] != null ||
          !article[`${booking.seasonKey}_collectionKeys`].some((item) =>
            item.includes(groupKeyToDelete)
          )
      );
    } else if (articleSort === "category") {
      newSelectedArticles = selectedArticles.filter(
        (article) =>
          currentOrderQtys?.[article.id] != null ||
          !article[`${booking.seasonKey}_generalCategoryKey`].includes(
            groupKeyToDelete
          )
      );
    }

    setSelectedArticles(newSelectedArticles);
    updateLocalBookingArticles(newSelectedArticles);
    updateRemoteBookingArticleIDs(
      newSelectedArticles.map((article) => article.id)
    );
    setGroupKeyToDelete();
  };

  const onSelectedOrderID = (orderID, shouldOpenCart) => {
    setSelectedOrderID(orderID);
    if (shouldOpenCart) {
      setShowCartModal(true);
    }
  };

  return booking == null ? (
    <Loading isLoading />
  ) : (
    <Container>
      <ProductPickerHeader
        searchBarRef={searchBarRef}
        searchTermChanged={searchTermChanged}
        onSortChange={onSortChange}
        currentSort={articleSort}
        onCartSelected={() => setShowCartModal(true)}
        enableCartButton
        selectedArticles={selectedArticles}
        orders={orders}
        selectedOrderID={selectedOrderID}
        onSelectedOrderID={onSelectedOrderID}
      />
      <ProductPickerScrollArea
        pricingToShowPreview={booking.priceToShowPreview}
        pricingToShowOrdering={booking.priceToShowOrdering}
        allowOrderEntry={selectedOrderID != null}
        showInventoryButton={selectedOrderID == null}
        searchResults={searchResults}
        selectedArticles={selectedArticles}
        articleSort={articleSort}
        onArticleSelection={onArticleSelection}
        onAddArticlesFromGroup={onAddArticlesFromGroup}
        onRemoveArticlesFromCollection={onRemoveArticlesFromCollection}
        initialSelectedArticles={initialSelectedArticles}
        showCollectionHeader={true}
        selectedSeasonKey={booking?.seasonKey}
        selectedSeasonSiloKey={booking?.seasonSiloKey}
        formControl={control}
        getFormValues={getValues}
        setFormValue={setValue}
        overallCategorySummary={overallCategorySummary}
      />
      <CartModal
        show={showCartModal}
        onClose={() => setShowCartModal(false)}
        booking={booking}
        selectedArticles={selectedArticles}
        formControl={control}
        getFormValues={getValues}
        setFormValue={setValue}
        overallCategorySummary={overallCategorySummary}
        didSelecteRemoveArticle={onArticleSelection}
      />
      <UserNoticeModal
        show={userNoticeMessage != null}
        onClose={() => setUserNoticeMessage()}
        title={t("cannot_remove_title")}
        message={userNoticeMessage}
      />
      <ConfirmModal
        show={articleToConfirmDelete != null}
        onClose={() => setArticleToConfirmDelete()}
        title={t("confirm_delete_title")}
        message={t("confirm_booking_article_remove")}
        onConfirm={onConfirmDeleteSingleArticle}
        buttonTitle={"Remove"}
        isDestructive
      />
      <ConfirmModal
        show={groupKeyToDelete != null}
        onClose={() => setGroupKeyToDelete()}
        title={t("confirm_delete_title")}
        message={t("confirm_booking_article_remove")}
        onConfirm={onConfirmRemovalOfCollection}
        buttonTitle={"Remove"}
        isDestructive
      />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  gap: 10px 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: hidden;
`;

export default ArticlesTab;
