import { useMemo, useCallback } from "react";
import { useAuth } from "database/useAuth";
import { useDatabaseReader } from "database/useDatabaseReader";
import { debounce } from "lodash";
import {
  addDoc,
  collection,
  deleteField,
  doc,
  getFirestore,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { firebaseRandomID } from "helpers/helperFunctions";

const useOrderWriter = () => {
  const { user } = useAuth();
  const { selectedBooking } = useDatabaseReader();

  const createNewOrder = useCallback(
    async (name, isRepManaged) => {
      let order = {
        adidasRepIDs: [user.uid],
        name: name,
        isRepManaged: isRepManaged,
        creationDate: new Date(),
        qtys: {},
        purchaserUID: selectedBooking?.purchaserUID
      };

      const newOrderDocRef = await addDoc(
        collection(getFirestore(), "bookings", selectedBooking?.id, "orders"),
        order
      );

      return newOrderDocRef.id;
    },
    [selectedBooking?.id, selectedBooking?.purchaserUID, user.uid]
  );

  const setCurrentEditorID = useCallback(
    async (editorID, orderID) => {
      await updateDoc(
        doc(getFirestore(), "bookings", selectedBooking?.id, "orders", orderID),
        {
          editorID: editorID || deleteField()
        }
      );
      return true;
    },
    [selectedBooking?.id]
  );

  const updateOrderName = useCallback(
    async (name, orderID) => {
      await updateDoc(
        doc(getFirestore(), "bookings", selectedBooking?.id, "orders", orderID),
        {
          name: name
        }
      );
      return true;
    },
    [selectedBooking?.id]
  );

  const updateDBOrderQtys = useMemo(
    () =>
      debounce(
        async (qtys, orderID) => {
          await updateDoc(
            doc(
              getFirestore(),
              "bookings",
              selectedBooking?.id,
              "orders",
              orderID
            ),
            {
              qtys: qtys
            }
          );
          return true;
        },
        2000,
        { maxWait: 6000 }
      ),
    [selectedBooking?.id]
  );

  const transferOrderToBuyer = useCallback(
    async (orderID) => {
      await updateDoc(
        doc(getFirestore(), "bookings", selectedBooking?.id, "orders", orderID),
        {
          isRepManaged: false
        }
      );
      return true;
    },
    [selectedBooking?.id]
  );

  const duplicateOrder = useCallback(
    async (order) => {
      const ref = await addDoc(
        collection(getFirestore(), "bookings", selectedBooking?.id, "orders"),
        order
      );
      return ref.id;
    },
    [selectedBooking?.id]
  );

  const completeOrder = useCallback(
    async (orderID) => {
      await updateDoc(
        doc(getFirestore(), "bookings", selectedBooking?.id, "orders", orderID),
        {
          completionDate: new Date()
        }
      );
      return true;
    },
    [selectedBooking?.id]
  );

  return {
    createNewOrder,
    updateDBOrderQtys,
    updateOrderName,
    transferOrderToBuyer,
    duplicateOrder,
    completeOrder,
    setCurrentEditorID
  };
};

export default useOrderWriter;
