import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";
import Modal from "components/shared/modal/Modal";
import ImageComponent from "components/shared/ImageComponent";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Button, MiniButton } from "components/shared/standardElements";
import { useArticleReader } from "database/articles/useArticleReader";
import MiniSummary from "./MiniSummary";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { netPrice } from "helpers/helperFunctions";

const EditArticleQtysModal = ({
  show,
  onClose,
  articleID,
  articleDetails,
  articleQtys,
  formControl,
  getFormValues,
  setFormValue,
  overallCategorySummary,
  displayPrice,
  seasonKey
}) => {
  //HOOKS
  const { t, i18n } = useTranslation();
  const { copiedSizeRun, setCopiedSizeRun } = useArticleReader();
  const { handleSubmit } = useForm();

  const [unpastedSizes, setUnpastedSizes] = useState([]);

  const onCloseModal = () => {
    onClose();
  };

  const copySizeRun = () => {
    const currentValues = getFormValues();
    let sizeRunToCopy = {};
    currentValues[articleDetails.id].orderQtys.forEach((qty, index) => {
      sizeRunToCopy[articleDetails.userFriendlySizes[index]] = qty;
    });
    setCopiedSizeRun(sizeRunToCopy);
    toast.success(
      t("copied_size_run_notification", {
        articleNumber: articleDetails.articleNumber
      })
    );
  };

  const pasteSizeRun = () => {
    let unPasted = [];
    articleDetails.userFriendlySizes.forEach((size, index) => {
      if (size in copiedSizeRun) {
        setFormValue(
          `${articleDetails.id}.orderQtys.${index}`,
          copiedSizeRun[size] > 0 ? copiedSizeRun[size] : null
        );
      } else {
        unPasted.push(size);
      }
      setUnpastedSizes(unPasted);
    });
  };

  const clearSizeRun = () => {
    const sizes = Object.keys(getFormValues(`${articleDetails.id}.orderQtys`));
    sizes.forEach((size) => {
      setFormValue(`${articleDetails.id}.orderQtys.${size}`, null);
    });
    setUnpastedSizes([]);
  };

  const parseInputs = (data) => {
    // let articleData = {
    //   [`${articleID}`]: {
    //     orderCaps: {}
    //   }
    // };
    // let newDefaultValues = {};
    // data[articleID].orderQty.forEach((cap, index) => {
    //   newDefaultValues[`${articleID}.orderQty.${index}`] = cap;
    //   articleData[articleID].orderCaps[articleDetails.sizes[index]] =
    //     cap == null ? -1 : cap;
    // });
    // updateOrder(articleData);
    onClose();
  };

  return (
    <Modal
      title={t("order_entry")}
      show={show}
      onClose={onCloseModal}
      maxWidth='98vw'
    >
      <Wrapper>
        {articleDetails != null && (
          <StyledForm id='sizeInputForm' onSubmit={handleSubmit(parseInputs)}>
            <Card>
              <ArticleWrapper>
                <ImageWrapper>
                  <Image
                    imageURLs={
                      articleDetails.imageURLs.filter(
                        (url) =>
                          url.includes("_MAIN_") && url.includes("_thumb")
                      )[0]
                    }
                  />
                </ImageWrapper>
                <InfoWrapper>
                  <ArticleName>{articleDetails.articleName}</ArticleName>
                  <ArticleNumber>{articleDetails.articleNumber}</ArticleNumber>
                  <ArticleColor>
                    {i18n.language === "en"
                      ? articleDetails.color
                      : articleDetails.color_fr}
                  </ArticleColor>
                  {articleDetails != null && (
                    <ArticlePrice>
                      {displayPrice === "MSRP" ? (
                        `$ ${articleDetails.retailPrice} ${t("MSRP")}`
                      ) : displayPrice === "WHSL" ? (
                        `$ ${netPrice(
                          articleDetails.retailPrice,
                          articleDetails.priceCode
                        )} ${t("WHSL")}`
                      ) : displayPrice === "MSRP+WHSL" ? (
                        <>
                          <div>{`$ ${articleDetails.retailPrice} ${t(
                            "MSRP"
                          )} /`}</div>
                          <div>{`$ ${netPrice(
                            articleDetails.retailPrice,
                            articleDetails.priceCode
                          )} ${t("WHSL")}`}</div>
                        </>
                      ) : displayPrice === "NONE" ? (
                        ""
                      ) : (
                        `$ ${articleDetails?.retailPrice} ${t("MSRP")}`
                      )}
                    </ArticlePrice>
                  )}
                  <IntroDate>
                    <span>{t("intro_date_label")}: </span>
                    {i18n.language === "en"
                      ? articleDetails?.[`${seasonKey}_deliveryDate`]
                      : articleDetails?.[`${seasonKey}_deliveryDate_fr`]}
                  </IntroDate>
                </InfoWrapper>
                <SizeInputWrapper>
                  <SizeInputContainer>
                    <Fragment>
                      <LabelBox>
                        <LimitsInputTitle>{t("order_label")}:</LimitsInputTitle>
                        {articleDetails.inventory?.incoming != null ? (
                          articleDetails.inventory?.incoming?.map(
                            (dateData, index) => (
                              <InventoryDateTitle
                                key={dateData.date}
                                row={index}
                              >
                                {new Date(dateData.date).toLocaleString(
                                  i18n.language,
                                  {
                                    dateStyle: "medium"
                                  }
                                )}
                              </InventoryDateTitle>
                            )
                          )
                        ) : (
                          <InventoryDateTitle>NO DATA</InventoryDateTitle>
                        )}
                        <InventoryTotalTitle>{t("total")}</InventoryTotalTitle>
                      </LabelBox>
                      {articleDetails.userFriendlySizes.map(
                        (friendlySize, index) => {
                          const databaseSize = articleDetails.sizes[index];
                          return (
                            <SizeContainer key={friendlySize}>
                              <SizeTitle>{friendlySize}</SizeTitle>

                              <Controller
                                render={({ field }) => {
                                  return (
                                    <SizeInput
                                      $unPastedHighlight={
                                        unpastedSizes.includes(friendlySize)
                                          ? true
                                          : false
                                      }
                                      placeholder=''
                                      onValueChange={(v) => {
                                        field.onChange(
                                          v.floatValue != null
                                            ? v.floatValue
                                            : null
                                        );
                                      }}
                                      value={field.value || ""}
                                      displayType='input'
                                      type='text'
                                      thousandSeparator={false}
                                      allowNegative={false}
                                      decimalScale={0}
                                    />
                                  );
                                }}
                                name={`${articleID}.orderQtys.${index}`}
                                defaultValue={
                                  articleQtys?.[friendlySize] >= 0
                                    ? articleQtys[friendlySize]
                                    : null
                                }
                                control={formControl}
                              />
                              {articleDetails.inventory?.incoming != null ? (
                                articleDetails.inventory?.incoming?.map(
                                  (dateData, index) => (
                                    <InventoryQty
                                      key={dateData.date}
                                      row={index}
                                    >
                                      {dateData.sizes[databaseSize] != null
                                        ? dateData.sizes[databaseSize]
                                        : "-"}
                                    </InventoryQty>
                                  )
                                )
                              ) : (
                                <InventoryQty row={index}>-</InventoryQty>
                              )}
                              <InventoryQtyTotal>
                                {articleDetails?.inventory.overallTotals == null
                                  ? "-"
                                  : articleDetails?.inventory.overallTotals
                                      ?.sizes[databaseSize] != null
                                  ? articleDetails?.inventory.overallTotals
                                      ?.sizes[databaseSize]
                                  : "-"}
                              </InventoryQtyTotal>
                            </SizeContainer>
                          );
                        }
                      )}
                    </Fragment>
                  </SizeInputContainer>
                  <CopyPasteArea>
                    <CopyPasteButton type='button' onClick={copySizeRun}>
                      {t("copy_size_run_button")}
                    </CopyPasteButton>
                    <CopyPasteButton
                      type='button'
                      disabled={Object.keys(copiedSizeRun).length === 0}
                      onClick={pasteSizeRun}
                    >
                      {t("paste_size_run_button")}
                    </CopyPasteButton>
                    <CopyPasteButton type='button' onClick={clearSizeRun}>
                      {t("clear_button")}
                    </CopyPasteButton>
                  </CopyPasteArea>
                </SizeInputWrapper>
              </ArticleWrapper>
            </Card>
            <StyledButton primary type='submit'>
              {t("save_button")}
            </StyledButton>
          </StyledForm>
        )}
        {overallCategorySummary != null &&
          Object.keys(overallCategorySummary).length > 0 && (
            <MiniSummary
              totals={overallCategorySummary}
              displayPrice={displayPrice}
            />
          )}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
`;

const Card = styled.div`
  width: 100%;
  margin-bottom: 5px;
  background-color: white;
  display: flex;
  gap: 10px;
  position: relative;
`;

const ArticleWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  padding: 5px;
  width: 150px;
  flex-shrink: 0;
`;

const Image = styled(ImageComponent)`
  max-width: 150px;
  max-height: 150px;
`;

const InfoWrapper = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 200px;
  flex-shrink: 0;
  border-right: 1px solid ${COLORS.secondary};
`;

const ArticleName = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleNumber = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticleColor = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
`;

const ArticlePrice = styled.div`
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  padding: 5px;
`;

const IntroDate = styled.div`
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.medium};
  color: ${COLORS.text};
  padding: 5px;
  text-transform: uppercase;

  & > span {
    font-weight: ${FONTWEIGHT.heavy};
  }
`;

const SizeInputWrapper = styled.div`
  overflow-x: hidden;
`;

const SizeInputContainer = styled.div`
  padding: 10px;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
`;

const LabelBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
`;

const LimitsInputTitle = styled.div`
  padding-bottom: 17px;
  padding-right: 5px;
  text-transform: uppercase;
`;

const InventoryDateTitle = styled.div`
  width: 100%;
  padding: 3px;
  padding-right: 5px;
  text-align: right;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  background-color: ${({ row }) => row % 2 === 0 && COLORS.tertiary};
  white-space: nowrap;
`;

const InventoryTotalTitle = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.text};
  padding: 3px;
  padding-right: 5px;
  text-align: right;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.heavy};
  background-color: ${COLORS.secondary};
  text-transform: uppercase;
`;

const SizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 52px;
  /* margin: 5px; */
  flex-shrink: 0;
`;

const SizeTitle = styled.div`
  color: ${COLORS.text};
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid ${COLORS.secondary};
  width: 100%;
  text-align: center;
`;

const SizeInput = styled(NumberFormat)`
  width: 45px;
  text-align: center;
  border: 2px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 11px;
  background-color: ${COLORS.tertiary};
  font-size: ${FONTSIZE.small};
  color: ${(props) => {
    if (props.$orangeHighlight) {
      return COLORS.alertOrange;
    } else if (props.$redHighlight) {
      return COLORS.alertRed;
    } else {
      return undefined;
    }
  }};
  border-color: ${(props) => {
    if (props.$orangeHighlight) {
      return COLORS.alertOrange;
    } else if (props.$redHighlight) {
      return COLORS.alertRed;
    } else {
      return undefined;
    }
  }};
  -webkit-appearance: none;

  &:focus {
    outline: none !important;
  }

  border-color: ${(props) => {
    if (props.$unPastedHighlight) {
      return COLORS.alertOrange;
    }
  }};
`;

const InventoryQty = styled.div`
  width: 100%;
  padding: 3px;
  text-align: center;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  background-color: ${({ row }) => row % 2 === 0 && COLORS.tertiary};
`;

const InventoryQtyTotal = styled.div`
  width: 100%;
  /* margin-top: 3px; */
  border-top: 1px solid ${COLORS.text};
  padding: 3px;
  text-align: center;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.heavy};
  background-color: ${COLORS.secondary};
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
`;

const CopyPasteArea = styled.div`
  width: 100%;
  display: flex;
  padding: 0 10px;
`;

const CopyPasteButton = styled(MiniButton)`
  margin-right: 5px;
`;

export default EditArticleQtysModal;
