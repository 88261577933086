import React, { useState } from "react";
import Modal from "components/shared/modal/Modal";
import styled from "styled-components/macro";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import Checkbox from "components/shared/Checkbox";
import { Button } from "components/shared/standardElements";

const ConfirmShareModal = ({ show, onClose, contacts = [], onSend }) => {
  const [sharePasscode, setSharePasscode] = useState(false);

  const onSelectedSend = () => {
    onSend(sharePasscode);
  };

  return (
    <Modal
      title='SHARE BOOKING'
      show={show}
      onClose={onClose}
      disableCloseOnOutsideClick
      maxHeight={"90vh"}
    >
      <Container>
        <Message>CONTACTS:</Message>
        <ContactList>
          {contacts.map((contact) => (
            <ContactLineItem key={contact.id}>{contact.name}</ContactLineItem>
          ))}
        </ContactList>
        <SendPurchaseCodeWrapper>
          <Checkbox
            label='SHARE ORDERING PASSCODE'
            color={COLORS.text}
            selected={sharePasscode}
            onChange={() => setSharePasscode(!sharePasscode)}
          />
        </SendPurchaseCodeWrapper>
        <Button onClick={onSelectedSend}>SEND</Button>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  height: 100%;
  min-width: 300px;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
`;

const Message = styled.div`
  color: ${COLORS.text};
  /* text-align: center; */
  /* padding-left: 10px; */
`;

const ContactList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  width: 100%;
  overflow-y: auto;
`;

const ContactLineItem = styled.div`
  text-transform: uppercase;
  font-size: ${FONTSIZE.large};
  font-weight: ${FONTWEIGHT.heavy};
  color: ${COLORS.text};
  border-top: 1px solid ${COLORS.secondary};
  padding: 10px 0px;

  :last-child {
    border-bottom: 1px solid ${COLORS.secondary};
  }
`;

const SendPurchaseCodeWrapper = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  color: ${COLORS.text};
`;

export default ConfirmShareModal;
