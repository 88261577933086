import React from "react";
import styled from "styled-components/macro";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import ScrollAreaCard from "components/shared/ScrollAreaCard";
import { MiniButton } from "components/shared/standardElements";

const ContactCard = ({ contact, onEditContact, ...props }) => {
  return (
    <ScrollAreaCard {...props}>
      <Content>
        <Name>{contact.name}</Name>
        <CourseName>{contact.courseName}</CourseName>
        {/* <Email>{contact.email}</Email> */}
        {/* <ButtonGroup>
          <MiniButton onClick={() => onEditContact(contact)}>Edit</MiniButton>
          <MiniButton destructive>Delete</MiniButton>
        </ButtonGroup> */}
      </Content>
    </ScrollAreaCard>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.text};
  /* padding: 5px 10px; */
  padding: 10px 10px 20px;
  /* text-transform: uppercase; */
  cursor: pointer;
`;

const Name = styled.div`
  font-size: ${FONTSIZE.medium};
  text-transform: uppercase;
  font-weight: ${FONTWEIGHT.heavy};
  border-bottom: 1px solid ${COLORS.secondary};
`;

const CourseName = styled.div`
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};
  /* border-bottom: 1px solid ${COLORS.secondary}; */
  text-align: right;
  padding: 5px 0;
  /* margin-bottom: 10px; */
`;

const Email = styled.div`
  padding: 5px;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 10px;
  }
`;

export default ContactCard;
