import { useTranslation } from "react-i18next";
import { MiniButton } from "components/shared/standardElements";
import { COLORS } from "helpers/designConstants";
import React, { useState, useEffect } from "react";
import useBookingWriter from "database/bookings/useBookingWriter";
import styled from "styled-components/macro";
import DashboardCard from "./DashboardCard";

const StatusCard = ({ className, booking, orders }) => {
  const { t, i18n } = useTranslation();
  const { markBookingComplete, markBookingShared } = useBookingWriter();

  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    if (booking == null || orders == null) return;
    let list = [];
    if (!booking?.isComplete) {
      list.push(<ListItem highlight>{t("booking_in_progress")}</ListItem>);
    }
    if (booking != null && Object.keys(booking.shareTracking).length === 0) {
      list.push(
        <ListItem highlight>
          {t("booking_unshared")}
          <StyledSharedButton onClick={markBookingShared}>
            {t("mark_shared")}
          </StyledSharedButton>
        </ListItem>
      );
    } else {
      list.push(
        <ListItem>
          {`${t("last_shared")}: ${new Date(
            Object.values(booking.shareTracking).sort((a, b) =>
              a.lastSent < b.lastSent ? 1 : a.lastSent > b.lastSent ? -1 : 0
            )[0].lastSent
          ).toLocaleString(i18n.language, {
            month: "short",
            day: "numeric",
            year: "numeric"
          })}`}
        </ListItem>
      );
    }

    if (booking?.repOrdersInProgress?.length > 0) {
      list.push(
        <ListItem highlight>
          {t("num_rep_orders_open_withCount", {
            count: booking?.repOrdersInProgress.length
          })}
        </ListItem>
      );
    }
    if (booking?.buyerOrdersInProgress?.length > 0) {
      list.push(
        <ListItem highlight>
          {t("num_buyer_orders_open_withCount", {
            count: booking?.buyerOrdersInProgress.length
          })}
        </ListItem>
      );
    }
    if (
      booking?.repOrdersComplete?.length > 0 ||
      booking?.buyerOrdersComplete?.length > 0
    ) {
      list.push(
        <ListItem>
          {t("num_orders_complete_withCount", {
            count:
              (booking?.repOrdersComplete?.length || 0) +
              (booking?.buyerOrdersComplete?.length || 0)
          })}
        </ListItem>
      );
    }

    setStatusList(list);
  }, [
    booking,
    i18n.language,
    markBookingComplete,
    markBookingShared,
    orders,
    t
  ]);

  return (
    <DashboardCard className={className} title={t("status")}>
      <Wrapper>
        <List>
          {statusList.map((item, index) => (
            <li key={`statusItem-${index}`}>{item}</li>
          ))}
        </List>
      </Wrapper>
    </DashboardCard>
  );
};

const Wrapper = styled.div``;

const List = styled.ul``;

const ListItem = styled.div`
  padding: 5px 0;
  text-transform: uppercase;
  color: ${({ highlight }) => (highlight ? COLORS.alertOrange : "inherit")};
  display: flex;
  align-items: center;
`;

const StyledSharedButton = styled(MiniButton)`
  margin-left: 10px;
`;

export default StatusCard;
