import { useMemo, useCallback } from "react";
import { useAuth } from "database/useAuth";
import { debounce } from "lodash";
import {
  addDoc,
  collection,
  deleteField,
  doc,
  getFirestore,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseRandomID } from "helpers/helperFunctions";
import { useCourseDatabase } from "database/useCourseDatabase";

const usePublicOrderWriter = () => {
  const { user } = useAuth();
  const { booking } = useCourseDatabase();

  const createNewOrder = useCallback(
    async (name) => {
      let order = {
        adidasRepIDs: [user.uid],
        isRepManaged: false,
        creationDate: new Date(),
        purchaserUID: booking?.purchaserUID,
        qtys: {}
      };

      if (name != null) order.name = name;

      const docRef = await addDoc(
        collection(getFirestore(), "bookings", booking?.id, "orders"),
        order
      );
      return docRef.id;
    },
    [booking?.id, booking?.purchaserUID, user.uid]
  );

  const updateOrderName = useCallback(
    async (name, orderID) => {
      await updateDoc(
        doc(getFirestore(), "bookings", booking?.id, "orders", orderID),
        {
          name: name
        }
      );
      return true;
    },
    [booking?.id]
  );

  const setCurrentEditorID = useCallback(
    async (editorID, orderID) => {
      await updateDoc(
        doc(getFirestore(), "bookings", booking?.id, "orders", orderID),
        {
          editorID: editorID || deleteField()
        }
      );
      return true;
    },
    [booking?.id]
  );

  const updateDBOrderQtys = useMemo(
    () =>
      debounce(
        async (qtys, orderID) => {
          await updateDoc(
            doc(getFirestore(), "bookings", booking?.id, "orders", orderID),
            {
              qtys: qtys
            }
          );
          return true;
        },
        2000,
        { maxWait: 6000 }
      ),
    [booking?.id]
  );

  const duplicateOrder = useCallback(
    async (order) => {
      const ref = await addDoc(
        collection(getFirestore(), "bookings", booking?.id, "orders"),
        order
      );
      return ref.id;
    },
    [booking?.id]
  );

  const completeOrder = useCallback(
    async (orderID) => {
      await updateDoc(
        doc(getFirestore(), "bookings", booking?.id, "orders", orderID),
        {
          completionDate: new Date()
        }
      );
      return true;
    },
    [booking?.id]
  );

  const submitOrder = async (
    orderID,
    name,
    email,
    pdfSummary,
    excelOrderForm
  ) => {
    const submitBookingOrder = httpsCallable(
      getFunctions(),
      "http-submitBookingOrder"
    );

    await submitBookingOrder({
      booking,
      orderID,
      fromName: name,
      fromEmail: email,
      pdfSummary,
      excelOrderForm
    });

    await updateDoc(
      doc(getFirestore(), "bookings", booking?.id, "orders", orderID),
      {
        completionDate: new Date()
      }
    );
    return true;
  };

  return {
    createNewOrder,
    updateDBOrderQtys,
    updateOrderName,
    duplicateOrder,
    completeOrder,
    submitOrder,
    setCurrentEditorID
  };
};

export default usePublicOrderWriter;
