import { React } from "react";

import styled from "styled-components/macro";
import { COLORS, CONSTANTS, FONTSIZE } from "helpers/designConstants";

const ScrollArea = ({ emptyPlaceholder, className, children }) => {
  return (
    <OuterContainer>
      {children != null && children.length > 0 ? (
        <ContentContainerScrollArea className={className}>
          {children}
        </ContentContainerScrollArea>
      ) : (
        <EmptyPlaceholder>
          <div>{emptyPlaceholder}</div>
        </EmptyPlaceholder>
      )}
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  overflow-y: auto;
`;

const ContentContainerScrollArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0.5fr));
  grid-gap: 5px;
  align-content: start;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 5px;
`;

const EmptyPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  color: ${COLORS.text};
  font-size: ${FONTSIZE.large};
  text-align: center;
  border-radius: ${CONSTANTS.borderRadius};
`;

export default ScrollArea;
