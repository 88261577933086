import React from "react";
import styled from "styled-components/macro";
import ScrollAreaCard from "components/shared/ScrollAreaCard";
import { COLORS, FONTSIZE, FONTWEIGHT } from "helpers/designConstants";
import { useTranslation } from "react-i18next";
import { MiniButton } from "components/shared/standardElements";

const CatalogueCard = ({
  catalogue,
  onEditArticlesForCatalogue,
  onEditCatalogueDetails,
  onCopyLink,
  onDeleteCatalogue,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  return (
    <ScrollAreaCard {...props}>
      <Content>
        <Name>{catalogue.name}</Name>
        <CreationDate>{`${t("created_on")}: ${new Date(
          catalogue.creationDate
        ).toLocaleString(i18n.language, {
          month: "short",
          day: "numeric",
          year: "numeric"
        })}`}</CreationDate>
        <ButtonGroup>
          <MiniButton onClick={() => onEditArticlesForCatalogue(catalogue)}>
            {t("edit_articles_button")}
          </MiniButton>
          <MiniButton onClick={() => onEditCatalogueDetails(catalogue)}>
            {t("edit_details")}
          </MiniButton>
          <MiniButton destructive onClick={() => onDeleteCatalogue(catalogue)}>
            {t("delete_button")}
          </MiniButton>
          <MiniButton highlightedOutline onClick={() => onCopyLink(catalogue)}>
            {t("copy_link_button")}
          </MiniButton>
        </ButtonGroup>
      </Content>
    </ScrollAreaCard>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.text};
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
`;

const Name = styled.div`
  font-size: ${FONTSIZE.medium};
  text-transform: uppercase;
  font-weight: ${FONTWEIGHT.heavy};
`;
const CreationDate = styled.div`
  padding: 5px 0 0px;
  text-transform: uppercase;
  font-size: ${FONTSIZE.xsmall};
  font-weight: ${FONTWEIGHT.heavy};
`;

const ButtonGroup = styled.div`
  border-top: 1px solid ${COLORS.secondary};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 10px;

  ${MiniButton} {
    margin: 5px;
  }
`;

export default CatalogueCard;
