import React, { useCallback } from "react";
import styled from "styled-components/macro";
import {
  COLORS,
  FONTWEIGHT,
  FONTSIZE,
  CONSTANTS
} from "helpers/designConstants";

const TabTitle = ({
  isHighlighted,
  title,
  alertCount,
  setSelectedTab,
  index,
  isSelected
}) => {
  const onSelectedTab = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <Title
      isHighlighted={isHighlighted}
      isSelected={isSelected}
      onClick={onSelectedTab}
    >
      {title}
      {alertCount != null && <AlertCount>{alertCount}</AlertCount>}
    </Title>
  );
};

const Title = styled.li`
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
  background-color: ${({ isHighlighted, isSelected }) => {
    if (isHighlighted && isSelected) {
      return COLORS.alertOrange;
    } else if (!isHighlighted && isSelected) {
      return COLORS.tertiary;
    } else {
      return COLORS.primaryInverted;
    }
  }};
  color: ${({ isHighlighted, isSelected }) => {
    if (isHighlighted && isSelected) {
      return COLORS.tertiary;
    } else if (!isHighlighted && isSelected) {
      return COLORS.text;
    } else if (isHighlighted && !isSelected) {
      return COLORS.alertOrange;
    } else {
      return COLORS.secondary;
    }
  }};
  border: ${({ isHighlighted, isSelected }) => {
    if (!isSelected && isHighlighted) {
      return `1px solid ${COLORS.alertOrange}`;
    } else {
      return `1px solid ${COLORS.tertiary}`;
    }
  }};
  border-bottom: none;
  border-top-left-radius: ${CONSTANTS.borderRadius};
  border-top-right-radius: ${CONSTANTS.borderRadius};
  transition: 0.3s ease;
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  text-transform: uppercase;

  &:hover {
    ${(props) => {
      if (!props.isSelected) {
        return `background-color: ${COLORS.tertiary};`;
      }
    }}
  }
`;

const AlertCount = styled.div`
  margin-left: 5px;
  padding: 0 5px;
  border-radius: 5px;
  font-size: ${FONTSIZE.small};
  border: 2px solid ${COLORS.alertOrange};
  color: ${COLORS.alertOrange};
`;

export default TabTitle;
