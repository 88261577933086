import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardPageLayout from "components/shared/dashboardPageLayout/DashboardPageLayout";
import { useSearchParams } from "react-router-dom";
import { useDatabaseReader } from "database/useDatabaseReader";
import useOrderWriter from "database/orders/useOrderWriter";

import ArticlesTab from "./articlesTab/ArticlesTab";
import DashboardTab from "./dashboardTab/DashboardTab";
import OrdersTab from "./ordersTab/OrdersTab";

const BookingPage = () => {
  const { t } = useTranslation();
  const {
    deviceID,
    selectedBooking,
    setSelectedBookingID,
    selectedBookingPurchaserPassword,
    contacts,
    updateLocalBookingArticles,
    orders
  } = useDatabaseReader();

  const [searchParams] = useSearchParams();
  const { setCurrentEditorID } = useOrderWriter();

  const [articleTabSelectedOrderID, setArticleTabSelectedOrderID] = useState();

  useEffect(() => {
    if (selectedBooking == null) {
      const currentParams = Object.fromEntries([...searchParams]);
      setSelectedBookingID(currentParams.bookingID);
    }
  }, [searchParams, selectedBooking, setSelectedBookingID]);

  //Set the editorID on the order in Firebase as well as
  //Unset editing id on any open orders to keep database in sync
  useEffect(() => {
    if (articleTabSelectedOrderID != null) {
      setCurrentEditorID(deviceID, articleTabSelectedOrderID);
    }

    //Chrome Failsafe to unset the deviceID on the order if the user refreshes the page
    window.onbeforeunload = (e) => {
      console.log("onBeforeUnload in Booking page called");
      if (articleTabSelectedOrderID != null) {
        setCurrentEditorID(null, articleTabSelectedOrderID);
      }
    };
    //Other Browsers Failsafe to unset the deviceID on the order if the user refreshes the page
    window.onunload = (e) => {
      console.log("onUnload in Booking page called");
      if (articleTabSelectedOrderID != null) {
        setCurrentEditorID(null, articleTabSelectedOrderID);
      }
    };

    return () => {
      if (articleTabSelectedOrderID != null) {
        setCurrentEditorID(null, articleTabSelectedOrderID);
        console.log("Booking was unmounted");
      }
    };
  }, [articleTabSelectedOrderID, deviceID, setCurrentEditorID]);

  const dashboardTabAlerts = () => {
    if (selectedBooking == null) return null;
    let alertCount = 0;
    //Unshared
    if (Object.keys(selectedBooking.shareTracking).length === 0) alertCount++;
    //Marked as Incomplete
    if (!selectedBooking.isComplete) alertCount++;
    if (selectedBooking.repOrdersInProgress?.length > 0) alertCount++;
    if (selectedBooking.buyerOrdersInProgress?.length > 0) alertCount++;
    if (alertCount === 0) {
      return null;
    }
    return alertCount;
  };

  const orderTabAlerts = () => {
    let alertCount = 0;
    orders != null &&
      Object.values(orders).forEach((order) => {
        if (order.completionDate == null) alertCount++;
      });
    if (alertCount === 0) {
      return null;
    }
    return alertCount;
  };

  return (
    <DashboardPageLayout
      title={selectedBooking?.name}
      subTitle={selectedBooking?.courseName}
    >
      <DashboardTab
        title={t("dashboard")}
        alertCount={dashboardTabAlerts()}
        booking={selectedBooking}
        orders={orders}
        orderingPassword={selectedBookingPurchaserPassword}
        contacts={contacts?.contacts}
      />
      <ArticlesTab
        title={t("articles")}
        alertCount={articleTabSelectedOrderID != null ? 1 : undefined}
        booking={selectedBooking}
        updateLocalBookingArticles={updateLocalBookingArticles}
        selectedOrderID={articleTabSelectedOrderID}
        setSelectedOrderID={setArticleTabSelectedOrderID}
      />
      <OrdersTab
        title={t("orders")}
        alertCount={orderTabAlerts()}
        booking={selectedBooking}
        articleTabSelectedOrderID={articleTabSelectedOrderID}
      />
    </DashboardPageLayout>
  );
};

export default BookingPage;
