import { useTranslation } from "react-i18next";
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";
import React from "react";
import styled from "styled-components/macro";
import NumberFormat from "react-number-format";

const Summary = ({ totals, displayPrice }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SummaryTitleWrapper>
        <SummaryTitle>{t("order_summary_title")}</SummaryTitle>
      </SummaryTitleWrapper>
      <InnerWrapper>
        <InnerScrollArea>
          <SubSummary>
            <Title>{t("mens")}</Title>
            <Categories>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>{t("polos")}</CategoryTitle>
                  <CategoryQty>{`${totals?.mensPolos.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.mensPolos.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.mensPolos.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>{t("bottoms")}</CategoryTitle>
                  <CategoryQty>{`${totals?.mensBottoms.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.mensBottoms.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.mensBottoms.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>LYG/OWR:</CategoryTitle>
                  <CategoryQty>{`${totals?.mensLayeringOuterwear.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.mensLayeringOuterwear.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.mensLayeringOuterwear.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>{t("footwear")}</CategoryTitle>
                  <CategoryQty>{`${totals?.mensFootwear.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.mensFootwear.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.mensFootwear.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
            </Categories>
          </SubSummary>
          <SubSummary>
            <Title>{t("womens")}</Title>
            <Categories>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>{t("polos")}</CategoryTitle>
                  <CategoryQty>{`${totals?.womensPolos.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.womensPolos.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.womensPolos.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>{t("bottoms")}</CategoryTitle>
                  <CategoryQty>{`${totals?.womensBottoms.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.womensBottoms.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.womensBottoms.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>LYG/OWR:</CategoryTitle>
                  <CategoryQty>{`${totals?.womensLayeringOuterwear.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.womensLayeringOuterwear.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.womensLayeringOuterwear.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>{t("footwear")}</CategoryTitle>
                  <CategoryQty>{`${totals?.womensFootwear.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.womensFootwear.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.womensFootwear.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
            </Categories>
          </SubSummary>
          <SubSummary>
            <Title>{t("total")}</Title>
            <Categories>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>{t("apparel")}</CategoryTitle>
                  <CategoryQty>{`${totals?.totalApparel.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.totalApparel.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.totalApparel.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>{t("accessories")}</CategoryTitle>
                  <CategoryQty>{`${totals?.accessories.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.accessories.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.accessories.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>{t("footwear")}</CategoryTitle>
                  <CategoryQty>{`${totals?.totalFootwear.qty} pcs`}</CategoryQty>
                </CategoryTopRow>
                <CategoryBottomRow>
                  <CategoryDollars>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.totalFootwear.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.totalFootwear.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryDollars>
                </CategoryBottomRow>
              </Category>
              <Category>
                <CategoryTopRow>
                  <CategoryTitle>{t("total")}</CategoryTitle>
                  <CategoryQty>
                    {(displayPrice === "MSRP" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.totalDollars.msrpDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("MSRP")}`}</span>
                      </>
                    )}

                    {displayPrice === "MSRP+WHSL" && ` / `}
                    {(displayPrice === "WHSL" ||
                      displayPrice === "MSRP+WHSL") && (
                      <>
                        <NumberFormat
                          value={totals?.totalDollars.netDollars}
                          prefix='$'
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale={2}
                          displayType='text'
                        />
                        <span>{` ${t("WHSL")}`}</span>
                      </>
                    )}
                  </CategoryQty>
                </CategoryTopRow>
              </Category>
            </Categories>
          </SubSummary>
        </InnerScrollArea>
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  padding: 15px 5px 10px;
  background: ${COLORS.tertiary};
  display: grid;
  grid-template-rows: min-content 1fr;
`;

const SummaryTitleWrapper = styled.div`
  /* position: absolute;
  top: -12px;
  left: 0;
  right: 0; */
  display: flex;
  justify-content: center;
`;

const SummaryTitle = styled.div`
  color: ${COLORS.text};
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  /* background: ${COLORS.primaryInverted};
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius}; */
  padding: 2px 10px;
  text-transform: uppercase;
`;
const InnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const InnerScrollArea = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SubSummary = styled.div`
  /* flex-grow: 1; */
  display: grid;
  grid-template-rows: min-content 1fr;
  color: ${COLORS.text};
  padding: 5px;
  margin-top: 5px;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  background: white;
`;

const Title = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.small};
  border-bottom: 1px solid ${COLORS.secondary};
  /* margin-bottom: 5px; */
  text-transform: uppercase;
`;

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: ${FONTSIZE.small};

  @media screen and (max-height: 900px) {
    font-size: ${FONTSIZE.xsmall};
  }
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 5px 10px 5px 0;
  margin-left: 10px;

  :not(:last-child) {
    border-bottom: 1px solid ${COLORS.secondary};
  }

  @media screen and (max-height: 900px) {
    padding: 5px;
  }
`;

const CategoryTopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const CategoryBottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 10px;
`;

const CategoryTitle = styled.div`
  padding-right: 20px;
  text-transform: uppercase;
`;

const CategoryNumbers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CategoryQty = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
  padding-bottom: 5px;
`;

const CategoryDollars = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.xsmall};
`;

export default Summary;
