import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { COLORS, CONSTANTS } from "helpers/designConstants";

import { useDatabaseReader } from "database/useDatabaseReader";
import useContactsWriter from "database/useContactsWriter";

import Loading from "components/shared/Loading";
import ScrollArea from "components/shared/ScrollArea";
import SearchBar from "components/shared/SearchBar";
import { Button } from "components/shared/standardElements";
import ContactCard from "./ContactCard";
import CreateEditContactModal from "./CreateEditContactModal";

const ContactsTab = () => {
  //REFS
  const searchBarRef = useRef();

  //HOOKS
  const { t } = useTranslation();
  const { contacts } = useDatabaseReader();
  const { createContact, updateContact, deleteContact, createTestData } =
    useContactsWriter();

  //STATE
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateEditContactModal, setShowCreateEditContactModal] =
    useState(false);
  const [contactToEdit, setContactToEdit] = useState();
  const [filteredContacts, setFilteredContacts] = useState([]);

  useEffect(() => {
    if (contacts == null) return;
    setFilteredContacts(
      Object.values(contacts.contacts).sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      )
    );
  }, [contacts]);

  //METHODS
  const onSearchBarChanged = async (searchTerm) => {
    setFilteredContacts(
      Object.values(contacts.contacts)
        .filter(
          (contact) =>
            contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            contact.courseName.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
    );
  };

  const onSaveContact = (name, email, courseName, contactID) => {
    if (contactID == null) {
      createContact({ name, email, courseName });
    } else {
      updateContact({ name, email, courseName }, contactID);
    }
  };

  const onRemoveContact = (contactID) => {
    deleteContact(contactID);
    searchBarRef.current.clearSearch();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container>
        <Header>
          <StyledSearchBar
            placeholder={`${t("search")}...`}
            searchTermChanged={onSearchBarChanged}
            ref={searchBarRef}
          />
          <StyledButton
            primary
            fontsize='small'
            onClick={() => setShowCreateEditContactModal(true)}
          >
            {` + ${t("new_contact")}`}
          </StyledButton>
        </Header>
        <StyledScrollArea>
          {filteredContacts.map((contact) => (
            <ContactCard
              key={contact.id}
              contact={contact}
              onClick={() => {
                setContactToEdit(contact);
                setShowCreateEditContactModal(true);
              }}
            />
          ))}
        </StyledScrollArea>
      </Container>
      <CreateEditContactModal
        title={contactToEdit != null ? "Edit Contact" : "Create Contact"}
        show={showCreateEditContactModal}
        onClose={() => {
          setShowCreateEditContactModal(false);
          setContactToEdit();
        }}
        selectedContact={contactToEdit}
        courses={contacts?.courseNames}
        onSave={onSaveContact}
        onRemoveContact={onRemoveContact}
      />
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Header = styled.div`
  display: flex;
  padding: 5px;
  border-radius: ${CONSTANTS.borderRadius} ${CONSTANTS.borderRadius} 0 0;
  border-bottom: 2px solid ${COLORS.secondary};
  background: ${COLORS.tertiary};
  box-shadow: 0px 2px 5px ${COLORS.secondary};
  z-index: 1;
`;

const StyledSearchBar = styled(SearchBar)`
  min-width: 325px;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  text-transform: uppercase;
`;

const StyledScrollArea = styled(ScrollArea)`
  grid-template-columns: repeat(auto-fit, minmax(400px, 0.3333fr));
  overflow: unset;
`;

export default ContactsTab;
