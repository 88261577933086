import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect
} from "react";
import { useAuth } from "./useAuth";
import useArticleSearchReader from "./articles/useArticleSearchReader";
import {
  getFirestore,
  onSnapshot,
  doc,
  Timestamp,
  getDoc,
  collection,
  where,
  query
} from "firebase/firestore";
import useLocalStorage from "helpers/useLocalStorage";
import { firebaseRandomID } from "helpers/helperFunctions";
import * as Sentry from "@sentry/react";

const CourseDatabaseContext = createContext();

const CourseDatabaseContextProvider = ({ children }) => {
  //Create a device id for tracking when user is editing an order
  const [deviceID, setDeviceID] = useLocalStorage("deviceID", null);
  if (deviceID == null) setDeviceID(firebaseRandomID());

  const { user, login, authContextLoaded } = useAuth();

  const { retrieveArticlesByIDs } = useArticleSearchReader();

  const [bookingID, setBookingID] = useState();
  const [booking, setBooking] = useState();
  const [currentlySelectedArticles, setCurrentlySelectedArticles] = useState();
  const [orders, setOrders] = useState();

  //Monitor Booking Doc
  useEffect(() => {
    if (!authContextLoaded || user == null || bookingID == null) return;
    const unsubscribe = onSnapshot(
      doc(getFirestore(), "bookings", bookingID),
      (doc) => {
        if (doc.exists()) {
          let booking = doc.data();
          booking.id = doc.id;

          //Parse Firestore Timestamps on creationDate to Javascript dates
          booking.creationDate = booking.creationDate.toDate();

          //Parse Firestore Timestamps on shareTracking to Javascript dates
          Object.entries(booking.shareTracking).forEach(
            ([contactID, contact]) => {
              booking.shareTracking[contactID] = {
                lastSent: contact.lastSent?.toDate()
              };
            }
          );

          // if (currentlySelectedArticles != null) {
          //   booking.articles = currentlySelectedArticles;
          // } else if (booking.articleIDs.length === 0) {
          //   booking.articles = [];
          // }

          setBooking(booking);
        } else {
          Sentry.captureMessage(
            `bookings/${bookingID} does not exist... in useCourseDatabase listener for selected booking... (public buyer site)`
          );
        }
      }
    );

    return () => unsubscribe();
  }, [authContextLoaded, bookingID, user]);

  // Hyrate Articles for Selected Booking
  //This runs everytime the booking info changes
  useEffect(() => {
    if (booking?.id == null || booking?.articleIDs == null || bookingID == null)
      return;
    if (booking.id === bookingID) {
      if (booking.articleIDs.length === 0) {
        setCurrentlySelectedArticles([]);
        setBooking((prev) => ({ ...prev, articles: [] }));
      } else {
        retrieveArticlesByIDs(booking.articleIDs).then((bookingArticles) => {
          setCurrentlySelectedArticles(Object.values(bookingArticles));
          setBooking((prev) => ({
            ...prev,
            articles: Object.values(bookingArticles)
          }));
        });
      }
    }
  }, [
    // currentlySelectedArticles,
    retrieveArticlesByIDs,
    booking?.articleIDs,
    booking?.id,
    bookingID
  ]);

  //This sets the articles on the booking after it has been updated in firebase
  //It comes back raw with no article data attached other than the article ids
  useEffect(() => {
    if (booking?.articles == null && currentlySelectedArticles != null) {
      setBooking((prev) => ({
        ...prev,
        articles: currentlySelectedArticles
      }));
    }
  }, [currentlySelectedArticles, booking?.articles]);

  //Monitor Orders if user is bookingPurchaser type
  useEffect(() => {
    if (user?.type === "bookingPurchaser" && booking != null) {
      const unsubscribe = onSnapshot(
        query(
          collection(getFirestore(), "bookings", booking.id, "orders"),
          where("purchaserUID", "==", user.uid)
        ),
        (snapshot) => {
          const dbOrders = {};
          snapshot.forEach((doc) => {
            let order = { ...doc.data() };
            order.id = doc.id;
            order.creationDate = order.creationDate?.toDate();
            order.completionDate = order.completionDate?.toDate();
            dbOrders[doc.id] = order;
          });
          // orders.sort((a, b) =>
          //   new Date(a.creationDate) > new Date(b.creationDate)
          //     ? 1
          //     : new Date(a.creationDate) < new Date(b.creationDate)
          //     ? -1
          //     : 1
          // );
          setOrders(dbOrders);
        }
      );
      return () => unsubscribe();
    }
  }, [user, booking]);

  const loginViewer = useCallback(
    async (bookingID) => {
      setBookingID(bookingID);
      try {
        if (
          user == null ||
          (authContextLoaded &&
            !user.possibleTypes.some((type) =>
              ["god", "adidasRep", "bookingPurchaser"].includes(type)
            ))
        ) {
          await login(`viewer@${bookingID}.com`, bookingID);
        } else {
          console.log("Current user can view");
        }
        return;
      } catch (error) {
        console.log(error);
      }
    },
    [authContextLoaded, user, login]
  );

  return (
    <CourseDatabaseContext.Provider
      value={{
        deviceID,
        loginViewer,
        booking,
        orders,
        setBookingID
      }}
    >
      {children}
    </CourseDatabaseContext.Provider>
  );
};

export default CourseDatabaseContextProvider;

export const useCourseDatabase = () => useContext(CourseDatabaseContext);
