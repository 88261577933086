import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Modal from "components/shared/modal/Modal";
import { useArticleReader } from "database/articles/useArticleReader";
import NumberFormat from "react-number-format";
import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS
} from "helpers/designConstants";
import { calculateOrderSummaryAndOrderQtys } from "helpers/calculateOrderSummaryAndOrderQtys";

const OrderSummaryModal = ({ title, show, onClose, booking, order }) => {
  const { t } = useTranslation();
  const { overallCategories } = useArticleReader();

  const [overallCategorySummary, setOverallCategorySummary] = useState();

  //Set Initial Order Data
  useEffect(() => {
    if (booking?.articles == null || order?.id == null) return;

    let defaultValues = {};

    booking.articles.forEach((article) => {
      article.userFriendlySizes.forEach((size, index) => {
        if (defaultValues[article.id] == null)
          defaultValues[article.id] = { orderQtys: [] };

        defaultValues[article.id].orderQtys[index] =
          order.qtys[article.id]?.[size] || null;
      });
    });

    const { categoryTotals } = calculateOrderSummaryAndOrderQtys(
      defaultValues,
      overallCategories,
      booking?.articles,
      booking?.priceToShowOrdering
    );

    setOverallCategorySummary(categoryTotals);
  }, [
    booking?.articles,
    booking?.priceToShowOrdering,
    order?.id,
    order?.qtys,
    overallCategories
  ]);

  return (
    <Modal title={title} show={show} onClose={onClose}>
      <Content>
        {/* <TopRow> */}
        <SubSummary>
          <Title>{t("mens")}</Title>
          <Categories>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>{t("polos")}</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.mensPolos.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.mensPolos.msrpDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.mensPolos.netDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>{t("bottoms")}</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.mensBottoms.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.mensBottoms.msrpDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.mensBottoms.netDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>LYG/OWR:</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.mensLayeringOuterwear.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={
                          overallCategorySummary?.mensLayeringOuterwear
                            .msrpDollars
                        }
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={
                          overallCategorySummary?.mensLayeringOuterwear
                            .netDollars
                        }
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>{t("footwear")}</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.mensFootwear.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.mensFootwear.msrpDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.mensFootwear.netDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
          </Categories>
        </SubSummary>
        <SubSummary>
          <Title>{t("womens")}</Title>
          <Categories>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>{t("polos")}</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.womensPolos.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.womensPolos.msrpDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.womensPolos.netDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>{t("bottoms")}</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.womensBottoms.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={
                          overallCategorySummary?.womensBottoms.msrpDollars
                        }
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.womensBottoms.netDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>LYG/OWR:</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.womensLayeringOuterwear.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={
                          overallCategorySummary?.womensLayeringOuterwear
                            .msrpDollars
                        }
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={
                          overallCategorySummary?.womensLayeringOuterwear
                            .netDollars
                        }
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>{t("footwear")}</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.womensFootwear.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={
                          overallCategorySummary?.womensFootwear.msrpDollars
                        }
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={
                          overallCategorySummary?.womensFootwear.netDollars
                        }
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
          </Categories>
        </SubSummary>
        {/* </TopRow> */}
        {/* <BottomRow> */}
        <SubSummary>
          <Title>{t("total")}</Title>
          <Categories>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>{t("apparel")}</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.totalApparel.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.totalApparel.msrpDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.totalApparel.netDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>{t("accessories")}</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.accessories.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.accessories.msrpDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.accessories.netDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>{t("footwear")}</CategoryTitle>
                <CategoryQty>{`${overallCategorySummary?.totalFootwear.qty} pcs`}</CategoryQty>
              </CategoryTopRow>
              <CategoryBottomRow>
                <CategoryDollars>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={
                          overallCategorySummary?.totalFootwear.msrpDollars
                        }
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.totalFootwear.netDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryDollars>
              </CategoryBottomRow>
            </Category>
            <Category>
              <CategoryTopRow>
                <CategoryTitle>TOTAL:</CategoryTitle>
                <CategoryQty>
                  {(booking?.priceToShowOrdering === "MSRP" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.totalDollars.msrpDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("MSRP")}`}</span>
                    </>
                  )}

                  {booking?.priceToShowOrdering === "MSRP+WHSL" && ` / `}
                  {(booking?.priceToShowOrdering === "WHSL" ||
                    booking?.priceToShowOrdering === "MSRP+WHSL") && (
                    <>
                      <NumberFormat
                        value={overallCategorySummary?.totalDollars.netDollars}
                        prefix='$'
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        displayType='text'
                      />
                      <span>{` ${t("WHSL")}`}</span>
                    </>
                  )}
                </CategoryQty>
              </CategoryTopRow>
            </Category>
          </Categories>
        </SubSummary>
        {/* </BottomRow> */}
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

const SubSummary = styled.div`
  /* flex-grow: 1; */
  display: grid;
  grid-template-rows: min-content 1fr;
  color: ${COLORS.text};
  padding: 5px;
  margin-top: 5px;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};
  background: white;
`;

const Title = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.small};
  border-bottom: 1px solid ${COLORS.secondary};
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const Categories = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 25px;
  font-size: ${FONTSIZE.small};
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 5px 10px 5px 0;
  margin-left: 10px;

  :not(:nth-child(3), :nth-child(4)) {
    border-bottom: 1px solid ${COLORS.secondary};
  }

  @media screen and (max-height: 900px) {
    padding: 5px;
  }
`;

const CategoryTopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const CategoryBottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 10px;
`;

const CategoryTitle = styled.div`
  text-transform: uppercase;
`;

const CategoryQty = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
  margin-left: 20px;
`;

const CategoryDollars = styled.div`
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.xsmall};
`;

export default OrderSummaryModal;
