import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  COLORS,
  FONTSIZE,
  FONTWEIGHT,
  CONSTANTS
} from "helpers/designConstants";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const MenuItem = ({
  shouldAllowSelection,
  item,
  selectionWasMade,
  depth,
  location,
  componentIndex,
  currentSelection,
  onAddArticlesInGroup,
  onRemoveArticlesInCollection,
  allArticlesInCollectionSelected,
  currentlySelectedArticleGroupKeys = [],
  seasonArticleGroupKeys = []
}) => {
  //HOOKS
  const { i18n } = useTranslation();

  //STATE
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    let selected = true;
    for (let i = depth - 1; i >= 0; i--) {
      if (currentSelection[i] !== location[i]) {
        selected = false;
        break;
      }
    }
    currentSelection[depth] === location[depth] && selected
      ? setIsSelected(true)
      : setIsSelected(false);

    if (
      item.groups != null &&
      currentlySelectedArticleGroupKeys.some((key) => key.includes(item.key))
    ) {
      setIsSelected(true);
    }
  }, [
    currentSelection,
    location,
    depth,
    item.groups,
    item.key,
    currentlySelectedArticleGroupKeys
  ]);

  const userDidSelect = (item, selection) => {
    if (!item.groups) {
      // setIsSelected(true);
      selectionWasMade(item, [...selection, componentIndex]);
    } else {
      setIsSelected(!isSelected);
    }
  };

  const userSelectedAddRemoveArticles = (itemKey) => {
    if (allArticlesInCollectionSelected) {
      onRemoveArticlesInCollection(itemKey);
    } else {
      onAddArticlesInGroup(itemKey);
    }
  };

  // console.log(
  //   seasonArticleGroupKeys.filter((collectionKey) =>
  //     collectionKey.includes(item.key)
  //   )
  // );
  // console.log(
  //   currentlySelectedArticleGroupKeys.filter((collectionKey) =>
  //     collectionKey.includes(item.key)
  //   )
  // );

  const selectionCount = currentlySelectedArticleGroupKeys.filter(
    (key) => key === item.key
  ).length;

  return (
    <IndentedGroup>
      <GroupTitleWrapper>
        <Title
          isLastIndex={!item?.groups}
          highlight={!item?.groups && isSelected}
          isSelected={isSelected}
          onClick={() => userDidSelect(item, [])}
        >
          <TitleName>
            {i18n.language === "en" ? item?.name : item?.name_fr}
            {item?.groups && !isSelected && <ArrowRightIcon />}
            {item?.groups && isSelected && <ArrowDropDownIcon />}
          </TitleName>
          {selectionCount ? (
            <TitleCount>
              {allArticlesInCollectionSelected ? "ALL" : selectionCount}
            </TitleCount>
          ) : (
            ""
          )}
        </Title>
        {shouldAllowSelection && (
          <AddRemoveButton
            isSelected={allArticlesInCollectionSelected}
            onClick={() => userSelectedAddRemoveArticles(item.key)}
          >
            {allArticlesInCollectionSelected ? <RemoveIcon /> : <AddIcon />}
          </AddRemoveButton>
        )}
      </GroupTitleWrapper>
      {item?.groups && (
        <IndentedGroupList isSelected={isSelected}>
          {item?.groups.map((item, idx) => (
            <MenuItem
              shouldAllowSelection={shouldAllowSelection}
              item={item}
              key={item?.key}
              selectionWasMade={userDidSelect}
              depth={depth + 1}
              location={[...location, idx]}
              componentIndex={idx}
              parentIndex={componentIndex}
              currentSelection={currentSelection}
              onAddArticlesInGroup={onAddArticlesInGroup}
              onRemoveArticlesInCollection={onRemoveArticlesInCollection}
              currentlySelectedArticleGroupKeys={
                currentlySelectedArticleGroupKeys
              }
              seasonArticleGroupKeys={seasonArticleGroupKeys}
              allArticlesInCollectionSelected={
                seasonArticleGroupKeys.filter((groupKey) =>
                  groupKey.includes(item.key)
                ).length > 0 &&
                seasonArticleGroupKeys.filter((collectionKey) =>
                  collectionKey.includes(item.key)
                ).length ===
                  currentlySelectedArticleGroupKeys.filter((collectionKey) =>
                    collectionKey.includes(item.key)
                  ).length
              }
            />
          ))}
        </IndentedGroupList>
      )}
    </IndentedGroup>
  );
};
const GroupTitleWrapper = styled.div`
  padding: 5px 0;
  border-bottom: 1px solid ${COLORS.secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 10px;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? COLORS.primary : COLORS.text)};
  font-weight: ${({ isSelected }) =>
    isSelected ? FONTWEIGHT.heavy : FONTWEIGHT.medium};
  background: ${({ highlight }) => highlight && COLORS.secondary};
  border-radius: 3px;
  color: ${({ highlight }) => highlight && COLORS.primary};
  font-weight: ${({ highlight }) => highlight && FONTWEIGHT.medium};

  font-size: ${({ isLastIndex }) =>
    isLastIndex ? FONTSIZE.xsmall : FONTSIZE.small};

  text-transform: uppercase;
`;

const TitleName = styled.div`
  display: flex;
  align-items: center;
`;

const TitleCount = styled.div`
  color: ${COLORS.alertOrange};
  background: ${COLORS.primaryInverted};
  border: 1px solid ${COLORS.alertOrange};
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: ${FONTWEIGHT.heavy};
`;

const AddRemoveButton = styled.div`
  margin: 5px 5px 5px 10px;
  width: 30px;
  height: 30px;
  border: 2px solid
    ${(props) => (props.isSelected ? COLORS.alertRed : COLORS.select)};
  border-radius: ${CONSTANTS.borderRadius};
  color: ${(props) => (props.isSelected ? COLORS.alertRed : COLORS.select)};
  /* align-self: flex-end; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      background: ${(props) =>
        props.isSelected ? COLORS.alertRed : COLORS.select};
      color: white;
    }
  }
`;

const IndentedGroup = styled.li`
  margin-left: 10px;
`;

const IndentedGroupList = styled.ul`
  list-style-type: none;
  padding: 0;
  background: none;
  max-height: ${({ isSelected }) => (isSelected ? "4000px" : "0px")};
  /* opacity: ${({ isSelected }) => (isSelected ? "1.0" : "0.0")}; */
  overflow: hidden;
  transition: all 0.4s ease-in-out;
`;

export default MenuItem;
