import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AdminDropdownMenu from "./adminMenu/AdminDropdownMenu";
import LanguageDropdownMenu from "../LanguageDropdownMenu";
import TabView from "./tabs/TabView";

import { isMobile } from "react-device-detect";

import { useDatabaseReader } from "database/useDatabaseReader";

import { useNavigate, useLocation } from "react-router-dom";
import useNavigation from "helpers/useNavigation";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "database/useAuth";

//Import Helpers
import {
  COLORS,
  CONSTANTS,
  FONTSIZE,
  FONTWEIGHT
} from "helpers/designConstants";

const DashboardPageLayout = ({
  title,
  subTitle,
  selectedTabIndex,
  children
}) => {
  //HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [navigateForward] = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { courses } = useDatabaseReader();
  const { user } = useAuth();

  //LOCAL STATE
  const [showBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    if (location.pathname === "/reps/mainDashboard") {
      setShowBackButton(false);
    } else {
      setShowBackButton(true);
    }
  }, [location.pathname]);

  //NAVIGATION
  const onBackButtonPress = () => {
    // if (
    //   location.state?.previousLocation &&
    //   location.state.previousLocation.length > 0
    // ) {
    //   let previousLocation = [...location.state.previousLocation];
    //   const locationToReturnTo = previousLocation.pop();
    //   console.log(locationToReturnTo);
    //   navigate(locationToReturnTo, {
    //     state: { previousLocation: previousLocation }
    //   });
    // }
    navigate(-1);
  };

  const onClickUploadData = () => {
    navigateForward("/upload");
  };

  return (
    <Container isMobile={isMobile}>
      <Header userEnv={process.env.REACT_APP_USER_ENVIRONMENT}>
        <LeftSideHeader>
          {showBackButton ? (
            <NavText onClick={() => onBackButtonPress()}>
              <ArrowBackIosIcon style={{ width: "18px" }} />
              {t("back_button")}
            </NavText>
          ) : (
            ""
          )}
        </LeftSideHeader>
        <HeaderTitle>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </HeaderTitle>
        <RightSideHeader>
          <LanguageDropdownMenu />
          <Spacer />
          <AdminDropdownMenu didSelectUploadData={onClickUploadData} />
        </RightSideHeader>
      </Header>

      <TabView selectedTabIndex={selectedTabIndex}>{children}</TabView>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 98%;
  max-width: ${({ isMobile }) => (isMobile ? "unset" : "1350px")};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  justify-items: center;
  justify-content: center;
  padding: 0 0 30px 0;
`;

const Header = styled.div`
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ userEnv }) =>
    userEnv === "dev"
      ? "mistyrose"
      : userEnv === "local"
      ? "honeydew"
      : COLORS.tertiary};
  margin-bottom: 15px;
  border-radius: ${CONSTANTS.borderRadius};
  border: ${({ userEnv }) =>
    userEnv === "dev"
      ? `1px solid ${COLORS.alertRed}`
      : userEnv === "local" && `1px solid ${COLORS.green}`};
`;

const LeftSideHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-basis: 0;
  flex-grow: 1;
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-transform: uppercase;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.large};
  font-weight: ${FONTWEIGHT.heavy};
  text-align: center;
  flex-basis: 0;
  flex-grow: 5;
`;

const SubTitle = styled.div`
  text-transform: uppercase;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.medium};
  text-align: center;
  flex-basis: 0;
  flex-grow: 5;
`;

const RightSideHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* padding-top: 10px; */
  flex-basis: 0;
  flex-grow: 1;
`;
const Spacer = styled.div`
  width: 1px;
  height: 20px;
  background: ${COLORS.text};
  margin: 0 10px;
`;

const NavText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.medium};
  font-weight: ${FONTWEIGHT.heavy};
  transition: 0.3s;
  text-transform: uppercase;

  &:hover {
    color: ${COLORS.primaryHover};
  }
`;

export default DashboardPageLayout;
