import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import BookingPublicLayout from "../bookingPublic/BookingPublicLayout";
import {
  CONSTANTS,
  COLORS,
  FONTWEIGHT,
  FONTSIZE
} from "helpers/designConstants";
import { useSearchParams } from "react-router-dom";
import { useCatalogueDatabase } from "database/useCatalogueDatabase";
import { useArticleReader } from "database/articles/useArticleReader";
import { cloneDeep } from "lodash";
import ArticleZoomModal from "components/shared/articleZoomModal/ArticleZoomModal";
import InventoryModal from "components/shared/inventoryModal/InventoryModal";
import useArticleZoomModal from "components/shared/articleZoomModal/useArticleZoomModal";
import useInventoryModal from "components/shared/inventoryModal/useInventoryModal";
import ArticleCard from "components/shared/ArticleCard";

import { GroupedVirtuoso } from "react-virtuoso";

import Loading from "components/shared/Loading";

const CatalogueViewer = () => {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { loginViewer, catalogue, pageError } = useCatalogueDatabase();
  const { schemas } = useArticleReader();
  const {
    toggleArticleZoomModal,
    isZoomModalShowing,
    onZoomArticle,
    zoomArticle,
    zoomArticleImages
  } = useArticleZoomModal();
  const {
    isInventoryModalShowing,
    toggleInventoryModal,
    inventoryArticle,
    onViewInventory
  } = useInventoryModal();

  const [viewerLoggedIn, setViewerLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sections, setSections] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    if (!viewerLoggedIn) {
      loginViewer(currentParams.ctgid).then(() => {
        setViewerLoggedIn(true);
      });
    }
  }, [searchParams, loginViewer, viewerLoggedIn]);

  //Parse the selected articles and create sections to display
  useEffect(() => {
    if (
      Object.keys(schemas).length === 0 ||
      catalogue == null ||
      catalogue.articles == null
    )
      return;

    let constructedSections = [];
    let constructedArticles = [];

    //Filter out existing collection keys to reduce down the sections
    let existingKeys = [];
    if (catalogue?.sortOrder === "collection") {
      existingKeys = catalogue.articles
        ?.map((article) =>
          article[`${catalogue.seasonKey}_collectionKeys`].map((key) => key)
        )
        ?.reduce((flatten, arr) => [...flatten, ...arr], []);
    } else if (catalogue?.sortOrder === "category") {
      existingKeys = catalogue.articles?.map(
        (article) => article[`${catalogue.seasonKey}_generalCategoryKey`]
      );
    }

    //currentLocation is used in the loop below to track what the section titles should be
    let currentLocation = [];

    //Recursion function to be used below to determine what Nav Menu items to show
    const processSchemaItem = (item) => {
      item.groups = item.groups.filter((groupItem) =>
        existingKeys.some((key) => key.includes(groupItem.key))
      );
      item.groups.forEach((groupItem, index) => {
        if (groupItem.groups != null) {
          currentLocation.push(
            i18n.language === "en" ? groupItem.name : groupItem.name_fr
          );
          currentLocation.push(" ‣ ");
          processSchemaItem(groupItem, index);
        } else {
          currentLocation.push(groupItem.name);
          let section = {};
          section.location = [...currentLocation];
          section.locationKey = groupItem.key;

          if (catalogue?.sortOrder === "collection") {
            section.articles = [
              ...catalogue.articles.filter((article) =>
                article[`${catalogue.seasonKey}_collectionKeys`].includes(
                  groupItem.key
                )
              )
            ];
            section.articles.sort((a, b) =>
              a[`${catalogue.seasonKey}_${groupItem.key}_sort`] >
              b[`${catalogue.seasonKey}_${groupItem.key}_sort`]
                ? 1
                : a[`${catalogue.seasonKey}_${groupItem.key}_sort`] <
                  b[`${catalogue.seasonKey}_${groupItem.key}_sort`]
                ? -1
                : 0
            );
          } else if (catalogue?.sortOrder === "category") {
            section.articles = [
              ...catalogue?.articles.filter((article) =>
                article[`${catalogue.seasonKey}_generalCategoryKey`].includes(
                  groupItem.key
                )
              )
            ];
            section.articles.sort((a, b) =>
              a.retailPrice < b.retailPrice
                ? 1
                : a.retailPrice > b.retailPrice
                ? -1
                : a.articleName.toLowerCase() > b.articleName.toLowerCase()
                ? 1
                : a.articleName.toLowerCase() < b.articleName.toLowerCase()
                ? -1
                : 0
            );
          }

          currentLocation.pop();
          constructedSections.push(section);
          constructedArticles.push(section.articles);
          // section.articles.forEach((article) =>
          //   constructedArticles.push(article)
          // );
        }
      });
      currentLocation.pop();
      currentLocation.pop();
    };

    // let filteredSchema = cloneDeep(schemas[booking.seasonKey].schemas).filter(
    //   (schema) => existingKeys.some((key) => key.includes(schema.key))
    // );

    let filteredSchema;

    if (catalogue?.sortOrder === "collection") {
      filteredSchema = cloneDeep(schemas[catalogue.seasonKey].schemas).filter(
        (schema) => existingKeys.some((key) => key.includes(schema.key))
      );
    } else if (catalogue?.sortOrder === "category") {
      filteredSchema = cloneDeep(
        schemas[catalogue.seasonKey].categorySchemas
      ).filter((schema) =>
        existingKeys.some((key) => key.includes(schema.key))
      );
    }

    filteredSchema.forEach((schema) => {
      currentLocation.push(catalogue.seasonName);
      currentLocation.push(" ‣ ");
      currentLocation.push(schema.name);
      currentLocation.push(" ‣ ");
      processSchemaItem(schema);
    });
    setSections(constructedSections);
    setArticles(constructedArticles);
  }, [catalogue, schemas]);

  return (
    <BookingPublicLayout title={catalogue?.name}>
      <Wrapper>
        <Loading isLoading={loading} />
        <CartScrollArea>
          {pageError ? (
            <PageError>This Catalogue is no longer available</PageError>
          ) : (
            <GroupedVirtuoso
              style={{ height: "100%" }}
              groupCounts={sections.map((section) => 1)}
              groupContent={(index) => {
                const header = sections[index];
                return (
                  <SectionHeader index={index}>
                    <HeaderBreadCrumbs>
                      {header.location.map((text, index) => (
                        <span key={`crubms-${header.locationKey}-${index}`}>
                          {text}
                        </span>
                      ))}
                    </HeaderBreadCrumbs>
                  </SectionHeader>
                );
              }}
              itemContent={(index, groupIndex) => {
                const sectionArticles = articles[index];
                return (
                  <SectionWrapper>
                    {sectionArticles?.map((article) => (
                      <ArticleCard
                        key={article.id}
                        article={article}
                        showInventoryButton
                        shouldShowLimitedSoldOut
                        priceToShow={catalogue?.priceToShowPreview}
                        onZoomArticle={onZoomArticle}
                        onViewInventory={onViewInventory}
                      />
                    ))}
                  </SectionWrapper>
                );
              }}
            />
          )}
        </CartScrollArea>
      </Wrapper>
      <ArticleZoomModal
        show={isZoomModalShowing}
        onClose={toggleArticleZoomModal}
        imageList={zoomArticleImages}
        article={zoomArticle}
      />
      <InventoryModal
        show={isInventoryModalShowing}
        onClose={toggleInventoryModal}
        article={inventoryArticle}
      />
    </BookingPublicLayout>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const CartScrollArea = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: ${COLORS.tertiary};
  border-radius: 6px;
`;

const PageError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${COLORS.text};
  font-size: ${FONTSIZE.large};
`;

const SectionHeader = styled.div`
  z-index: 15;
  width: 100%;
  padding: 10px 5px;
  /* margin-top: ${({ index }) => (index > 0 ? "50px" : "unset")}; */
  background-color: ${COLORS.secondary};
  /* border-bottom: 3px solid ${COLORS.secondary}; */
  /* position: sticky; */
  /* top: 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 5px lightgrey;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const HeaderBreadCrumbs = styled.div`
  color: ${COLORS.text};
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  padding-left: 10px;
  display: flex;
  align-items: center;

  span {
    padding: 3px;
    :last-child {
      color: ${COLORS.primary};
      font-size: ${FONTSIZE.medium};
    }
  }
`;

const SectionWrapper = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 0.33333fr));
  gap: 5px 5px;
`;

export default CatalogueViewer;
