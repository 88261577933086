import { useMemo, useCallback } from "react";
import { useAuth } from "../useAuth";
import { useDatabaseReader } from "database/useDatabaseReader";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  Timestamp,
  updateDoc
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseRandomID } from "helpers/helperFunctions";
import { debounce } from "lodash";

const useBookingWriter = () => {
  //HOOKS
  const { user } = useAuth();
  const { selectedBooking, contactsDocID } = useDatabaseReader();

  const createNewBooking = async ({
    bookingName,
    courseName,
    priceToShowPreview,
    priceToShowOrdering,
    sortOrder,
    seasonOptions
  }) => {
    //Booking
    let booking = {
      name: bookingName,
      courseName: courseName,
      seasonKey: seasonOptions.seasonKey,
      seasonName: seasonOptions.seasonName,
      seasonName_fr: seasonOptions.seasonName_fr,
      seasonSiloKey: seasonOptions.siloKey,
      seasonSiloName: seasonOptions.siloName,
      seasonSiloName_fr: seasonOptions.siloName_fr,
      articleIDs: [],
      priceToShowPreview: priceToShowPreview,
      priceToShowOrdering: priceToShowOrdering,
      sortOrder: sortOrder,
      creationDate: new Date(),
      linkedContactIDs: [],
      shareTracking: {},
      isComplete: false,
      viewerUID: firebaseRandomID(),
      purchaserUID: firebaseRandomID(),
      adidasRepIDs: [user.uid]
    };

    //Create Booking and update user's contacts
    await updateDoc(doc(getFirestore(), "bookingContacts", contactsDocID), {
      courseNames: arrayUnion(courseName)
    });

    const docRef = await addDoc(
      collection(getFirestore(), "bookings"),
      booking
    );
    return docRef.id;
  };

  const updateBookingDetails = async (
    {
      bookingName,
      courseName,
      priceToShowPreview,
      priceToShowOrdering,
      sortOrder,
      seasonOptions
    },
    resetArticles
  ) => {
    let booking = {
      name: bookingName,
      courseName: courseName,
      priceToShowPreview: priceToShowPreview,
      priceToShowOrdering: priceToShowOrdering,
      sortOrder: sortOrder,
      seasonKey: seasonOptions.seasonKey,
      seasonName: seasonOptions.seasonName,
      seasonName_fr: seasonOptions.seasonName_fr,
      seasonSiloKey: seasonOptions.siloKey,
      seasonSiloName: seasonOptions.siloName,
      seasonSiloName_fr: seasonOptions.siloName_fr
    };
    // if (resetArticles) {
    //   booking.articleIDs = [];
    // }

    return await updateDoc(
      doc(getFirestore(), "bookings", selectedBooking.id),
      booking
    );
  };

  const updateRemoteBookingArticleIDs = useMemo(
    () =>
      debounce(
        async (articleIDs) => {
          await updateDoc(
            doc(getFirestore(), "bookings", selectedBooking?.id),
            {
              articleIDs: articleIDs
            }
          );
        },
        2000,
        { maxWait: 6000 }
      ),
    [selectedBooking?.id]
  );

  const addLinkedContactID = async (contactID) => {
    await updateDoc(doc(getFirestore(), "bookings", selectedBooking.id), {
      linkedContactIDs: arrayUnion(contactID)
    });
  };

  const removeLinkedContactID = async (contactID) => {
    await updateDoc(doc(getFirestore(), "bookings", selectedBooking.id), {
      linkedContactIDs: arrayRemove(contactID)
    });
  };

  const shareBookingWithContacts = async (contacts, sendPasscode) => {
    const shareBooking = httpsCallable(getFunctions(), "http-shareBooking");

    //Not awaiting this function as we are just using optimism that it succeeds
    //Done this way as its calling a cloud function and this could take a while to return
    shareBooking({
      booking: selectedBooking,
      recipients: contacts,
      sendPasscode
    });

    //Update the booking for tracking last sent dates
    let updates = {};

    contacts.forEach((contact) => {
      updates[`shareTracking.${contact.id}`] = {
        lastSent: Timestamp.fromDate(new Date())
      };
    });
    await updateDoc(doc(getFirestore(), "bookings", selectedBooking.id), {
      ...updates,
      isComplete: true
    });
    return { success: true };
  };

  const markBookingComplete = useCallback(async () => {
    await updateDoc(doc(getFirestore(), "bookings", selectedBooking?.id), {
      isComplete: true
    });
  }, [selectedBooking?.id]);

  const markBookingShared = useCallback(async () => {
    await updateDoc(doc(getFirestore(), "bookings", selectedBooking?.id), {
      "shareTracking.manual": { lastSent: new Date() },
      isComplete: true
    });
  }, [selectedBooking?.id]);

  const deleteBooking = async (bookingID) => {
    await deleteDoc(doc(getFirestore(), "bookings", bookingID));
  };

  return {
    createNewBooking,
    updateBookingDetails,
    updateRemoteBookingArticleIDs,
    addLinkedContactID,
    removeLinkedContactID,
    shareBookingWithContacts,
    markBookingComplete,
    markBookingShared
  };
};

export default useBookingWriter;
