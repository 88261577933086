import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  CONSTANTS,
  COLORS,
  FONTWEIGHT,
  FONTSIZE
} from "helpers/designConstants";

import { useDatabaseReader } from "database/useDatabaseReader";
import useCatalogueSearchReader from "database/catalogues/useCatalogueSearchReader";
import useCatalogueWriter from "database/catalogues/useCatalogueWriter";

import Loading from "components/shared/Loading";
import ScrollArea from "components/shared/ScrollArea";
import SearchBar from "components/shared/SearchBar";
import { Button, MiniButton } from "components/shared/standardElements";
import CreateEditCatalogueModal from "./CreateEditCatalogueModal";
import CatalogueCard from "./CatalogueCard";
import CatalogueProductPickerModal from "./catalogueProductPickerModal/CatalogueProductPickerModal";
import { copyToClipboard } from "helpers/copyToClipboard";
import { toast } from "react-toastify";
import ConfirmModal from "components/shared/ConfirmModal";

const sortCatalogues = (catalogues, sortBy) => {
  if (sortBy === "date") {
    return catalogues.sort((a, b) =>
      a.creationDate < b.creationDate
        ? 1
        : a.creationDate > b.creationDate
        ? -1
        : 0
    );
  } else if (sortBy === "alpha") {
    return catalogues.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : 0
    );
  }
};

const CatalogueCreatorTab = () => {
  //HOOKS
  const { t } = useTranslation();
  const { cataloguesSearchAPIKey } = useDatabaseReader();
  const { searchForCatalogue } = useCatalogueSearchReader();
  const { createNewCatalogue, updateCatalogueDetails, deleteCatalogue } =
    useCatalogueWriter();

  //STATE
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("date");
  const [showCreateCatalogueModal, setShowCreateCatalogueModal] =
    useState(false);
  const [catalogueToEdit, setCatalogueToEdit] = useState();
  const [catalogueForProductPickerModal, setCatalogueForPrductPickerModal] =
    useState();
  const [catalogueToDelete, setCatalogueToDelete] = useState();
  const [filteredCatalogues, setFilteredCatalogues] = useState([]);

  useEffect(() => {
    if (cataloguesSearchAPIKey == null) return;
    searchForCatalogue("*").then((result) => {
      const sortedResult = sortCatalogues(result, sortBy);
      setFilteredCatalogues(sortedResult);
    });
  }, [searchForCatalogue, cataloguesSearchAPIKey, sortBy]);

  //METHODS
  const onSearchBarChanged = async (searchTerm) => {
    searchForCatalogue(searchTerm).then((result) => {
      const sortedResult = sortCatalogues(result, sortBy);
      setFilteredCatalogues(sortedResult);
    });
  };

  const onCreateUpdateCatalogue = async (data) => {
    setShowCreateCatalogueModal(false);
    setIsLoading(true);
    if (catalogueToEdit == null) {
      const newCatalogue = await createNewCatalogue(data);

      setFilteredCatalogues((prev) =>
        sortCatalogues([...prev, newCatalogue], sortBy)
      );
    } else {
      //Update the local catalogue in this component
      //Done because this is not refetched from Typesense
      const catalogueIndex = filteredCatalogues.findIndex(
        (catalogue) => catalogue.id === catalogueToEdit.id
      );
      const updatedCatalogue = {
        ...filteredCatalogues[catalogueIndex],
        name: data.catalogueName,
        priceToShowPreview: data.priceToShowPreview,
        sortOrder: data.sortOrder,
        seasonKey: data.seasonOptions.seasonKey,
        seasonName: data.seasonOptions.seasonName,
        seasonName_fr: data.seasonOptions.seasonName_fr,
        seasonSiloKey: data.seasonOptions.siloKey,
        seasonSiloName: data.seasonOptions.siloName,
        seasonSiloName_fr: data.seasonOptions.siloName_fr
      };
      const catalogues = [...filteredCatalogues];
      catalogues[catalogueIndex] = updatedCatalogue;
      setFilteredCatalogues(catalogues);

      //Update Firebase Catalogue details which will updated Typesense
      await updateCatalogueDetails(data, catalogueToEdit.id);
      setCatalogueToEdit();
    }
    setIsLoading(false);
  };

  const onCopyLink = (catalogue) => {
    let domain = "";
    if (window.location.hostname === "localhost") {
      domain = "http://localhost:3000";
    } else {
      domain = `https://${window.location.hostname}`;
    }
    // navigator.clipboard.writeText(
    //   `${domain}/ext/preview?bid=${encodeURIComponent(bookingID)}`
    // );
    copyToClipboard(
      `${domain}/catalogue/viewer?ctgid=${encodeURIComponent(catalogue.id)}`
    );
    toast.success(t("copied_invite_link_message", { name: catalogue.name }));
  };

  const onConfirmCatalogueDeletion = async () => {
    setIsLoading(true);
    await deleteCatalogue(catalogueToDelete.id);
    setFilteredCatalogues((prev) =>
      prev.filter((catalogue) => catalogue.id !== catalogueToDelete.id)
    );
    setIsLoading(false);
    setCatalogueToDelete();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container>
        <Header>
          <SearchSection>
            <StyledSearchBar
              placeholder={`${t("search")}...`}
              searchTermChanged={onSearchBarChanged}
            />
            <StyledButton
              primary
              fontsize='small'
              onClick={() => setShowCreateCatalogueModal(true)}
            >
              {`+ ${t("create_catalogue")}`}
            </StyledButton>
          </SearchSection>
          <SortSection>
            {`${t("sort")}:`}
            <MiniButton
              highlighted={sortBy === "date"}
              onClick={() => setSortBy("date")}
            >
              {t("date")}
            </MiniButton>
            <MiniButton
              highlighted={sortBy === "alpha"}
              onClick={() => setSortBy("alpha")}
            >
              {t("name_title")}
            </MiniButton>
          </SortSection>
        </Header>
        <StyledScrollArea>
          {filteredCatalogues.map((catalogue) => (
            <CatalogueCard
              key={catalogue.id}
              catalogue={catalogue}
              onEditArticlesForCatalogue={setCatalogueForPrductPickerModal}
              onEditCatalogueDetails={setCatalogueToEdit}
              onCopyLink={onCopyLink}
              onDeleteCatalogue={setCatalogueToDelete}
            />
          ))}
        </StyledScrollArea>
      </Container>
      <CreateEditCatalogueModal
        title={
          catalogueToEdit == null ? t("new_catalogue") : t("edit_catalogue")
        }
        catalogue={catalogueToEdit}
        show={showCreateCatalogueModal || catalogueToEdit != null}
        onClose={() => {
          setShowCreateCatalogueModal(false);
          setCatalogueToEdit();
        }}
        onCreateUpdateCatalogue={onCreateUpdateCatalogue}
      />
      <CatalogueProductPickerModal
        show={catalogueForProductPickerModal != null}
        onClose={() => setCatalogueForPrductPickerModal()}
        catalogueID={catalogueForProductPickerModal?.id}
      />
      <ConfirmModal
        show={catalogueToDelete != null}
        onClose={() => setCatalogueToDelete()}
        title={t("delete_catalogue")}
        message={t("confirm_catalogue_delete", {
          name: catalogueToDelete?.name
        })}
        isDestructive
        buttonTitle={t("delete_button")}
        onConfirm={onConfirmCatalogueDeletion}
      />
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: ${CONSTANTS.borderRadius} ${CONSTANTS.borderRadius} 0 0;
  border-bottom: 2px solid ${COLORS.secondary};
  background: ${COLORS.tertiary};
  box-shadow: 0px 2px 5px ${COLORS.secondary};
  z-index: 1;
`;

const SearchSection = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSearchBar = styled(SearchBar)`
  min-width: 325px;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  text-transform: uppercase;
`;

const SortSection = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.text};
  font-weight: ${FONTWEIGHT.heavy};
  font-size: ${FONTSIZE.small};
  text-transform: uppercase;
  padding: 10px 5px 5px;

  button {
    margin-left: 10px;
  }
`;

const StyledScrollArea = styled(ScrollArea)`
  grid-template-columns: repeat(auto-fit, minmax(300px, 0.3333fr));
`;

export default CatalogueCreatorTab;
