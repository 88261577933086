import React from "react";
import styled from "styled-components/macro";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import useWindowSize from "helpers/useWindowSize";

import { COLORS } from "helpers/designConstants";

const ArticleImageGallery = ({ imageList }) => {
  const { width, height } = useWindowSize();
  return (
    <Content>
      <ImageWrapper shouldBeFullHeight={width - 100 > height}>
        <ImageGallery
          lazyLoad={true}
          showPlayButton={false}
          showFullscreenButton={width < 550 ? false : true}
          thumbnailPosition={width - 100 > height ? "left" : "bottom"}
          disableThumbnailScroll
          items={imageList}
        />
      </ImageWrapper>
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  && .image-gallery-icon {
    filter: drop-shadow(0 0px 2px ${COLORS.text});
    opacity: 0.5;
    z-index: 5;

    :hover {
      color: ${COLORS.tertiary};
    }
  }

  && .image-gallery-icon.image-gallery-left-nav {
    .image-gallery-svg {
      height: 80px;
    }
  }
  && .image-gallery-icon.image-gallery-right-nav {
    .image-gallery-svg {
      height: 80px;
    }
  }

  && .image-gallery-fullscreen-button {
    opacity: 1;
  }

  .image-gallery {
    width: 100%;
  }

  && .image-gallery-thumbnail {
    border: 2px solid transparent;
    border-radius: 5px;

    .image-gallery-thumbnail-inner {
      padding: 2px;
    }
  }
  && .image-gallery-thumbnail.active {
    border: 2px solid ${COLORS.secondary};
  }

  .image-gallery-content {
    padding: 10px;
    display: ${({ shouldBeFullHeight }) => !shouldBeFullHeight && "grid"};
    grid-template-rows: ${({ shouldBeFullHeight }) =>
      !shouldBeFullHeight && "1fr max-content"};
    overflow: hidden;
  }

  .image-gallery-content.fullscreen {
    background: white;
  }

  && .image-gallery-slide {
    display: flex !important;
  }

  .image-gallery-slide-wrapper {
    height: 100%;
    overflow: hidden;
  }

  .image-gallery,
  .image-gallery-content,
  .image-gallery-swipe,
  .image-gallery-slides,
  .image-gallery-slide {
    height: 100%;
  }

  &&& .image-gallery-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

export default ArticleImageGallery;
