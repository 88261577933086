import { useCallback } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";

const useNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateForward = useCallback(
    (pathname, newSearchParams) => {
      // let previousLocation = location.state?.previousLocation
      //   ? [...location.state?.previousLocation]
      //   : [];
      // previousLocation.push(`${location.pathname}${location.search}`);

      navigate(
        {
          pathname: pathname,
          search: `?${createSearchParams(newSearchParams)}`
        }
        // { state: { previousLocation: previousLocation } }
      );
    },
    [navigate]
  );
  return [navigateForward];
};

export default useNavigation;
