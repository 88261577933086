import { useCallback } from "react";
import { catalogueSearchClient } from "services/typesense";
import { useDatabaseReader } from "database/useDatabaseReader";

const useCatalogueSearchReader = () => {
  const { cataloguesSearchAPIKey } = useDatabaseReader();

  const searchForCatalogue = useCallback(
    async (searchTerm) => {
      let searchParameters = {
        q: searchTerm,
        query_by: "name",
        per_page: 50,
        exhaustive_search: true,
        num_typos: 0,
        typo_tokens_threshold: 0
      };

      const client = catalogueSearchClient(cataloguesSearchAPIKey);
      const results = await client
        .collections(
          `${process.env.REACT_APP_TYPESENSE_COLLECTION_PREFIX}.catalogues`
        )
        .documents()
        .search(searchParameters);

      return results.hits.map((hit) => hit.document);
    },
    [cataloguesSearchAPIKey]
  );

  return {
    searchForCatalogue
  };
};

export default useCatalogueSearchReader;
