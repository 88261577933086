import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Modal from "components/shared/modal/Modal";
import { COLORS, CONSTANTS } from "helpers/designConstants";

const UserTypePickerModal = ({ userChoseUserType, userTypes, ...props }) => {
  const { t } = useTranslation();

  const userTypeMap = {
    adidasRep: t("user_type_adidas_rep"),
    eventManager: t("user_type_event_manager"),
    courseAdmin: t("user_type_course_admin")
  };

  return (
    <Modal
      closeButtonHidden={true}
      disableCloseOnOutsideClick={true}
      {...props}
      title={t("login_usertype_title")}
    >
      <Container>
        <Message>
          <p>{t("login_usertype_message_1")}</p>
          <p>{t("login_usertype_message_2")}</p>
        </Message>
        <UserTypeList>
          {userTypes &&
            userTypes
              .filter((type) => type !== "adidasManager" && type !== "god")
              .map((type) => (
                <UserTypeRow key={type} onClick={() => userChoseUserType(type)}>
                  <div>{userTypeMap[type]}</div>
                </UserTypeRow>
              ))}
        </UserTypeList>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.div`
  color: ${COLORS.text};
  text-align: center;
`;

const UserTypeList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  /* border-top: 1px solid ${COLORS.secondary}; */
  margin: 20px 0;
`;

const UserTypeRow = styled.li`
  text-transform: uppercase;
  border-bottom: 1px solid ${COLORS.secondary};
  padding: 5px;
  color: ${COLORS.text};
  cursor: pointer;
  :last-child {
    border-bottom: unset;
  }

  div {
    padding: 10px 30px;
    border-radius: ${CONSTANTS.borderRadius};
    :hover {
      background: ${COLORS.tertiary};
    }
  }
`;

const ButtonRow = styled.div`
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export default UserTypePickerModal;
