import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import BookingPublicLayout from "./BookingPublicLayout";
import {
  CONSTANTS,
  COLORS,
  FONTWEIGHT,
  FONTSIZE
} from "helpers/designConstants";
import { TextInput } from "components/shared/standardElements";
import { useSearchParams } from "react-router-dom";
import { useCourseDatabase } from "database/useCourseDatabase";
import { useArticleReader } from "database/articles/useArticleReader";
import { cloneDeep } from "lodash";
import ArticleZoomModal from "components/shared/articleZoomModal/ArticleZoomModal";
import InventoryModal from "components/shared/inventoryModal/InventoryModal";
import useArticleZoomModal from "components/shared/articleZoomModal/useArticleZoomModal";
import useInventoryModal from "components/shared/inventoryModal/useInventoryModal";
import ArticleCard from "components/shared/ArticleCard";

import { GroupedVirtuoso } from "react-virtuoso";

import Loading from "components/shared/Loading";
import LoginIcon from "@mui/icons-material/Login";

import { useAuth } from "database/useAuth";
import useNavigation from "helpers/useNavigation";

const BookingPreview = () => {
  const { login } = useAuth();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { loginViewer, booking } = useCourseDatabase();
  const { schemas, overallCategories } = useArticleReader();
  const {
    toggleArticleZoomModal,
    isZoomModalShowing,
    onZoomArticle,
    zoomArticle,
    zoomArticleImages
  } = useArticleZoomModal();
  const {
    isInventoryModalShowing,
    toggleInventoryModal,
    inventoryArticle,
    onViewInventory
  } = useInventoryModal();

  const [navigateForward] = useNavigation();

  const PasscodeFormRef = useRef();

  const [viewerLoggedIn, setViewerLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passcodeInput, setPasscodeInput] = useState("");
  const [passcodeError, setPasscodeError] = useState(false);

  const [sections, setSections] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    if (!viewerLoggedIn) {
      loginViewer(currentParams.bid).then(() => {
        setViewerLoggedIn(true);
      });
    }
  }, [searchParams, loginViewer, viewerLoggedIn]);

  // //LEGACY CODE
  // Created a left side filter bar
  // KEEPING IN CASE WE WANT TO IMPLEMENT THIS... IT WAS FUCKING CONFUSING
  // //Filter down the navigation menu to only show pertinent categories to the article selections
  // useEffect(() => {
  //   if (
  //     Object.keys(schemas).length === 0 ||
  //     Object.keys(overallCategories).length === 0 ||
  //     booking == null
  //   )
  //     return;

  //   let filteredSchema = [];
  //   if (booking.sortOrder === "collection") {
  //     filteredSchema = cloneDeep(schemas[booking.seasonKey].schemas);
  //   } else {
  //     const categories = Object.entries(overallCategories)
  //       .map(([key, data]) => ({ ...data, key: key }))
  //       .sort((a, b) =>
  //         a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0
  //       );
  //     filteredSchema = cloneDeep(categories);
  //   }

  //   let existingKeys = [];
  //   if (booking?.sortOrder === "collection") {
  //     existingKeys = articles
  //       ?.map((article) =>
  //         article[`${booking.seasonKey}_collectionKeys`].map((key) => key)
  //       )
  //       ?.reduce((flatten, arr) => [...flatten, ...arr], []);
  //   } else {
  //     existingKeys = articles?.map((article) => article.overallCategoryKey);
  //   }

  //   //Recursion function to be used below to determine what Nav Menu items to show
  //   const processSchemaItem = (item) => {
  //     item.groups = item.groups.filter((groupItem) =>
  //       existingKeys.some((key) => key.includes(groupItem.key))
  //     );
  //     item.groups.forEach((groupItem, index) => {
  //       if (groupItem.groups != null) {
  //         processSchemaItem(groupItem, index);
  //       }
  //     });
  //   };

  //   if (booking.sortOrder === "collection") {
  //     //Start the loop and use the above recursion function to process what should be shown
  //     filteredSchema.forEach((schema) => {
  //       if (!existingKeys.some((key) => key.includes(schema.key))) {
  //         filteredSchema.splice(0, 1);
  //       }
  //       processSchemaItem(schema);
  //     });
  //   } else {
  //     filteredSchema = filteredSchema.filter((item) =>
  //       existingKeys.includes(item.key)
  //     );
  //   }

  //   setFilteredSchema(filteredSchema);
  // }, [schemas, overallCategories, articles, booking]);

  //Get the initial selection
  //Iterate through the nesting and pick the first item in each level

  // useEffect(() => {
  //   if (filteredSchema.length === 0) return;
  //   let isBottom = false;
  //   let selection = [];
  //   let currentDepth = filteredSchema[0];
  //   while (!isBottom) {
  //     if (currentDepth.groups != null) {
  //       selection.push(0);
  //       currentDepth = currentDepth.groups[0];
  //     } else {
  //       selection.push(0);
  //       isBottom = true;
  //       userMadeSelection(currentDepth);
  //     }
  //   }
  //   setInitialSelection(selection);
  // }, [filteredSchema, userMadeSelection]);

  const submitOrderPasscode = async (e) => {
    e.preventDefault();
    try {
      // User is returned on login but don't need it here
      setLoading(true);
      await login(`purchaser@${booking.id}.com`, passcodeInput, false);
      setLoading(false);
      navigateForward("/ext/private/orderDashboard", {
        bid: booking?.id
      });
    } catch (error) {
      setLoading(false);
      if (error) setPasscodeError(true);
      console.log(error);
      // toast.error(Errors[error.code]);
    }
  };

  //Parse the selected articles and create sections to display
  useEffect(() => {
    if (
      Object.keys(schemas).length === 0 ||
      booking == null ||
      booking.articles == null
    )
      return;

    let constructedSections = [];
    let constructedArticles = [];

    //Filter out existing collection keys to reduce down the sections
    let existingKeys = [];
    if (booking?.sortOrder === "collection") {
      existingKeys = booking.articles
        ?.map((article) =>
          article[`${booking.seasonKey}_collectionKeys`].map((key) => key)
        )
        ?.reduce((flatten, arr) => [...flatten, ...arr], []);
    } else if (booking?.sortOrder === "category") {
      existingKeys = booking.articles?.map(
        (article) => article[`${booking.seasonKey}_generalCategoryKey`]
      );
    }

    //currentLocation is used in the loop below to track what the section titles should be
    let currentLocation = [];

    //Recursion function to be used below to determine what Nav Menu items to show
    const processSchemaItem = (item) => {
      item.groups = item.groups.filter((groupItem) =>
        existingKeys.some((key) => key.includes(groupItem.key))
      );
      item.groups.forEach((groupItem, index) => {
        if (groupItem.groups != null) {
          currentLocation.push(
            i18n.language === "en" ? groupItem.name : groupItem.name_fr
          );
          currentLocation.push(" ‣ ");
          processSchemaItem(groupItem, index);
        } else {
          currentLocation.push(groupItem.name);
          let section = {};
          section.location = [...currentLocation];
          section.locationKey = groupItem.key;

          if (booking?.sortOrder === "collection") {
            section.articles = [
              ...booking.articles.filter((article) =>
                article[`${booking.seasonKey}_collectionKeys`].includes(
                  groupItem.key
                )
              )
            ];
            section.articles.sort((a, b) =>
              a[`${booking.seasonKey}_${groupItem.key}_sort`] >
              b[`${booking.seasonKey}_${groupItem.key}_sort`]
                ? 1
                : a[`${booking.seasonKey}_${groupItem.key}_sort`] <
                  b[`${booking.seasonKey}_${groupItem.key}_sort`]
                ? -1
                : 0
            );
          } else if (booking?.sortOrder === "category") {
            section.articles = [
              ...booking?.articles.filter((article) =>
                article[`${booking.seasonKey}_generalCategoryKey`].includes(
                  groupItem.key
                )
              )
            ];
            section.articles.sort((a, b) =>
              a.retailPrice < b.retailPrice
                ? 1
                : a.retailPrice > b.retailPrice
                ? -1
                : a.articleName.toLowerCase() > b.articleName.toLowerCase()
                ? 1
                : a.articleName.toLowerCase() < b.articleName.toLowerCase()
                ? -1
                : 0
            );
          }

          currentLocation.pop();
          constructedSections.push(section);
          constructedArticles.push(section.articles);
          // section.articles.forEach((article) =>
          //   constructedArticles.push(article)
          // );
        }
      });
      currentLocation.pop();
      currentLocation.pop();
    };

    // let filteredSchema = cloneDeep(schemas[booking.seasonKey].schemas).filter(
    //   (schema) => existingKeys.some((key) => key.includes(schema.key))
    // );

    let filteredSchema;

    if (booking?.sortOrder === "collection") {
      filteredSchema = cloneDeep(schemas[booking.seasonKey].schemas).filter(
        (schema) => existingKeys.some((key) => key.includes(schema.key))
      );
    } else if (booking?.sortOrder === "category") {
      filteredSchema = cloneDeep(
        schemas[booking.seasonKey].categorySchemas
      ).filter((schema) =>
        existingKeys.some((key) => key.includes(schema.key))
      );
    }

    filteredSchema.forEach((schema) => {
      currentLocation.push(booking.seasonName);
      currentLocation.push(" ‣ ");
      currentLocation.push(schema.name);
      currentLocation.push(" ‣ ");
      processSchemaItem(schema);
    });
    setSections(constructedSections);
    setArticles(constructedArticles);
  }, [booking, schemas]);

  return (
    <BookingPublicLayout
      title={booking?.name}
      pageHeaderRight={
        <StyledForm onSubmit={submitOrderPasscode} ref={PasscodeFormRef}>
          <StyledTextInput
            value={passcodeInput}
            onChange={(e) => setPasscodeInput(e.target.value)}
            placeholder={t("ordering_passcode")}
            error={passcodeError}
          />
          <TextInputRightCap
            show={passcodeInput.length > 0}
            error={passcodeError}
          >
            <LoginIcon fontSize='small' onClick={submitOrderPasscode} />
          </TextInputRightCap>
        </StyledForm>
      }
    >
      <Wrapper>
        <Loading isLoading={loading} />
        <CartScrollArea>
          <GroupedVirtuoso
            style={{ height: "100%" }}
            groupCounts={sections.map((section) => 1)}
            groupContent={(index) => {
              const header = sections[index];
              return (
                <SectionHeader index={index}>
                  <HeaderBreadCrumbs>
                    {header.location.map((text, index) => (
                      <span key={`crubms-${header.locationKey}-${index}`}>
                        {text}
                      </span>
                    ))}
                  </HeaderBreadCrumbs>
                </SectionHeader>
              );
            }}
            itemContent={(index, groupIndex) => {
              const sectionArticles = articles[index];
              return (
                <SectionWrapper>
                  {sectionArticles?.map((article) => (
                    <ArticleCard
                      seasonKey={booking?.seasonKey}
                      key={article.id}
                      article={article}
                      showInventoryButton
                      shouldShowLimitedSoldOut
                      priceToShow={booking?.priceToShowPreview}
                      onZoomArticle={onZoomArticle}
                      onViewInventory={onViewInventory}
                    />
                  ))}
                </SectionWrapper>
              );
            }}
          />
        </CartScrollArea>
      </Wrapper>
      <ArticleZoomModal
        show={isZoomModalShowing}
        onClose={toggleArticleZoomModal}
        imageList={zoomArticleImages}
        article={zoomArticle}
      />
      <InventoryModal
        show={isInventoryModalShowing}
        onClose={toggleInventoryModal}
        article={inventoryArticle}
      />
    </BookingPublicLayout>
  );
};

const StyledForm = styled.form`
  display: flex;
  justify-content: flex-end;
`;

const StyledTextInput = styled(TextInput)`
  width: unset;
  border: 1px solid ${COLORS.secondary};
  border-color: ${({ error }) => (error ? COLORS.alertRed : COLORS.secondary)};
  color: ${({ error }) => (error ? COLORS.alertRed : COLORS.text)};
  font-size: ${FONTSIZE.small};
  text-transform: uppercase;
  border-right: unset;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const TextInputRightCap = styled.div`
  border-top: 1px solid ${COLORS.secondary};
  border-bottom: 1px solid ${COLORS.secondary};
  border-right: 1px solid ${COLORS.secondary};
  border-top-right-radius: ${CONSTANTS.borderRadius};
  border-bottom-right-radius: ${CONSTANTS.borderRadius};
  border-color: ${({ error }) => (error ? COLORS.alertRed : COLORS.secondary)};
  background: ${COLORS.primaryInverted};
  display: flex;
  align-items: center;
  padding: 8px;
  color: ${COLORS.text};

  :hover {
    color: ${COLORS.primary};
  }

  svg {
    opacity: ${({ show }) => (show ? 1.0 : 0.0)};
    width: ${({ show }) => !show && 0};
    cursor: pointer;
    transition: all 0.5s;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const CartScrollArea = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: ${COLORS.tertiary};
  border-radius: 6px;
`;

const SectionHeader = styled.div`
  z-index: 15;
  width: 100%;
  padding: 10px 5px;
  /* margin-top: ${({ index }) => (index > 0 ? "50px" : "unset")}; */
  background-color: ${COLORS.secondary};
  /* border-bottom: 3px solid ${COLORS.secondary}; */
  /* position: sticky; */
  /* top: 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 5px lightgrey;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const HeaderBreadCrumbs = styled.div`
  color: ${COLORS.text};
  text-transform: uppercase;
  font-size: ${FONTSIZE.small};
  font-weight: ${FONTWEIGHT.heavy};
  padding-left: 10px;
  display: flex;
  align-items: center;

  span {
    padding: 3px;
    :last-child {
      color: ${COLORS.primary};
      font-size: ${FONTSIZE.medium};
    }
  }
`;

const SectionWrapper = styled.div`
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 0.33333fr));
  gap: 5px 5px;
`;

export default BookingPreview;
