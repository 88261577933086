import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS, CONSTANTS } from "helpers/designConstants";
import Modal from "components/shared/modal/Modal";
import OrderEntry from "components/shared/orderEntry/OrderEntry";
import SaveIcon from "@mui/icons-material/Save";
import { useForm } from "react-hook-form";
import { useArticleReader } from "database/articles/useArticleReader";
import { netPrice } from "helpers/helperFunctions";
import { calculateOrderSummaryAndOrderQtys } from "helpers/calculateOrderSummaryAndOrderQtys";

import useWindowSize from "helpers/useWindowSize";

const OrderTabCartModal = ({
  title,
  show,
  onClose,
  booking,
  order,
  updateDBOrderQtys,
  showRemoveArticleButton,
  didSelecteRemoveArticle
}) => {
  const { width } = useWindowSize();
  const { overallCategories } = useArticleReader();

  const { control, watch, getValues, setValue, reset } = useForm({});

  const [currentOrderQtys, setCurrentOrderQtys] = useState();
  const [overallCategorySummary, setOverallCategorySummary] = useState();
  const [formSubscription, setFormSubscription] = useState();

  //Set Initial Order Data
  useEffect(() => {
    if (booking?.articles == null || order == null) return;

    const selectedOrder = order;
    setCurrentOrderQtys(selectedOrder.qtys);

    let defaultValues = {};

    booking.articles.forEach((article) => {
      article.userFriendlySizes.forEach((size, index) => {
        if (defaultValues[article.id] == null)
          defaultValues[article.id] = { orderQtys: [] };

        defaultValues[article.id].orderQtys[index] =
          selectedOrder.qtys[article.id]?.[size] || null;
      });
    });

    const { categoryTotals } = calculateOrderSummaryAndOrderQtys(
      defaultValues,
      overallCategories,
      booking?.articles,
      booking?.priceToShowOrdering
    );

    setOverallCategorySummary(categoryTotals);
    reset(defaultValues);

    return () => reset(defaultValues);
  }, [
    booking?.articles,
    booking?.priceToShowOrdering,
    order,
    overallCategories,
    reset
  ]);

  //Order Manipulation
  useEffect(() => {
    if (
      overallCategories == null ||
      booking?.articles == null ||
      order?.id == null
    )
      return;
    const subscription = watch((value) => {
      const { categoryTotals, orderQtys } = calculateOrderSummaryAndOrderQtys(
        value,
        overallCategories,
        booking?.articles,
        booking?.priceToShowOrdering
      );
      setOverallCategorySummary(categoryTotals);
      setCurrentOrderQtys(orderQtys);
      updateDBOrderQtys(orderQtys, order.id);
    });
    setFormSubscription(subscription);
    return () => subscription.unsubscribe();
  }, [
    booking?.articles,
    booking?.priceToShowOrdering,
    order?.id,
    overallCategories,
    updateDBOrderQtys,
    watch
  ]);

  //Unsubscribing when closing the Modal... otherwise the form gets reset and writes that reset to the database
  const onUserClosed = () => {
    formSubscription.unsubscribe();
    onClose();
  };

  return (
    <Modal
      show={show}
      onClose={onUserClosed}
      closeButtonHidden
      disableCloseOnOutsideClick
      maxHeight='88vh'
      width={width > 1350 ? "1300px" : "96vw"}
      contentPadding='0 20px 20px 20px'
      title={title}
    >
      <CartContainer>
        <CartHeader>
          <SaveButton onClick={onUserClosed}>
            <SaveIcon fontSize='large' />
          </SaveButton>
        </CartHeader>
        <OrderEntry
          booking={booking}
          selectedArticles={booking?.articles}
          formControl={control}
          getFormValues={getValues}
          setFormValue={setValue}
          overallCategorySummary={overallCategorySummary}
          showRemoveArticleButton={showRemoveArticleButton}
          didSelecteRemoveArticle={didSelecteRemoveArticle}
        />
      </CartContainer>
    </Modal>
  );
};

const CartContainer = styled.div`
  /* padding: 10px 0 0; */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  /* row-gap: 10px; */
  height: 100%;
  overflow: hidden;
`;

const CartHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const SaveButton = styled.div`
  position: relative;
  background: ${COLORS.primaryInverted};
  padding: 0 5px;
  color: ${COLORS.text};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${COLORS.secondary};
  border-radius: ${CONSTANTS.borderRadius};

  @media (hover: hover) {
    &:hover {
      background: ${COLORS.secondary};
      /* color: ${COLORS.primaryInverted}; */
    }
  }
`;

export default OrderTabCartModal;
